import { FC, useEffect, useRef, useState } from "react";
import {
  CategoriesTableContainer,
  CategoryContent,
  CategoryWrapper,
} from "./style";
import { Col, Flex, Spin } from "antd";
import { CategoriesChild } from "./components/CategoriesChild";
import { useCategory } from "../../context";

export const CategoriesTable: FC = () => {
  const {
    value: { category, categoryLoading, selectedCategories, levels },
    dispatch: { setSelectedCategories, setLevels },
  } = useCategory()!;
  const [selectedParentId, setSelectedParentId] = useState<number>();
  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    document
      .getElementById((levels.length - 1).toString())
      ?.scrollIntoView({ behavior: "smooth" });
  }, [levels.length]);
  if (categoryLoading) {
    return (
      <Flex className=" w-full h-full" justify="center" align="center">
        <Spin />
      </Flex>
    );
  }

  return (
    <CategoryContent>
      <CategoryWrapper>
        <CategoriesTableContainer ref={ref}>
          <Col span={24} className=" h-[63px] border ">
            <div className="flex flex-row justify-between items-center !mt[12px] mb-[4px]">
              <div className="flex flex-col gap-[4px]">
                <div className="titleHolder">Level 1</div>
                <div className="subTitleHolder"></div>
              </div>
              <div className="itemsCountHolder">({category.length} items)</div>
            </div>
          </Col>
          <CategoriesChild
            children={category.map((item) => ({
              ...item,
              title: item.name,
              subTitle: item.childeren.length,
            }))}
            onClick={(cat) => {
              setSelectedParentId(cat.id);
              setSelectedCategories([cat]);
              if (cat.childeren.length > 0) setLevels([cat]);
              else setLevels([]);
            }}
            selectedParentId={selectedParentId}
          />
        </CategoriesTableContainer>
        {levels.map((cat, index) => (
          <CategoriesTableContainer
            key={index}
            id={index.toString()}
            className="border-left"
          >
            <Col span={24} className=" h-[63px] border ">
              <div className="flex flex-row justify-between items-center !mt[12px] mb-[4px]">
                <div className="flex flex-col gap-[4px]">
                  <div className="titleHolder">
                    {"Level ".concat((+index + 2).toString())}
                  </div>
                  <div className="subTitleHolder"></div>
                </div>
                <div className="itemsCountHolder">
                  ({cat.childeren.length} items)
                </div>
              </div>
            </Col>
            <CategoriesChild
              children={cat.childeren.map((item) => ({
                ...item,
                title: item.name,
                subTitle: item.childeren.length,
              }))}
              selectedParentId={selectedCategories[index + 1]?.id}
              selectedChildren={selectedCategories}
              onClick={(newCat) => {
                setSelectedCategories((prev) => {
                  const parentIndex = prev.findIndex(
                    (item) => item.parentId === newCat.parentId
                  );

                  const newData =
                    parentIndex > 0
                      ? [...prev.slice(0, parentIndex), newCat]
                      : [...prev, newCat];

                  return newData;
                });
                setLevels((prev) => {
                  const parent = prev.findIndex(
                    (item) => item.parentId === newCat.parentId
                  );
                  if (parent > 0) {
                    return newCat.childeren.length > 0
                      ? [...prev.slice(0, parent), newCat]
                      : [...prev.slice(0, parent)];
                  } else {
                    return newCat.childeren.length > 0
                      ? [...prev, newCat]
                      : prev;
                  }
                });
              }}
            />
          </CategoriesTableContainer>
        ))}
      </CategoryWrapper>
    </CategoryContent>
  );
};
