import { IReportsArgs } from "src/services/InvoiceReport/models";
import { IReportFormValues } from "../models/formValues";

interface IProps {
  values: Partial<IReportFormValues>;
  offset: number;
  limit: number;
  withoutPagination?: boolean;
}
export const createExpensiveReportReqBody = ({
  values,
  offset,
  limit,
  withoutPagination,
}: IProps) => {
  const reqBody: Partial<IReportsArgs> = {
    page: {
      offset: !withoutPagination ? offset : null,
      limit: !withoutPagination ? limit : null,
    },
    shipId: values.ShipId || null,
    fromInvoiceCreateDate: values.createdDate ? values.createdDate[0] : null,
    toInvoiceCreateDate: values.createdDate ? values.createdDate[1] : null,
    requesterId: values.RequesterId || null,
    paymentCurrencyId: values.PaymentCurrencyId || null,
    zipCode: values.ZipCode || null,
    fromRequesterDate: values.RequesterDate ? values.RequesterDate[0] : null,
    toRequesterDate: values.RequesterDate ? values.RequesterDate[1] : null,
    fromDueDate: values.DueDate ? values.DueDate[0] : null,
    toDueDate: values.DueDate ? values.DueDate[1] : null,
    attachmentCode: values.AttachmentCode || null,
    invoiceNO: values.InvoiceNumber || null,
    requesterInvoiceNO: values.ShipManagementInvoiceNumber || null,
    fromRequesterInvoiceDate: values.RequesterInvoiceDate
      ? values.RequesterInvoiceDate[0]
      : null,
    toRequesterInvoiceDate: values.RequesterInvoiceDate
      ? values.RequesterInvoiceDate[1]
      : null,
    supplierId: values.SupplierId || null,
    supplierINvoiceNO: values.supplierInvoiceNumber || null,
    fromSupplierInvoiceDate: values.SupplierInvoiceDate
      ? values.SupplierInvoiceDate[0]
      : null,
    toSupplierInvoiceDate: values.SupplierInvoiceDate
      ? values.SupplierInvoiceDate[1]
      : null,
    invoiceCurrencyId: values.InvoiceCurrencyId || null,
    fromSupplierTotlaAmount: values.SupplierTotalFromAmount
      ? +values.SupplierTotalFromAmount
      : null,
    toSupplierTotlaAmount: values.SupplierTotalToAmount
      ? +values.SupplierTotalToAmount
      : null,
    fromOurDate: values.OurDate ? values.OurDate[0] : null,
    toOurDate: values.OurDate ? values.OurDate[0] : null,
    fromShipManagementTotlaAmount: values.ShipManagementTotalFromAmount
      ? +values.ShipManagementTotalFromAmount
      : null,
    toShipManagementTotlaAmount: values.ShipManagementTotalToAmount
      ? +values.ShipManagementTotalToAmount
      : null,
    categoryCode: values.categoryCode || null,
    categoryIds: values.categoryIds || null,
    fromQuantity: values.quantityFrom ? +values.quantityFrom : null,
    toQuantity: values.quantityTo ? +values.quantityTo : null,
    fromUnitPrice: values.unitPriceFrom ? +values.unitPriceFrom : null,
    toUnitPrice: values.unitPriceTo ? +values.unitPriceTo : null,
    fromAmount: values.amountFrom ? +values.amountFrom : null,
    toAmount: values.amountTo ? +values.amountTo : null,
    voyageId: values.voyageId || null,
  };
  return reqBody;
};
