export const CardsInfo = [
  {
    amount: "totalExpenses",
    color: ["#EB6F70", "#F49898"],
    desc: "Total expenses",
    img: "./assets/images/totalExpenses.svg",
  },
  {
    amount: "totalIncome",
    color: ["#6BC497", "#97D4B4"],
    desc: "Total Incomes",
    img: "./assets/images/totalIncomes.svg",
  },
  {
    amount: "balance",
    color: ["#2C73FC", "#95B9FE"],
    desc: "Balance",
    img: "./assets/images/balance.svg",
  },
];
