import { Col, Popconfirm, TableProps, Tooltip } from "antd";
import { uniqueId } from "lodash";
import { FC } from "react";
import { TableUI } from "src/components/UiKit/table";
import { AddIncomeTableContainer } from "./styles";
import { useAttachIncome } from "./context/attachFile";
import { IAllIncomeAttachmentDetails } from "src/services/Income/models";
import { IncomeService } from "src/services/Income/Income.service";

export const AttachmentTable: FC = () => {
  const {
    value: { allAttachment, tableLoading, pagination, attachmentForm },
    dispatch: { setPagination, setCurrentEditAttachment },
    func: { downloadDocumentReq, getAttachments },
  } = useAttachIncome();
  const onDelete = async (id: number) => {
    try {
      const { DeleteIncomeAttachmentDetail } = new IncomeService();
      const res = await DeleteIncomeAttachmentDetail(id);
      if (res && res.status === 200) {
        await getAttachments();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const columns: TableProps<IAllIncomeAttachmentDetails>["columns"] = [
    {
      title: "#",
      key: "#",
      render: (text, record, index) =>
        (pagination.Offset - 1) * pagination.Limit + (index + 1),
      width: 55,
    },
    {
      title: "File name",
      dataIndex: "fileName",
      key: "fileName",

      width: 120,
    },
    {
      title: "File format",
      dataIndex: "extentions",
      key: "extentions",
      width: 127,
      render: (text: string) => text.replaceAll(".", "").toUpperCase(),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",

      width: 578,
      render: (text: string) => text || "--",
    },

    {
      title: "Tools",
      key: "tools",
      width: 112,
      render: (text, record) => (
        <Tools
          onDelete={async () => {
            await onDelete(record.id);
          }}
          onEdit={() => {
            attachmentForm?.setFieldsValue(record);
            setCurrentEditAttachment(record);
          }}
          onDownload={() => {
            downloadDocumentReq(record.uniqueSystemName, record.fileName);
          }}
        />
      ),
    },
  ];
  return (
    <Col span={24}>
      <AddIncomeTableContainer>
        <Col span={24}>
          <div className="title">Attachment Table</div>
        </Col>
        <Col span={24}>
          <TableUI
            columns={columns}
            dataSource={allAttachment}
            loading={tableLoading}
            pagination={{
              position: ["bottomCenter"],
              onChange: (page, pageSize) => {
                setPagination((prev) => ({
                  ...prev,
                  Offset: pageSize !== prev.Limit ? 1 : page,
                  Limit: pageSize,
                }));
              },
            }}
            scroll={{ x: "auto" }}
            rowKey={() => uniqueId()}
            containerStyle={{
              padding: 8,
            }}
            rowClassName={"rowClass"}
          />
        </Col>
      </AddIncomeTableContainer>
    </Col>
  );
};

const Tools: FC<{
  onDelete: () => void;
  onEdit: () => void;
  onDownload: () => void;
}> = ({ onDelete, onEdit, onDownload }) => {
  return (
    <div className="flex justify-center items-center gap-[16px] text-[16px]">
      {/* <Guard action={EditVoyagePath}> */}
      <Tooltip title="Download">
        <span
          onClick={onDownload}
          className="material-icons !text-[#02514C] text-[16px] cursor-pointer"
        >
          file_download
        </span>
      </Tooltip>
      {/* </Guard> */}
      {/* <Guard action={EditVoyagePath}> */}
      <Tooltip title="Edit">
        <span
          onClick={onEdit}
          className="material-icons !text-[#01C18F] text-[16px] cursor-pointer"
        >
          edit_note
        </span>
      </Tooltip>
      {/* </Guard> */}
      {/* <Guard action={DeleteVoyagePath}> */}
      <Popconfirm
        placement="bottom"
        title="Delete Voyage"
        description="Are you sure you want to Delete this Voyage?"
        okText="Yes"
        cancelText="No"
        onConfirm={onDelete}
      >
        <Tooltip title="Delete">
          <span className="material-icons !text-[#FF1414] text-[16px] cursor-pointer">
            delete_forever
          </span>
        </Tooltip>
      </Popconfirm>
      {/* </Guard> */}
    </div>
  );
};
