import { FC } from "react";
import { CardContainer } from "../style";
import { Flex } from "antd";

export interface ITurnoverCard {
  img: string;
  desc: string;
  amount: number;
  color: string[];
}
export const Card: FC<ITurnoverCard> = ({ img, desc, amount, color }) => {
  return (
    <CardContainer>
      <img src={img} alt="img" className="w-[48px] h-[48px]" />
      <div className="text-[16px] font-[600] text-[#5D6881]">{desc}</div>
      <Flex gap={8}>
        <div className={` text-[24px] font-[700]`} style={{ color: color[0] }}>
          {amount?.toLocaleString("en-US", {
            maximumFractionDigits: 2,
          })}
        </div>
        <div className={` text-[24px] font-[500]`} style={{ color: color[1] }}>
          USD
        </div>
      </Flex>
    </CardContainer>
  );
};
