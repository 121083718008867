import { Row } from "antd";
import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  height: 80px;
  padding: 24px;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid var(--color-Light-Mode-Border-3, #dadeec);
`;
export const DailyAverageReportContainer = styled(Row)`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 32px;
  border-radius: 16px;
  background: var(--color-Base-Color-White, #fff);
  .title {
    color: var(--color-Light-Mode-Text-Title, #001377);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
    padding-block: 16px;
  }
  .footer {
    border-bottom: 1px solid var(--color-Light-Mode-Border-3, #dadeec);
  }
  .fromDate {
    color: var(--color-Icon-Warning, #fe9b0e);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0px;
  }
`;
