import { PublicUrls } from "./urls";
import { IPublicCategory, IPublicCurrency, IRequester, IShip } from "./models";
import axios from "axios";
import { IDataModel } from "../models";
import { checkActionPermission } from "src/helpers/checkPermissionFunc";
import * as GuardPaths from "./guardPath";
import { permissionLog } from "src/helpers/permissionLog";
import { IPagination } from "src/models/interfaces/pagination";
export class PublicService {
  async Ship(queries?: string) {
    if (!checkActionPermission(GuardPaths.ShipPath)) {
      permissionLog(GuardPaths.ShipPath.path);
      return undefined;
    }
    let endpoint: string = PublicUrls.Ship;
    if (queries) endpoint = endpoint.concat(`?${queries}`);
    try {
      const response = await axios.get<IShip[]>(endpoint);
      return response;
    } catch (err) {
      return console.log(err);
    }
  }
  async Requester(params?: Omit<IPagination, "total">) {
    if (!checkActionPermission(GuardPaths.RequesterPath)) {
      permissionLog(GuardPaths.RequesterPath.path);
      return undefined;
    }
    try {
      let searchParams = "";
      if (params) {
        const urlSearchParams = new URLSearchParams(JSON.stringify(params));
        searchParams = `?${urlSearchParams}`;
      }
      const response = await axios.get<IDataModel<IRequester[]>>(
        PublicUrls.Requester.concat(searchParams)
      );
      return response;
    } catch (err) {
      return console.log(err);
    }
  }
  async Category(queries?: string[]) {
    // const search = new URLSearchParams();
    // for (const query of queries) {
    //   search.append("costcodes", query);
    // }
    if (!checkActionPermission(GuardPaths.CategoryPath)) {
      permissionLog(GuardPaths.CategoryPath.path);
      return undefined;
    }
    try {
      const response = await axios.post<IPublicCategory[]>(
        PublicUrls.Category,
        queries
      );
      return response;
    } catch (err) {
      return console.log(err);
    }
  }
  async Currency(queries: string[]) {
    if (!checkActionPermission(GuardPaths.CurrencyPath)) {
      permissionLog(GuardPaths.CurrencyPath.path);
      return undefined;
    }
    const search = new URLSearchParams();
    for (const query of queries) {
      search.append("shortNames", query);
    }
    try {
      const response = await axios.get<IPublicCurrency[]>(
        PublicUrls.Currency.concat(`?${search.toString()}`)
      );
      return response;
    } catch (err) {
      return console.log(err);
    }
  }
  async AllCurrency() {
    if (!checkActionPermission(GuardPaths.AllCurrencyPath)) {
      permissionLog(GuardPaths.AllCurrencyPath.path);
      return undefined;
    }
    try {
      const response = await axios.get<IPublicCurrency[]>(
        PublicUrls.AllCurrency
      );
      return response;
    } catch (err) {
      return console.log(err);
    }
  }
}
