import { FC, Suspense } from "react";
import { ExpensiveReport } from "src/modules/ExpensiveReport";
import { ExpensiveReportProvider } from "src/modules/ExpensiveReport/context";
import { PreLoading } from "src/routes/Loading";

export const ExpensiveReportPage: FC = () => {
  return (
    <Suspense fallback={<PreLoading />}>
      <ExpensiveReportProvider>
        <ExpensiveReport />
      </ExpensiveReportProvider>
    </Suspense>
  );
};
