import { Form, FormInstance } from "antd";
import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { FinancialMode, IFinancialTabs } from "./models";
import { SystemService } from "src/services/System/System.service";
import { CategoryTypeEnum, ITreeCategory } from "src/services/System/models";
import {
  IAllIncomes,
  IAllPayer,
  IAllReceivers,
  IAllVoyageIncomes,
  IIncome,
} from "src/services/Income/models";
import { IncomeService } from "src/services/Income/Income.service";

interface ILoadings {
  categories: boolean;
  payers: boolean;
  receivers: boolean;
  allIncomes: boolean;
}
interface IContext {
  value: {
    activeKey: string | undefined;
    mode: FinancialMode;
    currentEditVoyage: IAllVoyageIncomes | undefined;
    form: FormInstance | undefined;
    loadings: ILoadings;
    categories: ITreeCategory[];
    editMode: boolean;
    payers: IAllPayer[];
    receivers: IAllReceivers[];
    allIncomes: IAllIncomes | undefined;
    currentEditIncome: IIncome | undefined;
    showView: boolean;
  };

  dispatch: {
    setActiveKey: Dispatch<SetStateAction<string | undefined>>;
    setMode: Dispatch<SetStateAction<FinancialMode>>;
    setCurrentVoyage: Dispatch<SetStateAction<IAllVoyageIncomes | undefined>>;
    setLoadings: Dispatch<SetStateAction<ILoadings>>;
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setCurrentEditIncome: Dispatch<SetStateAction<IIncome | undefined>>;
    setShowView: Dispatch<SetStateAction<boolean>>;
  };
  func: {
    getAllIncome: () => void;
    onCloseView: () => void;
    onViewIncome: (record: IAllVoyageIncomes) => void;
    goToAddIncome: (record?: IAllVoyageIncomes, editMode?: boolean) => void;
  };
}
const defaultContext: IContext = {
  value: {
    activeKey: undefined,
    mode: "table",
    currentEditVoyage: undefined,
    form: undefined,
    categories: [],
    loadings: {
      categories: false,
      payers: false,
      receivers: false,
      allIncomes: false,
    },
    editMode: false,
    payers: [],
    receivers: [],
    allIncomes: undefined,
    currentEditIncome: undefined,
    showView: false,
  },
  dispatch: {
    setActiveKey: () => {},
    setMode: () => {},
    setCurrentVoyage: () => {},
    setLoadings: () => {},
    setEditMode: () => {},
    setCurrentEditIncome: () => {},
    setShowView: () => {},
  },
  func: {
    getAllIncome: () => {},
    onCloseView: () => {},
    onViewIncome: () => {},
    goToAddIncome: () => {},
  },
};
export const FinancialCTX = createContext<IContext>(defaultContext);

export const FinancialProvider: FC<PropsWithChildren> = ({ children }) => {
  const [activeKey, setActiveKey] = useState<string>();
  const [mode, setMode] = useState<FinancialMode>("table");
  const [currentEditVoyage, setCurrentVoyage] = useState<IAllVoyageIncomes>();
  const [categories, setCategories] = useState<ITreeCategory[]>([]);
  const [loadings, setLoadings] = useState<ILoadings>(
    defaultContext.value.loadings
  );
  const [form] = Form.useForm();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [payers, setPayers] = useState<IAllPayer[]>([]);
  const [receivers, setReceivers] = useState<IAllReceivers[]>([]);
  const [currentEditIncome, setCurrentEditIncome] = useState<IIncome>();
  const [allIncomes, setAllIncomes] = useState<IAllIncomes>();
  const [showView, setShowView] = useState<boolean>(false);
  const getCategories = async () => {
    setLoadings((prev) => ({ ...prev, categories: true }));
    try {
      const { TreeCategory } = new SystemService();
      const res = await TreeCategory(undefined, CategoryTypeEnum.Income);
      if (res && res.data && res.status === 200) {
        setCategories(res.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings((prev) => ({ ...prev, categories: false }));
    }
  };
  const getPayer = async () => {
    setLoadings((prev) => ({ ...prev, payers: true }));
    try {
      const { AllPayers } = new IncomeService();
      const res = await AllPayers();
      if (res && res.data && res.status === 200) {
        setPayers(res.data.records);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings((prev) => ({ ...prev, payers: false }));
    }
  };
  const getReceivers = async () => {
    setLoadings((prev) => ({ ...prev, receivers: true }));
    try {
      const { AllReceivers } = new IncomeService();
      const res = await AllReceivers();
      if (res && res.data && res.status === 200) {
        setReceivers(res.data.records);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings((prev) => ({ ...prev, receivers: false }));
    }
  };
  const getAllIncome = useCallback(async () => {
    if (!currentEditVoyage) return;
    try {
      setLoadings((prev) => ({ ...prev, allIncomes: true }));
      const { AllIncomes } = new IncomeService();
      const result = await AllIncomes({ VoyageId: currentEditVoyage.voyageId });
      if (result && result.data) {
        setAllIncomes(result.data);
      }
    } catch (err) {
    } finally {
      setLoadings((prev) => ({ ...prev, allIncomes: false }));
    }
  }, [currentEditVoyage]);
  const goToAddIncome = (record?: IAllVoyageIncomes, editMode?: boolean) => {
    setCurrentVoyage(record);
    setMode("add");
    setActiveKey(IFinancialTabs.addIncome);
    setEditMode(!!editMode);
  };
  ///View

  const onCloseView = () => {
    setShowView(false);
  };
  const onViewIncome = (record: IAllVoyageIncomes) => {
    setShowView(true);
    setCurrentVoyage(record);
  };

  // Effects
  useEffect(() => {
    getAllIncome();
  }, [getAllIncome]);
  useEffect(() => {
    Promise.allSettled([getCategories(), getReceivers(), getPayer()]);
  }, []);

  const ctxValue: IContext = {
    value: {
      activeKey,
      mode,
      currentEditVoyage,
      form,
      loadings,
      categories,
      editMode,
      payers,
      receivers,
      allIncomes,
      currentEditIncome,
      showView,
    },
    dispatch: {
      setActiveKey,
      setMode,
      setCurrentVoyage,
      setLoadings,
      setEditMode,
      setCurrentEditIncome,
      setShowView,
    },
    func: {
      getAllIncome,
      onCloseView,
      onViewIncome,
      goToAddIncome,
    },
  };
  return (
    <FinancialCTX.Provider value={ctxValue}>{children}</FinancialCTX.Provider>
  );
};

export const useFinancial = () => useContext(FinancialCTX);
