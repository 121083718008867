import { Form } from "antd";
import { FormInstance } from "antd/lib";
import { AxiosRequestConfig } from "axios";
import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import downloadZipFile from "src/helpers/downloadZipFile";
import { IPagination } from "src/models/interfaces/pagination";
import { DocumentService } from "src/services/Document/Document.service";
import { IncomeService } from "src/services/Income/Income.service";
import {
  IAllIncomeAttachmentDetails,
  IIncome,
} from "src/services/Income/models";

const defaultPagination: IPagination = {
  Limit: 10,
  Offset: 1,
};
interface IContext {
  value: {
    currentIncome: IIncome | undefined;
    tableLoading: boolean;
    allAttachment: IAllIncomeAttachmentDetails[];
    pagination: IPagination;
    progress: number;
    attachmentForm: FormInstance | undefined;
    currentEditAttachment: IAllIncomeAttachmentDetails | undefined;
  };
  dispatch: {
    setCurrentIncome: Dispatch<SetStateAction<IIncome | undefined>>;
    setPagination: Dispatch<SetStateAction<IPagination>>;
    setCurrentEditAttachment: Dispatch<
      SetStateAction<IAllIncomeAttachmentDetails | undefined>
    >;
  };
  func: {
    getAttachments: () => void;
    // getIncomeAttachment: (detailId: number) => Promise<void>;
    downloadDocumentReq: (uniqueId: string, fileName: string) => Promise<void>;
  };
}
const defaultContext: IContext = {
  value: {
    currentIncome: undefined,
    allAttachment: [],
    tableLoading: false,
    pagination: defaultPagination,
    progress: 0,
    attachmentForm: undefined,
    currentEditAttachment: undefined,
  },
  dispatch: {
    setCurrentIncome: () => {},
    setPagination: () => {},
    setCurrentEditAttachment: () => {},
  },
  func: {
    getAttachments: () => {},
    // getIncomeAttachment: async () => {},
    downloadDocumentReq: async () => {},
  },
};
export const AttachIncomeCtx = createContext<IContext>(defaultContext);
export const AttachIncomeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [currentIncome, setCurrentIncome] = useState<IIncome>();
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [allAttachment, setAllAttachment] = useState<
    IAllIncomeAttachmentDetails[]
  >([]);
  const [pagination, setPagination] = useState<IPagination>(defaultPagination);
  const [progress, setProgress] = useState<number>(0);
  const [attachmentForm] = Form.useForm();
  const [currentEditAttachment, setCurrentEditAttachment] =
    useState<IAllIncomeAttachmentDetails>();
  const getAttachments = useCallback(async () => {
    if (!currentIncome) return;
    setTableLoading(true);
    try {
      const { AllIncomeAttachmentDetails } = new IncomeService();
      const res = await AllIncomeAttachmentDetails(currentIncome.id);
      if (res && res.data && res.status === 200) {
        setAllAttachment(res.data.records);
        setPagination((prev) => ({ ...prev, total: res.data.count }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTableLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIncome, pagination.Limit, pagination.Offset]);
  useEffect(() => {
    getAttachments();
  }, [getAttachments]);
  // const getIncomeAttachment = async (detailId: number) => {
  //   try {
  //     setDownloadLoading(true);
  //     const { GetIncomeAttachment } = new IncomeService();
  //     const result = await GetIncomeAttachment(detailId);
  //     if (result && result.data && result.data.length > 0) {
  //       await downloadDocumentReq(result.data[0].uniqueSystemName);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   } finally {
  //     setDownloadLoading(false);
  //   }
  // };
  const downloadDocumentReq = async (uniqueId: string, fileName: string) => {
    const config: AxiosRequestConfig = {
      onDownloadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / (progressEvent.total || 0)
        );
        if (percentage === 100) {
          setTimeout(() => setProgress(0), 400);
        } else {
          setProgress(percentage);
        }
      },
    };
    try {
      const { DownloadDocument } = new DocumentService();
      const result = await DownloadDocument(uniqueId, config);
      if (result) {
        downloadZipFile({ ...result, downloadFileName: fileName });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setProgress(0);
    }
  };
  const ctxValue: IContext = {
    value: {
      currentIncome,
      allAttachment,
      tableLoading,
      pagination,
      progress,
      attachmentForm,
      currentEditAttachment,
    },
    dispatch: {
      setCurrentIncome,
      setPagination,
      setCurrentEditAttachment,
    },
    func: {
      getAttachments,
      downloadDocumentReq,
    },
  };

  return (
    <AttachIncomeCtx.Provider value={ctxValue}>
      {children}
    </AttachIncomeCtx.Provider>
  );
};
export const useAttachIncome = () => useContext(AttachIncomeCtx);
