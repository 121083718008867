export enum IFinancialTabs {
  addIncome = "1",
  voyageTable = "2",
}
export type FinancialMode = "table" | "add" | "doc";
export interface ITreeValue {
  value: number;
  title: string;
  children: ITreeValue[];
}
