import { FC } from "react";
import { AddIncomeForm } from "./AddIncomeForm";
import { Col, Divider, Row } from "antd";
import { AddIncomeTable } from "./AddIncomeTable";
import { FinancialContainer } from "./styles";
import { useFinancial } from "../../context";
import { IncomeAttachment } from "./Attachment";

export const AddIncome: FC = () => {
  const {
    value: { mode },
  } = useFinancial();
  if (mode === "doc") {
    return (
      <FinancialContainer justify={"center"}>
        <Col span={23} className="content">
          <Row>
            <Col span={24}>
              <IncomeAttachment />
            </Col>
          </Row>
        </Col>
      </FinancialContainer>
    );
  }
  return (
    <FinancialContainer justify={"center"}>
      <Col span={23} className="content">
        <Row>
          <Col span={24}>
            <AddIncomeForm />
          </Col>
          <Col span={24}>
            <Divider />
          </Col>
          <Col span={24}>
            <AddIncomeTable />
          </Col>
        </Row>
      </Col>
    </FinancialContainer>
  );
};
