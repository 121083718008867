import { InvoiceReportUrls } from "./urls";
import {
  IDailyAverageReport,
  IDailyAverageReportArg,
  IInvoiceReportsArgs,
  IReportAllRequest,
  IReportAllRequestAttachment,
  IReportInvoice,
  IReportInvoiceArticle,
  IReportsArgs,
  IReportsResult,
  ITurnoverReport,
  ITurnoverReportResult,
} from "./models";
import axios from "axios";
import { IDataModel } from "../models";
import { checkActionPermission } from "src/helpers/checkPermissionFunc";
import { permissionLog } from "src/helpers/permissionLog";
import * as GuardPaths from "./guardPath";

export class InvoiceReportService {
  async ReportAllRequest(data: Partial<IInvoiceReportsArgs>) {
    if (!checkActionPermission(GuardPaths.ReportAllRequestPath)) {
      permissionLog(GuardPaths.ReportAllRequestPath.path);
      return undefined;
    }
    try {
      const response = await axios.post<IDataModel<IReportAllRequest[]>>(
        InvoiceReportUrls.ReportAllRequest,
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async ReportAllRequestAttachment(data: Partial<IInvoiceReportsArgs>) {
    if (!checkActionPermission(GuardPaths.ReportAllRequestAttachmentPath)) {
      permissionLog(GuardPaths.ReportAllRequestAttachmentPath.path);
      return undefined;
    }
    try {
      const response = await axios.post<
        IDataModel<IReportAllRequestAttachment[]>
      >(InvoiceReportUrls.ReportAllRequestAttachment, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async ReportInvoice(data: Partial<IInvoiceReportsArgs>) {
    if (!checkActionPermission(GuardPaths.ReportInvoicePath)) {
      permissionLog(GuardPaths.ReportInvoicePath.path);
      return undefined;
    }
    try {
      const response = await axios.post<IDataModel<IReportInvoice[]>>(
        InvoiceReportUrls.ReportInvoice,
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async ReportInvoiceArticle(data: Partial<IInvoiceReportsArgs>) {
    if (!checkActionPermission(GuardPaths.ReportInvoiceArticlePath)) {
      permissionLog(GuardPaths.ReportInvoiceArticlePath.path);
      return undefined;
    }
    try {
      const response = await axios.post<IDataModel<IReportInvoiceArticle[]>>(
        InvoiceReportUrls.ReportInvoiceArticle,
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DailyAverageReport(data: IDailyAverageReportArg) {
    if (!checkActionPermission(GuardPaths.DailyAverageReportPath)) {
      permissionLog(GuardPaths.DailyAverageReportPath.path);
      return undefined;
    }
    let endpoint = InvoiceReportUrls.DailyAverageReport;

    try {
      const response = await axios.post<IDailyAverageReport>(endpoint, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async Report(data: Partial<IReportsArgs>) {
    if (!checkActionPermission(GuardPaths.ReportPath)) {
      permissionLog(GuardPaths.ReportPath.path);
      return undefined;
    }
    try {
      const response = await axios.post<IReportsResult>(
        InvoiceReportUrls.Report,
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async TurnoverReport(data: ITurnoverReport) {
    if (!checkActionPermission(GuardPaths.TurnoverReportPath)) {
      permissionLog(GuardPaths.TurnoverReportPath.path);
      return undefined;
    }
    let endpoint: string = InvoiceReportUrls.TurnoverReport;

    try {
      const response = await axios.post<ITurnoverReportResult>(endpoint, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
}
