import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { IReports, IReportsResult } from "src/services/InvoiceReport/models";
import { IReportFormValues } from "../models/formValues";
import { createExpensiveReportReqBody } from "../helper/createInvoiceReportReqBody";
import { INVOICE_REPORT_TYPES } from "../models/reportTypes";
import { InvoiceReportService } from "src/services/InvoiceReport/InvoiceReport.service";
import { IPagination } from "src/models/interfaces/pagination";
import { Form, Modal } from "antd";
import { FormInstance } from "antd/lib";

interface ILoadings {
  allRequest: boolean;
  attachment: boolean;
  article: boolean;
  invoice: boolean;
}
interface ICommentModal {
  showModal: boolean;
  title: string;
  description: string;
}
interface IFetchReportProps {
  values: Partial<IReportFormValues>;
  pagination?: IPagination;
  withoutPagination?: boolean;
  justData?: boolean;
}
interface IContext {
  value: {
    loading: boolean;
    ArticleData: IReports[];
    ArticlePagination: IPagination;
    reportType: number | undefined;
    currentRequestValue: Partial<IReportFormValues> | undefined;
    loadings: ILoadings;
    commentModal: ICommentModal;
    defaultPagination: IPagination;
    invoiceReportForm: FormInstance | undefined;
    totalAmount: number | undefined;
  };
  dispatch: {
    setReportType: Dispatch<SetStateAction<number | undefined>>;
    setArticlePagination: Dispatch<SetStateAction<IPagination>>;
    setLoadings: Dispatch<SetStateAction<ILoadings>>;
    setCommentModal: Dispatch<SetStateAction<ICommentModal>>;
    setCurrentRequestValue: Dispatch<
      SetStateAction<Partial<IReportFormValues> | undefined>
    >;
  };
  func: {
    setFilter: (values: Partial<IReportFormValues>) => void;
    fetchReports: (
      props: IFetchReportProps
    ) => Promise<IReportsResult | undefined>;
  };
}
const defaultPagination: IPagination = {
  Limit: 10,
  Offset: 1,
};
const defaultLoadings: ILoadings = {
  allRequest: false,
  article: false,
  attachment: false,
  invoice: false,
};
const defaultCommentModal: ICommentModal = {
  showModal: false,
  title: "",
  description: "",
};
const defaultContextValue: IContext = {
  value: {
    loading: false,
    ArticleData: [],
    ArticlePagination: defaultPagination,
    reportType: undefined,
    currentRequestValue: undefined,
    loadings: defaultLoadings,
    commentModal: defaultCommentModal,
    defaultPagination,
    invoiceReportForm: undefined,
    totalAmount: undefined,
  },
  dispatch: {
    setReportType: () => {},
    setArticlePagination: () => {},
    setLoadings: () => {},
    setCommentModal: () => {},
    setCurrentRequestValue: () => {},
  },
  func: {
    setFilter: () => {},
    fetchReports: async () => undefined,
  },
};
export const ExpensiveReportContext =
  createContext<IContext>(defaultContextValue);

export const ExpensiveReportProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadings, setLoadings] = useState<ILoadings>(defaultLoadings);
  const [invoiceReportForm] = Form.useForm();

  const [commentModal, setCommentModal] =
    useState<ICommentModal>(defaultCommentModal);
  const [currentRequestValue, setCurrentRequestValue] =
    useState<Partial<IReportFormValues>>();
  const [ArticlePagination, setArticlePagination] =
    useState<IPagination>(defaultPagination);
  const [ArticleData, setArticleData] = useState<IReports[]>([]);
  const [reportType, setReportType] = useState<number>();
  const { Report } = new InvoiceReportService();
  const [totalAmount, setTotalAmount] = useState<number>();
  const fetchReports = useCallback(
    async ({
      values,
      pagination,
      withoutPagination,
      justData,
    }: IFetchReportProps) => {
      try {
        !justData && setLoadings((prev) => ({ ...prev, article: true }));
        const reqBody = createExpensiveReportReqBody({
          values,
          limit: pagination ? pagination.Limit : ArticlePagination.Limit,
          offset: pagination ? pagination.Offset : ArticlePagination.Offset,
          withoutPagination,
        });

        const res = await Report(reqBody);
        if (res && res.data) {
          if (justData) return res.data;
          setArticleData(res.data.reports.records);
          setArticlePagination((prev) => ({
            ...prev,
            total: res.data.reports.count,
          }));
          setTotalAmount(res.data.totalAmount);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoadings((prev) => ({ ...prev, article: false }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ArticlePagination]
  );
  const setFilter = async (values: Partial<IReportFormValues>) => {
    setLoading(true);
    setCurrentRequestValue(values);
    await fetchReports({ values }).finally(() => setLoading(false));
    setReportType(INVOICE_REPORT_TYPES.Article);
  };
  const contextValue: IContext = {
    value: {
      loading,
      ArticleData,
      ArticlePagination,
      reportType,
      currentRequestValue,
      loadings,
      commentModal,
      defaultPagination,
      invoiceReportForm,
      totalAmount,
    },
    dispatch: {
      setReportType,
      setArticlePagination,
      setLoadings,
      setCommentModal,
      setCurrentRequestValue,
    },
    func: {
      setFilter,
      fetchReports,
    },
  };
  return (
    <ExpensiveReportContext.Provider value={contextValue}>
      {children}
      <Modal
        open={commentModal.showModal}
        onCancel={() => setCommentModal(defaultCommentModal)}
        footer={null}
        width={720}
        title={
          <div
            className="flex justify-between "
            style={{
              borderBottom: "1px solid var(--shadow, #DADEEC)",
              padding: "8px 0 24px",
            }}
          >
            <div className="w-[122px] flex items-center gap-[8px]">
              <span className="material-symbols-outlined cursor-default text-[24px]">
                mode_comment
              </span>
              Description
            </div>
            <div>{commentModal.title}</div>
            <div className="w-[122px]"></div>
          </div>
        }
      >
        <div
          className={
            "px-[8px] py-[16px] bg-[#F4F7FE] text-[13px] rounded-[12px] mt-[24px]"
          }
        >
          {commentModal.description || "There is no comment"}
        </div>
      </Modal>
    </ExpensiveReportContext.Provider>
  );
};

export const useExpensiveReport = () => useContext(ExpensiveReportContext);
