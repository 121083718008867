import { Col, Flex, Popconfirm, TableProps, Tooltip } from "antd";
import { FC } from "react";
import { AddIncomeTableContainer } from "./styles";
import { TableUI } from "src/components/UiKit/table";
import { omit, uniqueId } from "lodash";
import { useFinancial } from "../../context";
import { IFinancialTabs } from "../../models";
import { IIncome } from "src/services/Income/models";
import moment from "moment";
import { IAddIncomeFormValue } from "./AddIncomeForm";
import dayjs from "dayjs";
import { IncomeService } from "src/services/Income/Income.service";
import { useAttachIncome } from "./context/attachFile";
export const AddIncomeTable: FC = () => {
  const {
    dispatch: { setActiveKey, setMode, setEditMode, setCurrentEditIncome },
    value: { allIncomes, loadings, form },
    func: { getAllIncome },
  } = useFinancial();
  const {
    dispatch: { setCurrentIncome },
  } = useAttachIncome();
  const onAttach = (record: IIncome) => {
    setMode("doc");
    setActiveKey(IFinancialTabs.addIncome);
    setCurrentIncome(record);
  };
  const onEdit = (record: IIncome) => {
    let newRecord: IAddIncomeFormValue = {
      ...omit(record, [
        "receiptDate",
        "transactionDate",
        "periodStartDate",
        "periodEndDate",
      ]),
    };
    if (record.receiptDate) {
      newRecord = {
        ...newRecord,
        receiptDate: dayjs(record.receiptDate),
      };
    }
    if (record.transactionDate) {
      newRecord = {
        ...newRecord,
        transactionDate: dayjs(record.transactionDate),
      };
    }
    if (record.periodStartDate && record.periodEndDate) {
      newRecord = {
        ...newRecord,
        periodDate: [
          dayjs(record.periodStartDate),
          dayjs(record.periodEndDate),
        ],
      };
    }
    form?.setFieldsValue(newRecord);
    setCurrentEditIncome(record);
    setEditMode(true);
  };
  const onDelete = async (id: number) => {
    try {
      const { DeleteIncome } = new IncomeService();
      const res = await DeleteIncome(id);
      if (res && res.status === 200) {
        getAllIncome();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const columns: TableProps<IIncome>["columns"] = [
    {
      title: "#",
      key: "#",
      render: (text, record, index) => index + 1,

      width: 32,
    },

    {
      title: "Ref Code",
      dataIndex: "refCode",
      key: "refCode",
      width: 60,
    },
    {
      title: "Category",
      dataIndex: "categoryName",
      key: "categoryName",
      width: 144,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 60,
      render: (text: number) => (text ? text.toLocaleString("en-US") : "-"),
    },
    {
      title: "Currency",
      dataIndex: "currencyId",
      key: "currencyId",
      width: 79,
      render: (text: number, record) =>
        record.currencyName ? record.currencyName : "-",
    },

    {
      title: "Amount (USD)",
      dataIndex: "rate",
      key: "rate",
      width: 108,
      render: (text: number, record) =>
        Number(record.amount * record.rate).toLocaleString("en-US"),
    },
    {
      title: "Receipt Date",
      dataIndex: "receiptDate",
      key: "receiptDate",
      width: 102,
      render: (text: string) =>
        text ? moment(text).format("YYYY.MM.DD") : "-",
    },
    {
      title: "Transaction Date",
      key: "transactionDate",
      dataIndex: "transactionDate",
      width: 102,
      render: (text: string) =>
        text ? moment(text).format("YYYY.MM.DD") : "-",
    },

    {
      title: "Tools",
      key: "tools",
      width: 102,
      render: (text, record) => (
        <Tools
          onDelete={async () => {
            await onDelete(record.id);
          }}
          onEdit={() => {
            onEdit(record);
          }}
          onAttach={() => onAttach(record)}
        />
      ),
    },
  ];

  return (
    <AddIncomeTableContainer>
      <Col span={24}>
        <Flex justify="space-between">
          <div className="title">Receipt Table</div>
          <Flex gap={12} className="totalAmount">
            <div className="total ">Total</div>
            <Flex gap={12}>
              <div className="amount">
                {allIncomes?.totalUSD.toLocaleString("en-US") || "0"}
              </div>
              <div className="currency">USD</div>
            </Flex>
          </Flex>
        </Flex>
      </Col>
      <Col span={24} className="mt-[16px]">
        <TableUI
          columns={columns}
          dataSource={allIncomes?.incomes.records}
          pagination={false}
          loading={loadings.allIncomes}
          rowKey={() => uniqueId()}
          scroll={{ x: 800 }}
          containerStyle={{
            padding: 0,
          }}
          rowClassName={"rowClass"}
        />
      </Col>
    </AddIncomeTableContainer>
  );
};

const Tools: FC<{
  onDelete: () => void;
  onEdit: () => void;
  onAttach: () => void;
}> = ({ onDelete, onEdit, onAttach }) => {
  return (
    <div className="flex justify-center items-center gap-[16px] text-[16px]">
      {/* <Guard action={EditVoyagePath}> */}
      <Tooltip title="Attach files">
        <span
          onClick={onAttach}
          className="material-symbols-outlined !text-[#02514C] text-[16px] cursor-pointer"
        >
          file_upload
        </span>
      </Tooltip>
      {/* </Guard> */}
      {/* <Guard action={EditVoyagePath}> */}
      <Tooltip title="Edit">
        <span
          onClick={onEdit}
          className="material-icons !text-[#01C18F] text-[16px] cursor-pointer"
        >
          edit_note
        </span>
      </Tooltip>
      {/* </Guard> */}
      {/* <Guard action={DeleteVoyagePath}> */}
      <Popconfirm
        placement="bottom"
        title="Delete Voyage"
        description="Are you sure you want to Delete this Voyage?"
        okText="Yes"
        cancelText="No"
        onConfirm={onDelete}
      >
        <Tooltip title="Delete">
          <span className="material-icons !text-[#FF1414] text-[16px] cursor-pointer">
            delete_forever
          </span>
        </Tooltip>
      </Popconfirm>
      {/* </Guard> */}
    </div>
  );
};
