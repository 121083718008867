import { Row } from "antd";
import styled from "styled-components";
export * from "./attachment";
export const FinancialContainer = styled(Row)`
  .content {
    border-radius: 20px;
    background: var(--color-Base-Color-White, #fff);
    box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
    padding: 24px 32px;
  }
  .title {
    color: var(--color-Light-Mode-Text-Title, #001377);
    /* En/Headline/H6.SemiBold */
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 8px;
  }
  .currency {
    .ant-select-selector {
      border-radius: 12px 0px 0px 12px !important;
      border-right: 1px solid var(--color-Light-Mode-Border-3, #dadeec) !important;
    }
  }
  .subtitle {
    margin-bottom: 24px;
    color: var(--color-Light-Mode-Text-Title, #001377);
    /* En/Button/B2.Medium */
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .datePicker {
    background: #f4f7fe !important;
    border-color: #f4f7fe !important;
    border-radius: 12px !important;
    min-height: 48px !important;
    width: 100% !important;
    &.ant-picker-disabled {
      background: #dadeec !important;
      border-color: #dadeec !important;
    }
  }
  .selectOption .ant-select-disabled {
    .ant-select-selector {
      background: #dadeec !important;
    }
  }
  .uniqueField {
    color: var(--Dark-Mode-Secondary-Text, #949494);

    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-inline: 8px;
    margin-top: 4px;
  }
`;

export const AddIncomeTableContainer = styled(Row)`
  .title {
    color: var(--color-Light-Mode-Text-Title, #001377);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .total {
    border-radius: 8px 0px 0px 8px;
    background: var(--color-Light-Mode-Disable-Disable-2, #dadeec);
    color: var(--color-Light-Mode-Text-Primary-text, #3e4556);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 12px;
    min-width: 85px;
    text-align: center;
  }
  .totalAmount {
    background: var(--color-Light-Mode-BG-Background-1, #f2f7ff);
    border-radius: 0px 8px 8px 0px;
    padding-right: 12px;
  }
  .amount {
    padding-block: 12px;
    min-width: 136px;
    color: var(--color-Primary-Primary-600, #2c73fc);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .currency {
    padding-block: 12px;
    color: var(--color-Light-Mode-Text-Primary-text, #3e4556);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  thead {
    tr {
      th {
        color: var(--color-Light-Mode-Text-Primary-text, #3e4556) !important;
        font-size: 12px !important;
        font-style: normal;
        font-weight: 700 !important;
        line-height: normal;
        white-space: nowrap;
      }
    }
  }
  .rowClass {
    td {
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      color: var(--color-Light-Mode-Text-Primary-text, #3e4556);
    }
  }
`;
