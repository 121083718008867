import { Row } from "antd";
import styled from "styled-components";

export const TableContainer = styled(Row)`
  position: relative;
  .download {
    position: absolute;
    left: 19px;
    bottom: 16px;
  }
`;
