import { Button, Col, DatePicker, Flex, Form, Row } from "antd";
import { FC, useMemo, useState } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import { SelectUiKit } from "src/components/UiKit/select";
import { TurnoverFormContainer } from "../style";
import { useForm } from "antd/es/form/Form";
import { useTurnoverReport } from "../context";
import usePublicApiStore from "src/store/publicApi";
import { CardsInfo } from "../constants/CardsInfo";
import { Card } from "./card";
import {
  ITurnoverReport,
  ITurnoverReportResult,
} from "src/services/InvoiceReport/models";
import { InvoiceReportService } from "src/services/InvoiceReport/InvoiceReport.service";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

export interface IFormValues {
  ShipIds?: number[];
  VoyageId?: number;
  date: string[];
}
export const TurnoverForm: FC = () => {
  const {
    value: { voyageLoading, voyages },
    func: { getVoyageNo },
  } = useTurnoverReport()!;
  const [form] = useForm();
  const { ships } = usePublicApiStore();
  const defaultData = {
    balance: 0,
    totalExpenses: 0,
    totalIncome: 0,
  };
  const [data, setData] = useState<ITurnoverReportResult>(defaultData);
  const [loading, setLoading] = useState<boolean>(false);
  const values = Form.useWatch([], form);
  const isDisabledResetForm = useMemo(() => {
    let isValidate: boolean = true;
    if (!values) {
      isValidate = true;
      return;
    }
    for (const val of Object.keys(values)) {
      if (values[val] !== undefined) {
        isValidate = false;
        break;
      }
    }
    return isValidate;
  }, [values]);

  const onFinish = async (values: IFormValues) => {
    try {
      setLoading(true);
      const { TurnoverReport } = new InvoiceReportService();
      const newValue: ITurnoverReport = {
        shipIds: values.ShipIds ? values.ShipIds : undefined,
        voyageId: values.VoyageId ? values.VoyageId : undefined,
        fromOurDate: values.date
          ? dayjs(values.date[0]).format("YYYY-MM-DD")
          : undefined,
        toOurDate: values.date
          ? dayjs(values.date[1]).format("YYYY-MM-DD")
          : undefined,
      };
      const res = await TurnoverReport(newValue);
      if (res && res.data && res.status === 200) {
        setData(res.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <TurnoverFormContainer>
      <div className="font-[16px]">
        Please select the ship to view its average expenses.
      </div>
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={(err) => {
          console.log(err);
        }}
      >
        <Row gutter={[24, 0]}>
          <Col xs={24} md={8}>
            <FormItem name={"ShipIds"} label="Vessel">
              <SelectUiKit
                options={ships?.data?.map((i) => ({
                  label: i.name,
                  value: i.id,
                  name: i.abbreviation,
                }))}
                showSearch
                allowClear
                optionFilterProp="label"
                optionLabelProp="name"
                onChange={(value) => {
                  if (value) {
                    getVoyageNo({ VesselIds: value });
                  } else {
                    getVoyageNo();
                  }
                }}
                mode="multiple"
              />
            </FormItem>
          </Col>
          <Col xs={24} md={8}>
            <FormItem name={"VoyageId"} label="Voyage No.">
              <SelectUiKit
                options={voyages.map((i) => ({
                  label: i.voyageNo,
                  value: i.voyageId,
                }))}
                showSearch
                allowClear
                optionFilterProp="label"
                loading={voyageLoading}
              />
            </FormItem>
          </Col>
          <Col xs={24} md={8}>
            <FormItem label="Date" name={"date"}>
              <RangePicker allowClear className="datePicker" />
            </FormItem>
          </Col>
        </Row>
      </Form>
      <Col span={24}>
        <Flex gap={16} justify="flex-end" className="buttonHolder">
          <Button
            className="w-[85px] !h-[38px] "
            onClick={() => {
              form?.resetFields();
            }}
            disabled={isDisabledResetForm}
          >
            Reset
          </Button>
          <Button
            type="primary"
            className="w-[85px] !h-[40px]"
            disabled={isDisabledResetForm}
            onClick={form?.submit}
            loading={loading}
          >
            OK
          </Button>
        </Flex>
      </Col>
      <Col span={24}>
        <Row gutter={[24, 24]}>
          {CardsInfo.map((i, index) => (
            <Col xs={24} md={8} key={index}>
              <Card
                color={i.color}
                amount={data[i.amount]}
                desc={i.desc}
                img={i.img}
              />
            </Col>
          ))}
        </Row>
      </Col>
    </TurnoverFormContainer>
  );
};
