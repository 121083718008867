import { Col, Collapse, Flex, Row, Spin, Tabs, TabsProps } from "antd";
import moment from "moment";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { IncomeService } from "src/services/Income/Income.service";
import {
  IAllIncomeAttachmentDetails,
  IIncome,
} from "src/services/Income/models";
import { IncomeChildContainer } from "../styles";
import { Item } from "./incomeViewItem";
import { IncomeAttachmentTab } from "./incomeAttachmentTab";
import { ITreeCategory } from "src/services/System/models";
import { useFinancial } from "src/modules/Financial/context";

export const IncomeTab: FC<{ income: IIncome; print?: boolean }> = ({
  income,
  print,
}) => {
  const [attachLoading, setAttachLoading] = useState<boolean>(true);
  const [allAttachment, setAllAttachment] = useState<
    IAllIncomeAttachmentDetails[]
  >([]);
  const getAttachments = useCallback(async () => {
    if (!income) return;
    setAttachLoading(true);
    try {
      const { AllIncomeAttachmentDetails } = new IncomeService();
      const res = await AllIncomeAttachmentDetails(income.id);
      if (res && res.data && res.status === 200) {
        setAllAttachment(res.data.records);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAttachLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [income]);
  const tabsItems: TabsProps["items"] = allAttachment?.map((item, index) => {
    return {
      label: `${index + 1 < 10 ? "0".concat(String(index + 1)) : index + 1}`,
      key: index.toString(),
      children: <IncomeAttachmentTab attachment={item} />,
    };
  });

  const {
    value: { categories: allCategory },
  } = useFinancial();
  const left = [
    {
      label: "Receipt Date",
      value: income.receiptDate
        ? moment(income.receiptDate).format("YYYY.MM.DD")
        : "--",
    },
    {
      label: "Period date",
      value:
        income.periodStartDate && income.periodEndDate
          ? moment(income.periodStartDate)
              .format("YYYY.MM.DD")
              .concat(`_${moment(income.periodEndDate).format("YYYY.MM.DD")}`)
          : "--",
    },
    { label: "Payer", value: income.payerName || "--" },
    {
      label: "Amount",
      value: (income.amount.toString() || "-").concat(
        ` ${income.currencyName}`
      ),
    },
  ];
  const right = [
    {
      label: "Transaction Date",
      value: income.transactionDate
        ? moment(income.transactionDate).format("YYYY.MM.DD")
        : "--",
    },
    { label: "Receipt No.", value: income.receiptNo || "--" },
    { label: "Receiver", value: income.receiverName || "--" },
    { label: "Rate (To USD)", value: income.rate || "--" },
  ];
  useEffect(() => {
    getAttachments();
  }, [getAttachments]);
  const createCategoryPath: (
    categoryId: number,
    category: ITreeCategory,
    path: string
  ) => string | undefined = (categoryId, category, path) => {
    path = path + "/" + category.name;
    if (categoryId === category.id) {
      return path;
    } else {
      if (category.childeren.length > 0) {
        for (const child of category.childeren) {
          const childCat: string | undefined = createCategoryPath(
            income.categoryId,
            child,
            path
          );
          if (childCat) {
            return childCat;
          }
        }
      }
    }
  };
  const categoryPath = useMemo(() => {
    if (allCategory.length > 0) {
      for (const category of allCategory) {
        const currentCategory = createCategoryPath(
          income.categoryId,
          category,
          ""
        );
        if (currentCategory) {
          return currentCategory;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCategory, income.categoryId]);
  return (
    <IncomeChildContainer className="mt-[8px]" gap={24} vertical>
      <div className="categoryName">
        <span className="fullCode">{income.categoryFullCode}</span>
        {categoryPath?.substring(1)}
      </div>
      <Row>
        <Col span={24} className="detail">
          <Row gutter={16}>
            <Col span={12}>
              <Flex vertical gap={32}>
                {left.map((i, index) => (
                  <Item {...i} key={index} />
                ))}
              </Flex>
            </Col>
            <Col span={12}>
              <Flex vertical gap={32}>
                {right.map((i, index) => (
                  <Item {...i} key={index} />
                ))}
              </Flex>
            </Col>
          </Row>
        </Col>

        <Col span={24} className="total">
          <Row>
            <Col span={12} className="totalTitle">
              Total (USD)
            </Col>
            <Col span={12} className="totalAmount">
              {(income.rate * income.amount).toLocaleString("en-US", {
                maximumFractionDigits: 4,
              })}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="detail !rounded-[8px]">
          <Item label="Description" value={income.description || "--"} />
        </Col>
      </Row>
      <Collapse
        items={[
          {
            key: "1",
            label: attachLoading ? (
              <Flex justify="center">
                <Spin />
              </Flex>
            ) : (
              <div>
                <span className="attachLabel">Attachment</span>
                <span className="attachCount">
                  {allAttachment.length} files attached.
                </span>
              </div>
            ),
            children: <Tabs centered items={tabsItems} className="mt-[24px]" />,
          },
        ]}
        collapsible={attachLoading ? "disabled" : undefined}
        expandIconPosition="start"
        className={`IncomeCollapse  ${print && "hidden"}`}
        expandIcon={({ isActive }) => {
          return !isActive ? (
            <span className="material-icons !text-[#A5ABC0] !text-[24px]">
              keyboard_arrow_down
            </span>
          ) : (
            <span className="material-icons !text-[#A5ABC0] !text-[24px]">
              keyboard_arrow_up
            </span>
          );
        }}
      />
    </IncomeChildContainer>
  );
};
