import styled from "styled-components";

export const FiltersContainer = styled.div`
  border-radius: 16px;
  background: var(--color-Base-Color-White, #fff);
  margin-bottom: 32px;
  overflow-x: auto;
  .filterIconHolder {
    height: 40px;
    width: 40px;
    border-radius: 8px;
    background: var(--color-Primary-Primary-600, #2c73fc);
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }
  .total {
    color: var(--Secondary-Grey-600, #a3aed0);
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.28px;
  }
  .amount {
    color: var(--Secondary-Dark-Grey-900, #1b2559);
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 214.286% */
    letter-spacing: -0.28px;
  }
`;
