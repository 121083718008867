import { ROUTES } from "../../../models/enums/routes";
import { getItem } from "../helper/createItem";
import { Link } from "react-router-dom";
import useRoutesAccessStore from "src/store/routesAccess";

export const useSidebarItems = (activeKey: string) => {
  const { routes } = useRoutesAccessStore();
  const financial =
    routes?.FinancialIncome || routes.table || routes.ProfitReport;
  const commercial = routes.Commercial || routes.ProfitReport;
  const operation = routes.ProfitReport || routes?.ShipDailyReport;
  const parties =
    routes.ShipManagement ||
    routes.Supplier ||
    routes?.Receiver ||
    routes?.Agent ||
    routes.Payer ||
    routes.Requester;
  const basicInfo = routes.Country || routes.Currency || routes.Port;
  const sysSetting =
    routes.CreateRole ||
    routes.AssignActionToRole ||
    routes.CreateUser ||
    routes.AssignRoleToUser ||
    routes.FrontAccess;
  const items = [
    getItem(
      <Link to={ROUTES.home}>{"Dashboard"}</Link>,
      ROUTES.home,
      <span className="material-icons !text-[#a3aed0] !text-[24px]	">
        dashboard
      </span>
    ),
    routes?.AddShips
      ? getItem(
          <Link to={ROUTES.AddShips}> {"Ships"}</Link>,
          ROUTES.AddShips,
          <span className="material-icons !text-[#a3aed0] !text-[24px]">
            sailing
          </span>
        )
      : null,
    routes?.Category
      ? getItem(
          <Link to={ROUTES.Category}> {"Category"}</Link>,
          ROUTES.Category,
          <span className="material-icons !text-[#a3aed0] !text-[24px]">
            category
          </span>
        )
      : null,
    financial
      ? getItem(
          "Financial",
          "/financial",
          <span className="material-icons !text-[#a3aed0] !text-[24px]">
            calculate
          </span>,
          [
            routes?.table
              ? getItem(
                  <Link to={ROUTES.table}> {"Zip Files"}</Link>,
                  ROUTES.table
                )
              : null,
            routes?.FinancialIncome
              ? getItem(
                  <Link to={ROUTES.FinancialIncome}> {"Income"}</Link>,
                  ROUTES.FinancialIncome
                )
              : null,
            routes?.ProfitReport
              ? getItem(
                  <Link to={ROUTES.ProfitReport}>{"Profit and Loss"}</Link>,
                  ROUTES.ProfitReport
                )
              : null,
          ]
        )
      : null,
    commercial
      ? getItem(
          "Commercial",
          "/Commercial",
          <span className="material-icons !text-[#a3aed0] !text-[24px]">
            business_center
          </span>,
          [
            routes?.Commercial
              ? getItem(
                  <Link to={ROUTES.Commercial}> {"Add Voyage"}</Link>,
                  ROUTES.Commercial
                )
              : null,
            routes?.ProfitReport
              ? getItem(
                  <Link to={ROUTES.ProfitReport}>{"Profit and Loss"}</Link>,
                  ROUTES.ProfitReport
                )
              : null,
          ]
        )
      : null,
    operation
      ? getItem(
          "Operation",
          "/Operation",
          <span className="material-icons !text-[#a3aed0] !text-[24px]">
            anchor
          </span>,
          [
            routes?.ShipDailyReport
              ? getItem(
                  <Link to={ROUTES.ShipDailyReport}>
                    {"Ship Daily Report"}
                  </Link>,
                  ROUTES.ShipDailyReport
                )
              : null,
            routes?.ProfitReport
              ? getItem(
                  <Link to={ROUTES.ProfitReport}>{"Profit and Loss"}</Link>,
                  ROUTES.ProfitReport
                )
              : null,
          ]
        )
      : null,
    routes?.incomeReport ||
    routes.dailyAverageReport ||
    routes?.Report ||
    routes.TurnoverReport ||
    routes?.ExpensiveReport
      ? getItem(
          "Report",
          "/Report",
          <span className="material-icons !text-[#a3aed0] !text-[24px]">
            difference
          </span>,
          [
            routes?.Report
              ? getItem(
                  <Link to={ROUTES.Report}> {"Invoice Report"}</Link>,
                  ROUTES.Report
                )
              : null,
            routes?.ExpensiveReport
              ? getItem(
                  <Link to={ROUTES.ExpensiveReport}>{"Expensive Report"}</Link>,
                  ROUTES.ExpensiveReport
                )
              : null,
            routes?.incomeReport
              ? getItem(
                  <Link to={ROUTES.incomeReport}> {"Income Report"}</Link>,
                  ROUTES.incomeReport
                )
              : null,
            routes.TurnoverReport
              ? getItem(
                  <Link to={ROUTES.TurnoverReport}>{"Turnover Report"}</Link>,
                  ROUTES.TurnoverReport
                )
              : null,
            routes.dailyAverageReport
              ? getItem(
                  <Link to={ROUTES.dailyAverageReport}>
                    {"Daily Average Report"}
                  </Link>,
                  ROUTES.dailyAverageReport
                )
              : null,
          ]
        )
      : null,
    parties
      ? getItem(
          "Parties",
          "/parties",
          <span className="material-icons !text-[#a3aed0] !text-[24px]">
            group
          </span>,
          [
            routes?.ShipManagement
              ? getItem(
                  <Link to={ROUTES.ShipManagement}> {"Ship Management"}</Link>,
                  ROUTES.ShipManagement
                )
              : null,
            routes.Supplier
              ? getItem(
                  <Link to={ROUTES.Supplier}> {"Supplier"}</Link>,
                  ROUTES.Supplier
                )
              : null,
            routes.Requester
              ? getItem(
                  <Link to={ROUTES.Requester}> {"Requester"}</Link>,
                  ROUTES.Requester
                )
              : null,
            routes.Agent
              ? getItem(<Link to={ROUTES.Agent}> {"Agent"}</Link>, ROUTES.Agent)
              : null,
            routes.Receiver
              ? getItem(
                  <Link to={ROUTES.Receiver}>{"Receiver"}</Link>,
                  ROUTES.Receiver
                )
              : null,
            routes.Payer
              ? getItem(<Link to={ROUTES.Payer}>{"Payer"}</Link>, ROUTES.Payer)
              : null,
          ]
        )
      : null,
    basicInfo
      ? getItem(
          "Basic Information",
          "/basicInfo",
          <span className="material-icons !text-[#a3aed0] !text-[24px]">
            display_settings
          </span>,
          [
            routes.Country
              ? getItem(
                  <Link to={ROUTES.Country}> {"Country"}</Link>,
                  ROUTES.Country
                )
              : null,
            routes.Port
              ? getItem(<Link to={ROUTES.Port}> {"Ports"}</Link>, ROUTES.Port)
              : null,
            routes.Currency
              ? getItem(
                  <Link to={ROUTES.Currency}>{"Currency"}</Link>,
                  ROUTES.Currency
                )
              : null,
          ]
        )
      : null,
    sysSetting
      ? getItem(
          "System settings",
          ROUTES.settings,
          <span className="material-icons !text-[#a3aed0] !text-[24px]">
            settings
          </span>,
          [
            routes.CreateRole
              ? getItem(
                  <Link to={ROUTES.CreateRole}> {"Create role"}</Link>,
                  ROUTES.CreateRole
                )
              : null,
            routes.AssignActionToRole
              ? getItem(
                  <Link to={ROUTES.AssignActionToRole}>
                    {"Assign action to role"}
                  </Link>,
                  ROUTES.AssignActionToRole
                )
              : null,
            routes.CreateUser
              ? getItem(
                  <Link to={ROUTES.CreateUser}> {"Create user"}</Link>,
                  ROUTES.CreateUser
                )
              : null,
            routes.AssignRoleToUser
              ? getItem(
                  <Link to={ROUTES.AssignRoleToUser}>
                    {"Assign role to user"}
                  </Link>,
                  ROUTES.AssignRoleToUser
                )
              : null,
            // routes.FrontAccess
            //   ? getItem(
            //       <Link to={ROUTES.FrontAccess}>Front Access</Link>,
            //       ROUTES.FrontAccess
            //     )
            //   : null,
          ]
        )
      : null,
  ];
  const finallyItems = items.filter((item) => !!item);
  return finallyItems;
};
