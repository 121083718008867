import { useEffect, useState } from "react";
import { useQueries } from "react-query";
import { reactQueryEndPoints } from "src/constants/rectQueryEndPoints";
import { PublicService } from "src/services/Public/Public.service";
import usePublicApiStore from "src/store/publicApi";
const service = new PublicService();

const fetchShip = async () => {
  try {
    const res = await service.Ship();
    if (res && res.status === 200 && res.data) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
  }
};
const fetchRequester = async () => {
  try {
    const res = await service.Requester();
    if (res && res.status === 200 && res.data) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
  }
};
const fetchAllCurrency = async () => {
  try {
    const res = await service.AllCurrency();
    if (res && res.status === 200 && res.data) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
  }
};
export const usePublicApi = () => {
  const { setShips, setRequesters, setAllCurrency } = usePublicApiStore();
  const [fetchData, setFetchData] = useState({
    ship: true,
    requester: true,
    allCurrency: true,
  });
  const [ship, requester, allCurrency] = useQueries([
    {
      queryKey: reactQueryEndPoints.publicShip,
      queryFn: fetchShip,
      enabled: fetchData.ship,
    },
    {
      queryKey: reactQueryEndPoints.publicRequester,
      queryFn: fetchRequester,
      enabled: fetchData.requester,
    },
    {
      queryKey: reactQueryEndPoints.publicAllCurrency,
      queryFn: fetchAllCurrency,
      enabled: fetchData.allCurrency,
    },
  ]);
  useEffect(() => {
    setFetchData({
      ship: !ship.data,
      requester: !requester.data,
      allCurrency: !allCurrency.data,
    });
    if (ship) setShips(ship);
    if (requester) setRequesters(requester);
    if (allCurrency) setAllCurrency(allCurrency);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requester.data, ship.data, allCurrency.data]);
  return {
    shipRefetch: ship.refetch,
    requesterRefetch: requester.refetch,
    allCurrencyRefetch: allCurrency.refetch,
  };
};
