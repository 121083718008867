import { Row } from "antd";
import styled from "styled-components";

export const IncomeReportContainer = styled.div``;
export const HeaderContainer = styled.div`
  display: flex;
  height: 80px;
  padding: 24px;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid var(--color-Light-Mode-Border-3, #dadeec);
`;
export const IncomeFormContainer = styled(Row)`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  border-radius: 16px;
  background: var(--color-Base-Color-White, #fff);
  .filterContainer {
    display: flex;
    height: 40px;
    width: 40px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    background: var(--color-Gray-Gray-100, #f5f5f5);
  }
  .datePicker {
    background: #f4f7fe !important;
    border-color: #f4f7fe !important;
    border-radius: 12px !important;
    min-height: 48px !important;
    width: 100% !important;
    &.ant-picker-disabled {
      background: #dadeec !important;
      border-color: #dadeec !important;
    }
  }
  .absoluteLabel {
    position: absolute;
    top: 6px;
    left: 24px;
    z-index: 10;
    white-space: nowrap;
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ant-picker-suffix {
    color: #a5abc0;
  }
  .buttonHolder {
    padding-top: 24px;
    gap: 16px;
    border-top: 1px solid var(--color-Light-Mode-Border-3, #dadeec);
  }
  .ant-btn-background-ghost:disabled {
    border-color: #bdddff !important;
  }
`;
export const IncomeTableContainer = styled(Row)`
  padding: 0px 32px;
  gap: 40px;
  .leftBoxHolder {
    height: 84px;
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 16px;
    background: var(--color-Base-Color-White, #fff);
    overflow: scroll;
  }
  .filterHolder {
    display: flex;
    height: 40px;
    white-space: nowrap;
    padding: 8px 12px;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    border: 1px solid var(--color-Light-Mode-Border-1, #a3aed0);
  }
  .rightBoxHolder {
    height: 84px;
    min-width: 80px;
    padding: 8px 16px;
    border-radius: 16px;
    background: var(--Secondary-Primary-White, #fff);
  }

  /* .ant-table-wrapper::after {
    img {
      src: "/assets/images/dlExcelIncome.svg";
    }
  } */
`;
