import { FC } from "react";
import { Header } from "./components/Header";
import { InvoiceFilters } from "./components/InvoiceFilters";
import { INVOICE_REPORT_TYPES } from "./models/reportTypes";
import { ArticleReportTable } from "./components/Tables/Article";
import { InvoiceReportFilters } from "./components/Filters";
import { useExpensiveReport } from "./context";

export const ExpensiveReport: FC = () => {
  const {
    value: { reportType },
  } = useExpensiveReport();
  return (
    <>
      <Header />
      {!reportType && <InvoiceFilters />}
      {reportType === INVOICE_REPORT_TYPES.Article && <InvoiceReportFilters />}
      {reportType === INVOICE_REPORT_TYPES.Article && <ArticleReportTable />}
    </>
  );
};
