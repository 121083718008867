import { Flex, Row } from "antd";
import styled from "styled-components";

export const ViewIncomeContainer = styled(Row)`
  .vesselName {
    color: var(--color-Light-Mode-Text-Title, #001377);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .voyageNo {
    color: var(--color-Light-Mode-Text-Primary-text, #3e4556);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .subtitle {
    color: var(--color-Light-Mode-Text-Secondary-Text, #5d6881);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 4px;
  }
  .edit {
    border-radius: 8px;
    background: var(--color-Success-Success-100, #f2faf6);
    color: #47b881;
    padding: 4px;
    cursor: pointer;
    user-select: none;
  }
  .delete {
    border-radius: 8px;
    background: var(--color-Danger-Danger-100, #fef2f2);
    color: #ec2d30;
    padding: 4px;
    cursor: pointer;
    user-select: none;
  }
  .footerText {
    color: var(--color-Light-Mode-Text-Secondary-Text, #5d6881);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ant-collapse-header {
    padding: 24px !important;
  }
  .ant-collapse-content-box {
    padding: 24px !important;
  }
`;
export const IncomeChildContainer = styled(Flex)`
  .categoryName {
    color: var(--color-Light-Mode-Text-Third-Text, #878787);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 24px 16px 0px 16px;
    .fullCode {
      color: var(--color-Light-Mode-Text-Primary-text, #3e4556);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-right: 10px;
    }
  }
  .detail {
    border-radius: 8px 8px 0px 0px;
    background: var(--color-Light-Mode-BG-Background-1, #f2f7ff);
    padding: 24px;
  }

  .total {
    border-radius: 0px 0px 8px 8px;
    background: var(--color-secondary-secondary-200, #ecfaf9);
    padding: 24px;
    margin-top: 4px;
  }
  .totalTitle {
    color: var(--color-Light-Mode-Text-Title, #001377);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .totalAmount {
    color: var(--color-Light-Mode-Text-Secondary-Text, #5d6881);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .IncomeCollapse {
    border-radius: 8px !important;
    background: var(--color-Light-Mode-BG-Background-1, #f2f7ff);
    border-color: transparent !important;
    .ant-collapse-content {
      border-top: unset !important;
    }
    .ant-collapse-content-box {
      background: var(--color-Light-Mode-BG-Background-1, #f2f7ff);
    }
  }
  .attachLabel {
    color: var(--color-Light-Mode-Text-Title, #001377);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .attachCount {
    color: var(--color-Light-Mode-Text-Secondary-Text, #5d6881);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 16px;
  }
`;

export const IncomeViewItemStyle = styled(Flex)`
  .label {
    color: var(--color-Light-Mode-Text-Title, #001377);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 130px;
  }
  .value {
    color: var(--color-Light-Mode-Text-Secondary-Text, #5d6881);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: calc(100% - 142px);
  }
`;
