import { Button, Col, Divider, Flex, Modal, Spin, Tabs, TabsProps } from "antd";
import { FC, useCallback, useRef } from "react";
import { useFinancial } from "../../context";

import { IncomeTab } from "./components/incomeTab";
import { ViewIncomeContainer } from "./styles";
import ReactToPrint, { useReactToPrint } from "react-to-print";

export const IncomeView: FC = () => {
  const {
    value: { showView, currentEditVoyage, allIncomes, loadings },
    func: { onCloseView, goToAddIncome },
  } = useFinancial();
  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef.current]);

  const tabsItems: TabsProps["items"] = allIncomes?.incomes.records.map(
    (item) => {
      return {
        label: `Ref Code ${item.refCode}`,
        key: item.id.toString(),
        children: <IncomeTab income={item} />,
      };
    }
  );

  const tabsItemsPrint: TabsProps["items"] = allIncomes?.incomes.records.map(
    (item) => {
      return {
        label: `Ref Code ${item.refCode}`,
        key: item.id.toString(),
        children: <IncomeTab income={item} print={true} />,
      };
    }
  );
  return (
    <Modal
      open={showView}
      onCancel={onCloseView}
      closable={false}
      footer={null}
      width={710}
      style={{ top: 32 }}
      styles={{
        content: {
          padding: "16px 16px 32px 16px",
        },
      }}
    >
      <ReactToPrint
        content={reactToPrintContent}
        documentTitle="Income"
        removeAfterPrint
      />
      {loadings.allIncomes ? (
        <Flex className="h-[500px] w-full" justify="center" align="center">
          <Spin />
        </Flex>
      ) : (
        <ViewIncomeContainer>
          <Col span={24}>
            <Flex align="center" justify="space-between">
              <div>
                <Flex gap={12}>
                  <span className="vesselName">
                    {currentEditVoyage?.vesselName}
                  </span>
                  <span className="voyageNo">
                    {currentEditVoyage?.voyageNo}
                  </span>
                </Flex>
                <div className="subtitle">All income’s factors</div>
              </div>
              <Flex align="center" gap={12}>
                <span
                  className="material-icons edit"
                  onClick={() => {
                    onCloseView();
                    goToAddIncome(currentEditVoyage);
                  }}
                >
                  edit_note
                </span>
              </Flex>
            </Flex>
          </Col>
          <Tabs centered items={tabsItems} className="mt-[24px] w-full" />
          <Divider />
          <Flex
            gap={16}
            justify="space-between"
            align="center"
            className={`w-full`}
          >
            <div className="footerText">
              If this page is printed, the "Attachment" section will not be
              included in the printout.
            </div>
            <Flex gap={16} justify="end" align="center">
              <Button
                icon={<span className="material-icons">close</span>}
                onClick={onCloseView}
              >
                Close
              </Button>
              <Button
                type="primary"
                icon={<span className="material-icons">print</span>}
                onClick={handlePrint}
              >
                Print
              </Button>
            </Flex>
          </Flex>
        </ViewIncomeContainer>
      )}
      {/*For Print  */}
      <div className="hidden">
        <ViewIncomeContainer ref={componentRef} className="p-[24px]">
          <Col span={24}>
            <Flex align="center" justify="space-between">
              <div>
                <Flex gap={12}>
                  <span className="vesselName">
                    {currentEditVoyage?.vesselName}
                  </span>
                  <span className="voyageNo">
                    {currentEditVoyage?.voyageNo}
                  </span>
                </Flex>
                <div className="subtitle">All income’s factors</div>
              </div>
            </Flex>
          </Col>
          <Tabs centered items={tabsItemsPrint} className="mt-[24px]" />
        </ViewIncomeContainer>
      </div>
    </Modal>
  );
};
