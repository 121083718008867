import { FC } from "react";
import { TurnoverReport } from "src/modules/TurnoverReport";
import { TurnoverReportProvider } from "src/modules/TurnoverReport/context";

export const TurnoverReportPage: FC = () => {
  return (
    <TurnoverReportProvider>
      <TurnoverReport />
    </TurnoverReportProvider>
  );
};
