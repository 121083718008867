import {
  App,
  Button,
  Col,
  Divider,
  Flex,
  Form,
  Row,
  Upload,
  UploadProps,
} from "antd";
import { FC, useEffect, useMemo, useState } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { AttachmentTable } from "./AttachmentTable";
import { UploadChangeParam, UploadFile } from "antd/es/upload";
import { dummyRequest } from "src/helpers/dummyRequest";
import { LoadingOutlined } from "@ant-design/icons";
import { AttachmentContainer } from "./styles";
import { useAttachIncome } from "./context/attachFile";
import { IncomeService } from "src/services/Income/Income.service";
import { MethodsType, uploadFile } from "src/helpers/uploadZipFile";
import { IncomeUrls } from "src/services/Income/urls";
interface IFormValue {
  file: {
    file: UploadFile;
    fileList: UploadFile[];
  };
  fileName: string;
  description?: string;
}
export const IncomeAttachment: FC = () => {
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [progress, setProgress] = useState<number>(0);
  const [submittable, setSubmittable] = useState<boolean>(false);

  const { message } = App.useApp();
  const {
    value: { currentIncome, attachmentForm, currentEditAttachment },
    func: { getAttachments },
    dispatch: { setCurrentEditAttachment },
  } = useAttachIncome();

  const onFinish = async (values: IFormValue) => {
    if (!currentIncome) return;
    setLoading(true);
    const { file, fileName, description } = values;
    try {
      const { NewIncomeAttachmentDetail, UpdateIncomeAttachmentDetail } =
        new IncomeService();
      let res;
      const reqBody = {
        fileName,
        description,
      };
      if (currentEditAttachment) {
        res = await UpdateIncomeAttachmentDetail(
          currentEditAttachment.id,
          reqBody
        );
      } else {
        res = await NewIncomeAttachmentDetail(currentIncome.id, reqBody);
      }
      if (res && res.status === 200) {
        let endpoint = IncomeUrls.income.concat(
          `${currentEditAttachment ? currentEditAttachment.id : res.data}${
            IncomeUrls.NewIncomeAttachment
          }`
        );

        if (fileList.length > 0) {
          const uploadProps = {
            callbackUrl: endpoint,
            extraData: { name: fileName, description },
            newFile: file.file.originFileObj,
            setProgress,
            method: (currentEditAttachment ? "PATCH" : "POST") as MethodsType,
          };

          await uploadFile(uploadProps);
        }
        reset();
        message.success("Successfully uploaded");
        setCurrentEditAttachment(undefined);
        getAttachments();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    setFileList([info.file]);

    if (info.file.status === "uploading") {
      return;
    }
  };
  const uploadButton = useMemo(
    () => (
      <div>
        {loading ? (
          <LoadingOutlined color="#A3AED0" size={13} />
        ) : fileList.length > 0 || !!currentEditAttachment ? (
          <span className="material-icons text-[#A3AED0] text-[13.5px]">
            done
          </span>
        ) : (
          <span className="material-icons text-[#A3AED0] text-[13.5px]">
            attach_file
          </span>
        )}
        {
          <div className="text-[#A3AED0] text-[6.8px]">
            {progress || "Attach"}
          </div>
        }
      </div>
    ),
    [currentEditAttachment, fileList.length, loading, progress]
  );
  const reset = () => {
    setFileList([]);
    attachmentForm?.resetFields();
    setCurrentEditAttachment(undefined);
  };
  const values = Form.useWatch([], attachmentForm);

  useEffect(() => {
    attachmentForm?.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);
  return (
    <AttachmentContainer>
      <Col span={24}>
        <h3 className="title">Attach files</h3>
        <h3 className="subtitle">Please attach the files .</h3>
      </Col>
      <Col span={24} className="py-[16px]">
        <Form form={attachmentForm} onFinish={onFinish}>
          <Row gutter={[24, 0]}>
            <Col span={10}>
              <Flex align="center" justify="space-between" gap={24}>
                <Flex justify="center" align="center" className=" ">
                  <FormItem
                    rules={[
                      {
                        required: currentEditAttachment?.uniqueSystemName
                          ? false
                          : true,
                        message: "file is required",
                      },
                    ]}
                    name={"file"}
                    className="mb-0 upload flex justify-center items-center"
                  >
                    <Upload
                      name="upload--file"
                      listType="picture-card"
                      className=" flex justify-center "
                      showUploadList={false}
                      // beforeUpload={beforeUpload}
                      onChange={handleChange}
                      customRequest={dummyRequest}
                      fileList={fileList}
                    >
                      {uploadButton}
                    </Upload>
                  </FormItem>
                </Flex>
                <Flex className="fileName">
                  <FormItem
                    rules={[{ required: true }]}
                    name={"fileName"}
                    label="File name"
                    className="w-full"
                  >
                    <SInput allowClear />
                  </FormItem>
                </Flex>
              </Flex>
            </Col>
            <Col span={14}>
              <FormItem label="Description" name={"description"}>
                <SInput allowClear />
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Col>
      <Col span={24} className="">
        <Flex gap={16} justify="flex-end">
          <Button className="min-w-[85px] !h-[40px]" onClick={reset}>
            Reset
          </Button>
          {/* <Guard action={[NewVoyagePath, EditVoyagePath]}> */}
          <Button
            type="primary"
            className="min-w-[85px] !h-[40px]"
            disabled={!submittable}
            onClick={attachmentForm?.submit}
            loading={loading}
          >
            {currentEditAttachment ? "Save edit" : "Add"}
          </Button>
          {/* </Guard> */}
        </Flex>
      </Col>
      <Col span={24}>
        <Divider />
      </Col>
      <AttachmentTable />
    </AttachmentContainer>
  );
};
