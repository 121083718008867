import { FC, useState } from "react";
import { DailyAverageReportContainer } from "../style";
import { Button, Col, Flex, Form, Radio, Row, Select } from "antd";
import { FormItem } from "src/components/UiKit/FormItem";
import usePublicApiStore from "src/store/publicApi";
import { DailyAverageCard } from "./Card";
import {
  IDailyAverageReport,
  IDailyAverageReportArg,
} from "src/services/InvoiceReport/models";
import { InvoiceReportService } from "src/services/InvoiceReport/InvoiceReport.service";
import moment from "moment";

export const DailyAverageReportContent: FC = () => {
  const { ships } = usePublicApiStore();
  const [form] = Form.useForm();
  const defaultData = {
    average: 0,
    fromDate: "",
    total: 0,
  };
  const [data, setData] = useState<IDailyAverageReport>(defaultData);
  const [loading, setLoading] = useState<boolean>(false);
  const onFinish = async (values: IDailyAverageReportArg) => {
    try {
      setLoading(true);
      const { DailyAverageReport } = new InvoiceReportService();
      const res = await DailyAverageReport(values);
      if (res && res.data && res.status === 200) {
        setData(res.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="p-[32px]">
      <DailyAverageReportContainer>
        <Col span={24}>
          <h3 className="title">
            Please select the ship to view its average expenses.
          </h3>
        </Col>
        <Col span={24}>
          <Form onFinish={onFinish} form={form}>
            <Row gutter={24}>
              <Col xs={24} md={8}>
                <FormItem name={"shipIds"} label="Vessel">
                  <Select
                    className="selectOption"
                    optionFilterProp="label"
                    optionLabelProp="name"
                    options={ships?.data?.map((ship) => ({
                      label: ship.name,
                      value: ship.id,
                      name: ship.abbreviation,
                    }))}
                    allowClear
                    mode="multiple"
                    placeholder="Select"
                  />
                </FormItem>
              </Col>
              <Col xs={24} md={8}>
                <FormItem
                  name={"withVoyage"}
                  label="Consider invoices with voyage number?"
                  initialValue={false}
                >
                  <Radio.Group className="flex items-center h-[48px]">
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>
              <Col span={24} className="!pb-[32px] footer">
                <Flex justify="flex-end" gap={16}>
                  <Button
                    type="default"
                    className="w-[85px]"
                    onClick={() => {
                      form.resetFields();
                      setData(defaultData);
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    type="primary"
                    className="w-[85px]"
                    loading={loading}
                    onClick={form.submit}
                  >
                    OK
                  </Button>
                </Flex>
              </Col>
            </Row>
          </Form>
        </Col>
        {data.fromDate && (
          <Col span={24}>
            <h3 className="fromDate">
              This report started of{" "}
              {data.fromDate && moment(data.fromDate).format("YYYY.MM.DD")}{" "}
              until today.
            </h3>
          </Col>
        )}
        <Col span={24}>
          <Flex wrap gap={24}>
            <DailyAverageCard amount={data.total} title="Total expenses" />
            <DailyAverageCard amount={data.average} title="Daily average" />
          </Flex>
        </Col>
      </DailyAverageReportContainer>
    </div>
  );
};
