import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { IncomeService } from "src/services/Income/Income.service";
import {
  IAllPayer,
  IAllReceivers,
  IAllVoyageIncomes,
  IAllVoyageIncomesArgs,
  IIncomeReportTable,
} from "src/services/Income/models";
import { IPublicCurrency } from "src/services/Public/models";
import { PublicService } from "src/services/Public/Public.service";
import { CategoryTypeEnum, ITreeCategory } from "src/services/System/models";
import { SystemService } from "src/services/System/System.service";
import dayjs from "dayjs";
import { ITablePagination } from "src/models/interfaces/pagination";
import { IComponent } from "./models";
import { IObject } from "src/models/interfaces";

interface IContext {
  value: {
    voyages: IAllVoyageIncomes[];
    category: ITreeCategory[];
    categoryLoading: boolean;
    voyageLoading: boolean;
    payerLoading: boolean;
    payer: IAllPayer[];
    currency: IPublicCurrency[];
    currencyLoading: boolean;
    receivers: IAllReceivers[];
    receiversLoading: boolean;
    loadingForm: boolean;
    tableDataSource: IIncomeReportTable | undefined;
    compHandler: IComponent;
    filters: IFormValue | undefined;
    pagination: ITablePagination;
  };
  dispatch: {
    setCompHandler: Dispatch<SetStateAction<IComponent>>;
    setFilters: Dispatch<SetStateAction<IFormValue | undefined>>;
    setPagination: Dispatch<SetStateAction<ITablePagination>>;
  };
  func: {
    onFinishForm: (values: IFormValue) => void;
    getVoyageNo: (queries?: IAllVoyageIncomesArgs) => void;
  };
}

export interface IFormValue extends IObject {
  vesselId?: number;
  voyageId?: number;
  refCode?: string;
  categoryId?: number;
  receiptNo?: string;
  payerId?: number;
  amountFrom?: string;
  amountTo?: string;
  currencyId?: number;
  receiverId?: number;
  receiptDate?: string[];
  transactionDate?: string[];
  pages: {
    current: number;
    pageSize: number;
  };
}
export const IncomeReportContext = createContext<IContext | undefined>(
  undefined
);

export const IncomeReportProvider: FC<PropsWithChildren> = ({ children }) => {
  const [compHandler, setCompHandler] = useState<IComponent>(IComponent.form);
  const [voyageLoading, setVoyageLoading] = useState<boolean>(false);
  const [voyages, setVoyages] = useState<IAllVoyageIncomes[]>([]);
  const [category, setCategory] = useState<ITreeCategory[]>([]);
  const [payer, setPayer] = useState<IAllPayer[]>([]);
  const [categoryLoading, setCategoryLoading] = useState<boolean>(false);
  const [payerLoading, setPayerLoading] = useState<boolean>(false);
  const [currency, setCurrency] = useState<IPublicCurrency[]>([]);
  const [currencyLoading, setCurrencyLoading] = useState<boolean>(false);
  const [receivers, setReceivers] = useState<IAllReceivers[]>([]);
  const [receiversLoading, setReceiversLoading] = useState<boolean>(false);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [tableDataSource, setTableDataSource] = useState<IIncomeReportTable>();
  const [pagination, setPagination] = useState<ITablePagination>({
    current: 1,
    pageSize: 10,
    total: 10,
  });
  const [filters, setFilters] = useState<IFormValue>();
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const getVoyageNo = useCallback(
    async (queries?: IAllVoyageIncomesArgs) => {
      try {
        setVoyageLoading(true);
        const service = new IncomeService();
        const res = await service.AllVoyageIncomes(queries);
        if (res && res.status === 200 && res.data) {
          setVoyages(res.data.records);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setVoyageLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getCategory = useCallback(async () => {
    try {
      setCategoryLoading(true);
      const { TreeCategory } = new SystemService();
      const res = await TreeCategory(undefined, CategoryTypeEnum.Income);
      if (res && res.status === 200 && res.data) {
        setCategory(res.data);
      } else {
        setCategory([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setCategoryLoading(false);
    }
  }, []);

  const getAllPayer = useCallback(async () => {
    try {
      setPayerLoading(true);
      const { AllPayers } = new IncomeService();
      const res = await AllPayers();
      if (res && res.status === 200 && res.data) {
        setPayer(res.data.records);
      } else {
        setPayer([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setPayerLoading(false);
    }
  }, []);

  const getAllCurrency = useCallback(async () => {
    try {
      setCurrencyLoading(true);
      const { AllCurrency } = new PublicService();
      const res = await AllCurrency();
      if (res && res.status === 200 && res.data) {
        setCurrency(res.data);
      } else {
        setPayer([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setCurrencyLoading(false);
    }
  }, []);
  const getAllReceivers = useCallback(async () => {
    try {
      setReceiversLoading(true);
      const { AllReceivers } = new IncomeService();
      const res = await AllReceivers();
      if (res && res.status === 200 && res.data) {
        setReceivers(res.data.records);
      } else {
        setReceivers([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setReceiversLoading(false);
    }
  }, []);

  const onFinishForm = async (values: IFormValue) => {
    setFilters(values);
    setShowFilter(true);
  };
  const fetchIncomeReport = async (values: IFormValue) => {
    try {
      setLoadingForm(true);
      const { IncomeReport } = new IncomeService();
      const newValues = {
        ...values,
        startReceiptDate: values.receiptDate
          ? dayjs(values.receiptDate[0]).format("YYYY-MM-DD")
          : undefined,
        endReceiptDate: values.receiptDate
          ? dayjs(values.receiptDate[1]).format("YYYY-MM-DD")
          : undefined,
        startTransactionDate: values.transactionDate
          ? dayjs(values.transactionDate[0]).format("YYYY-MM-DD")
          : undefined,
        endTransactionDate: values.transactionDate
          ? dayjs(values.transactionDate[1]).format("YYYY-MM-DD")
          : undefined,
        amountFrom: values.amountFrom ? +values.amountFrom : undefined,
        amountTo: values.amountTo ? +values.amountTo : undefined,
        page: { offset: pagination.current, limit: pagination.pageSize },
      };
      const res = await IncomeReport(newValues);
      if (res && res.status === 200 && res.data) {
        setTableDataSource(res.data);
        setCompHandler(IComponent.table);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingForm(false);
    }
  };
  useEffect(() => {
    if (showFilter && filters) {
      fetchIncomeReport(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, showFilter]);
  useEffect(() => {
    Promise.allSettled([
      getCategory(),
      getVoyageNo(),
      getAllPayer(),
      getAllCurrency(),
      getAllReceivers(),
    ]);
  }, [getCategory, getVoyageNo, getAllPayer, getAllCurrency, getAllReceivers]);
  const ContextValue: IContext = {
    value: {
      voyages,
      category,
      categoryLoading,
      voyageLoading,
      payer,
      payerLoading,
      currency,
      currencyLoading,
      receivers,
      receiversLoading,
      loadingForm,
      tableDataSource,
      compHandler,
      filters,
      pagination,
    },
    dispatch: { setCompHandler, setFilters, setPagination },
    func: { onFinishForm, getVoyageNo },
  };
  return (
    <IncomeReportContext.Provider value={ContextValue}>
      {children}
    </IncomeReportContext.Provider>
  );
};

export const useIncomeReport = () => useContext(IncomeReportContext);
