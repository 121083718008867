import { FC } from "react";

import { DailyAverageReport } from "src/modules/DailyAverageReport";

export const DailyAverageReportPage: FC = () => {
  return (
    // <CurrencyProvider>
    <DailyAverageReport />
    // </CurrencyProvider>
  );
};
