import { Row } from "antd";
import styled from "styled-components";

export const VoyageTableContainer = styled(Row)`
  .content {
    border-radius: 12px;
    background: var(--color-Base-Color-White, #fff);
    box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
    padding: 16px;
  }
  .select {
    .ant-select-selector {
      border-radius: 12px;
      border-color: rgb(219, 226, 230) !important;
    }
    .ant-select-arrow {
      margin-top: -6px !important;
    }
    &:hover {
      .ant-select-selector {
        border-color: rgb(219, 226, 230) !important;
      }
    }
  }
  .ant-select-focused {
    .ant-select-selector {
      border-color: rgb(219, 226, 230) !important;
    }
  }
  .tools {
    color: var(--color-Primary-Primary-700, #2867e3);

    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
    user-select: none;
    text-align: center;
  }
  .ant-select-selection-item-content {
    display: flex !important;
    align-items: center !important;
  }
  .ant-empty {
    min-height: 255px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;
