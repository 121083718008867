import { InputNumberProps } from "antd";
import { FC } from "react";
import { InputNumberStyle } from "./styles";
import { inputNumberFormatter } from "src/helpers/inputNumberFormatter";

interface IProps extends InputNumberProps {}
export const SInputNumber: FC<IProps> = (props) => {
  return (
    <InputNumberStyle
      changeOnWheel={false}
      controls={false}
      formatter={inputNumberFormatter}
      placeholder={"Insert"}
      {...props}
    />
  );
};
