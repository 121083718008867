import { Col, Divider } from "antd";
import { FC } from "react";
import { TopBarContainer } from "../../styles";
import { useExpensiveReport } from "../../context";
import { INVOICE_REPORT_TYPES } from "../../models/reportTypes";

export const Header: FC = () => {
  const {
    value: { reportType, defaultPagination },
    dispatch: { setReportType, setArticlePagination },
  } = useExpensiveReport();
  return (
    <TopBarContainer>
      <Col span={24} className="flex justify-between  items-center ">
        <div className="flex gap-[12px] items-center cursor-pointer relative ">
          <span className="material-icons text-[#001377] text-[32px]">
            filter_list
          </span>
          <div className="text-[#001377] text-[24px] font-[700] ">
            {reportType === undefined && "Set filter for report"}
            {reportType === INVOICE_REPORT_TYPES.Article &&
              "All request (zip file)"}
          </div>
        </div>
        {reportType !== undefined && (
          <div
            className="text-[#001377] text-[20px] font-[700] flex items-center gap-[12px] cursor-pointer select-none "
            onClick={() => {
              setReportType(undefined);
              setArticlePagination(defaultPagination);
            }}
          >
            <span
              className="material-icons"
              style={{ transform: "rotate(90deg)" }}
            >
              u_turn_left
            </span>
            Back
          </div>
        )}
      </Col>
      <Divider />
      {/* <Col span={24}>
        <div>
          <Tabs items={items} onChange={onChange} activeKey={activeKey} />
        </div>
      </Col> */}
    </TopBarContainer>
  );
};
