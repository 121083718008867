import { IDataModel } from "src/services/models";
import { IObject } from "src/models/interfaces";

export interface IReportAllRequest {
  allRequestId: number;
  created: string;
  zipCode: string;
  shipId: number;
  shipName: string;
  requesterId: number;
  requesterName: string;
  attachmentCount: number;
  duDate: string;
  requesterDate: string;
  paymentCurrencyId: number;
  paymentCurrencyName: string;
  comment: string;
}

export interface IReportAllRequestAttachment {
  attachmentId: number;
  created: string;
  shipName: string;
  attachmentCode: string;
  requesterFileName: string;
  invoiceCount: number;
  comment: string;
}
export interface IReportInvoice {
  invoiceId: number;
  created: number;
  invoiceNumber: string;
  invoiceType: number;
  invoiceStatus: number;
  requesterInvoiceNumber: string;
  supplierId: number;
  supplierName: string;
  totalAmount: number;
  difReqTotalAndOurAmount: number;
  difSUPTotalAndOurAmount: number;
  invoiceCurrencyId: number;
  invoiceCurrencyName: string;
  ourDate: number;
  comment: string;
}

export interface IReportInvoiceArticle {
  invoiceArticleId: number;
  created: string;
  invoiceNumber: string;
  quantity: number;
  unitQuantity: string;
  amount: number;
  category: string;
  categoryCode: string;
  comment: string;
}
export enum InvoiceStatus {
  Open = 1,
  Close = 2,
}
export interface IDailyAverageReport {
  total: number;
  average: number;
  fromDate: string;
}

export interface IReports {
  shipId: number;
  requesterId: number;
  paymentCurrencyId: number;
  voyageId: number;
  invoiceNO: string;
  supplierId: number;
  invoiceCurrencyId: number;
  invoiceDate: string;
  categoryId: number;
  zipCode: string;
  shipName: string;
  requester: string;
  supplierName: string;
  dueDate: string;
  paymanetCurrency: string;
  attachnNO: string;
  attachDate: string;
  voyageNO: string;
  kindOnInvoice: string;
  requesterInvoiceDate: string;
  requesterInvoiceNO: string;
  supplierInvoiceDate: string;
  supplierInvoiceNO: string;
  ourDate: string;
  description: string;
  quantity: number;
  unitQuantity: string;
  unitPrice: number;
  amount: number;
  invoiceCurrency: string;
  amountUSD: number;
  category: string;
  costCode: string;
  supplierTotalAmount: number;
  discount: number;
  requesterTotalAmount: number;
  tax: number;
  usdRate: number;
  totalInvAmountReqPayCurrency: number;
  supervisorRate: number;
}
export interface IReportsResult {
  totalAmount: number;
  reports: IDataModel<IReports[]>;
}
export interface ITurnoverReportResult extends IObject {
  totalExpenses: number;
  totalIncome: number;
  balance: number;
}
