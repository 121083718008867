import { Button, Col, Flex, Form } from "antd";
import { FC } from "react";
import { InvoiceArticleContainer } from "../../styles";
import { CollapseHandler } from "../Collapse";
import { KindInvoice } from "../kindInvoice";
import { AllRequest } from "../Allrequest";
import { Articles } from "../Articles";
import { useExpensiveReport } from "../../context";

export const InvoiceFilters: FC = () => {
  const {
    func: { setFilter },
    value: { invoiceReportForm, loading },
  } = useExpensiveReport();

  return (
    <Form form={invoiceReportForm} onFinish={setFilter}>
      <InvoiceArticleContainer>
        <Col span={24}>
          <Flex align="center" gap={12}>
            <Button className="p-0 w-[40px] h-[40px]">
              <span className="material-symbols-outlined text-[#4B4B4B] text-[24px]">
                filter_alt
              </span>
            </Button>
          </Flex>
        </Col>
        <Col span={24}>
          <CollapseHandler
            key="All request (zip file) & Attachment "
            icon="folder_zip"
            title="All request (zip file) & Attachment "
          >
            <AllRequest />
          </CollapseHandler>

          <CollapseHandler
            key="Invoice"
            icon="production_quantity_limits"
            title="Invoice"
          >
            <KindInvoice />
          </CollapseHandler>

          <CollapseHandler key="Article" icon="calendar_month" title="Article">
            <Articles />
          </CollapseHandler>
        </Col>
        <Col span={24} className="footer">
          <Button
            onClick={() => invoiceReportForm?.resetFields()}
            className="text-[#93C8FF] bg-[#fff] round-[#93C8FF] border-[#93C8FF]"
          >
            Erase Filter
          </Button>
          <Button
            type="primary"
            onClick={invoiceReportForm?.submit}
            loading={loading}
          >
            Set Filter
          </Button>
        </Col>
      </InvoiceArticleContainer>
    </Form>
  );
};
