import { FC } from "react";
import { CategoriesChildContainer } from "../style";
import { Col } from "antd";
import { CategoriesChildItems } from "./CategoriesChildItems";
import { ITreeCategory } from "src/services/System/models";

type ICategoriesChild = {
  children: ITreeCategory[];
  onClick?: (child: ITreeCategory) => void;
  selectedParentId?: number;
  selectedChildren?: ITreeCategory[];
};
export const CategoriesChild: FC<ICategoriesChild> = ({
  children,
  onClick,
  selectedParentId,
  selectedChildren,
}) => {
  return (
    <CategoriesChildContainer>
      {children.map((item, index) => (
        <Col span={24} key={index}>
          <CategoriesChildItems
            {...item}
            title={item.name}
            itemsCount={item.childeren.length}
            onClick={() => onClick && onClick(item)}
            isSelected={
              selectedChildren
                ? !!selectedChildren.find((child) => child.id === item.id)
                : selectedParentId === item.id
            }
          />
        </Col>
      ))}
    </CategoriesChildContainer>
  );
};
