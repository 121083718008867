import styled from "styled-components";
import { Layout } from "antd";
const { Sider } = Layout;

export const SidebarContainer = styled(Sider)`
  transition: all 0.3s;
  z-index: 100;
  left: 0;
  background-color: #fff;
  .ant-layout-sider-children {
    overflow-y: auto;
  }
  .ant-menu-submenu-arrow {
    color: #a3aed0;
  }
  .sidebar {
    width: 100%;
  }
  .sideBarLogoWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }
  .ant-menu-sub {
    background: #fff !important;
  }
  .menu {
    li {
      margin-top: 20px;
    }
    .ant-menu-item-only-child {
      margin-top: unset;
    }
    min-height: calc(100vh - 76px - 40px - 45px - 50px);
  }
  .ant-menu-title-content {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.32px;
    /* color: #a3aed0; */
  }
  .ant-menu-item:not(.ant-menu-item-selected) {
    .ant-menu-title-content {
      color: #a3aed0;
    }
  }
  .ant-menu-submenu-title {
    color: #a3aed0;
  }
  .ant-menu-item-selected {
    /* background-color: #2c73fc; */
    .ant-menu-title-content {
      /* color: #fff; */
    }
    .ant-menu-item-icon {
      color: #fff !important;
    }
  }
  .ant-menu-root {
    border: unset !important;
  }
  .ant-menu .ant-menu-item-selected {
    background-color: #2c73fc !important;
    color: #fff;
  }
  .ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: #2c73fc !important;

    .ant-menu-item-icon {
      color: #2c73fc !important;
    }
    .ant-menu-submenu-arrow {
      color: #2c73fc;
    }
    /* background-color: #2c73fc !important; */
  }
  .ant-menu-light > .ant-menu.ant-menu-item-selected {
    background-color: #e6f4ff;
  }

  .ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
    background-color: #e6f4ff !important;
    color: #2c73fc;
    .ant-menu-submenu-arrow {
      color: #2c73fc;
    }
  }
  /* .ant-menu-light > .ant-menu.ant-menu-item-selected {
    background-color: #2c73fc;
  } */
  .ant-menu-submenu-selected > .ant-menu-submenu-title[aria-expanded="true"] {
    background-color: #e6f4ff !important;
  }
  /* .ant-menu-item .ant-menu-item-only-child {
    background-color: #2c73fc !important;
  } */
  .ant-menu-submenu-selected {
    .ant-menu-item-selected {
      background-color: #2c73fc !important;
      color: #e6f4ff !important;
    }
  }
  .ant-menu-sub li {
    padding-left: 40px !important;
    font-size: 14px !important;
    font-weight: 500;
  }
`;
