import { IPath } from "../Identity/models";

export const InvoiceReportGuardPath: IPath[] = [
  {
    path: "api/InvoiceReport/ReportAllRequest",
    httpMethod: "POST",
  },
  {
    path: "api/InvoiceReport/ReportAllRequestAttachment",
    httpMethod: "POST",
  },
  {
    path: "api/InvoiceReport/ReportInvoice",
    httpMethod: "POST",
  },
  {
    path: "api/InvoiceReport/ReportInvoiceArticle",
    httpMethod: "POST",
  },
  {
    path: "api/InvoiceReport/Report",
    httpMethod: "POST",
  },
  {
    path: "api/InvoiceReport/DailyAverageReport",
    httpMethod: "GET",
  },
  {
    path: "api/InvoiceReport/TurnoverReport",
    httpMethod: "GET",
  },
];

export const ReportAllRequestPath: IPath = {
  path: "api/InvoiceReport/ReportAllRequest",
  httpMethod: "POST",
};
export const ReportAllRequestAttachmentPath: IPath = {
  path: "api/InvoiceReport/ReportAllRequestAttachment",
  httpMethod: "POST",
};
export const ReportInvoicePath: IPath = {
  path: "api/InvoiceReport/ReportInvoice",
  httpMethod: "POST",
};
export const ReportInvoiceArticlePath: IPath = {
  path: "api/InvoiceReport/ReportInvoiceArticle",
  httpMethod: "POST",
};

export const ReportPath: IPath = {
  path: "api/InvoiceReport/Report",
  httpMethod: "POST",
};
export const DailyAverageReportPath: IPath = {
  path: "api/InvoiceReport/DailyAverageReport",
  httpMethod: "POST",
};
export const TurnoverReportPath: IPath = {
  path: "api/InvoiceReport/TurnoverReport",
  httpMethod: "POST",
};
