import { FC } from "react";
import { IncomeReport } from "src/modules/IncomeReport";
import { IncomeReportProvider } from "src/modules/IncomeReport/context";

export const IncomeReportPage: FC = () => {
  return (
    <IncomeReportProvider>
      <IncomeReport />
    </IncomeReportProvider>
  );
};
