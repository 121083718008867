import { FC, useCallback, useMemo, useRef } from "react";
import { IncomeTableContainer } from "../style";
import { Col, TableColumnsType } from "antd";
import { TableUI } from "src/components/UiKit/table";
import _, { uniqueId } from "lodash";
import { useIncomeReport } from "../context";
import { IIncomeReport } from "src/services/Income/models";
import { IComponent } from "../models";
import dayjs from "dayjs";
import { FormLabels } from "../constants/formLabels";
import { generateIncomeExcel } from "./createExcel";
import ReactToPrint from "react-to-print";

export const IncomeTable: FC = () => {
  const {
    value: { tableDataSource, filters, pagination },
    dispatch: { setCompHandler, setFilters, setPagination },
  } = useIncomeReport()!;
  const componentRef = useRef<HTMLDivElement>(null);
  const data: IIncomeReport[] | undefined =
    tableDataSource?.records.records && tableDataSource?.records.records;
  const title = "Income Report";

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef.current]);

  const columns: TableColumnsType<IIncomeReport> = [
    {
      title: "#",
      key: "#",
      render: (text, record, index) =>
        (pagination.current - 1) * pagination.pageSize + (index + 1),
      width: 55,
      fixed: "left",
    },
    {
      title: "Vessel",
      dataIndex: "vesselName",
      key: "vesselName",
      width: 122,
      fixed: "left",
      className: "!text-center whitespace-nowrap",
      render: (text) => (text ? text : "---"),
    },
    {
      title: "Voyage No.",
      dataIndex: "voyageNo",
      key: "voyageNo",
      width: 122,
      className: "!text-center whitespace-nowrap",
      render: (text) => (text ? text : "---"),
    },
    {
      title: "Ref code",
      dataIndex: "refCode",
      key: "refCode",
      width: 122,
      className: "!text-center whitespace-nowrap",
      render: (text) => (text ? text : "---"),
    },
    {
      title: "Category",
      dataIndex: "categoryname",
      key: "categoryname",
      width: 122,
      className: "!text-center whitespace-nowrap",
      render: (text) => (text ? text : "---"),
    },
    {
      title: "Receipt No.",
      dataIndex: "receiptNo",
      key: "receiptNo",
      width: 122,
      className: "!text-center whitespace-nowrap",
      render: (text) => (text ? text : "---"),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 122,
      className: "!text-center whitespace-nowrap",
      render: (text, record) => (record.amount ? record.amount : "---"),
    },
    {
      title: "currency",
      dataIndex: "currencyname",
      key: "currencyname",
      width: 122,
      className: "!text-center whitespace-nowrap",
      render: (text) => (text ? text : "---"),
    },
    {
      title: "Amount(USD)",
      dataIndex: "amountUsd",
      key: "amountUsd",
      width: 109,
      className: "!text-center  whitespace-nowrap",
      render: (text, record) => (record.amountUsd ? record.amountUsd : "---"),
    },
    {
      title: "Payer",
      dataIndex: "payerName",
      key: "payerName",
      width: 122,
      className: "!text-center  whitespace-nowrap",
      render: (text) => (text ? text : "---"),
    },
    {
      title: "Receiver",
      dataIndex: "receiverName",
      key: "receiverName",
      width: 122,
      className: "!text-center whitespace-nowrap",
      render: (text) => (text ? text : "---"),
    },
    {
      title: "Receipt Date",
      dataIndex: "receiptDate",
      key: "receiptDate",
      width: 169,
      className: "!text-center whitespace-nowrap",
      render: (text) => (text ? dayjs(text).format("YYYY.MM.DD") : "---"),
    },
    {
      title: "Transaction date",
      dataIndex: "transactionDate",
      key: "transactionDate",
      width: 169,
      className: "!text-center whitespace-nowrap",
      render: (text) => (text ? dayjs(text).format("YYYY.MM.DD") : "---"),
    },
  ];
  const filtersLabel = useMemo(() => {
    if (filters) {
      const currentLabels: { label: string; value: string }[] = [];
      Object.keys(filters).forEach((item) => {
        if (filters[item]) {
          currentLabels.push({ label: FormLabels[item], value: item });
        }
      });
      const labels = _.uniqBy(currentLabels, (i) => {
        return i.label;
      });
      return labels;
    } else return [];
  }, [filters]);

  return (
    <IncomeTableContainer>
      <Col xs={16} md={16}>
        <div className="flex gap-[40px]">
          <div className="leftBoxHolder">
            <div
              className="bg-[#2C73FC] rounded-[8px] !w-[40px] !h-[40px] flex justify-center items-center cursor-pointer"
              onClick={() => {
                setCompHandler(IComponent.form);
              }}
            >
              <span className="material-symbols-outlined text-[#FFFFFF] !w-[40px] !h-[40px] text-[24px] flex justify-center items-center">
                tune
              </span>
            </div>
            {filtersLabel.map((item, index) => {
              return (
                <div className="filterHolder" key={index}>
                  <div className="text-[#A3AED0]">{item.label}</div>
                  <span
                    className="material-symbols-outlined text-[#A3AED0] text-[14px] cursor-pointer"
                    onClick={() => {
                      setFilters((prev) => {
                        let newValue = undefined;
                        if (prev) newValue = { ...prev };
                        if (newValue) newValue[item.value] = undefined;
                        return newValue;
                      });
                    }}
                  >
                    close
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </Col>
      <Col xs={8} md={7}>
        <div className="rightBoxHolder flex flex-col gap-[6px]">
          <div className="flex justify-between w-full">
            <div className="flex gap-[4px] justify-center items-center">
              <div className="text-[#A3AED0] text-[14px] ">Total</div>
              <div className="text-[#A3AED0] text-[10px] pt-[3px]">(USD)</div>
            </div>
            <img alt="dollar" src="/assets/images/dollar.svg"></img>
          </div>
          <div className="text-[#1B2559]">
            {tableDataSource?.totalAmountUsd
              ? tableDataSource?.totalAmountUsd?.toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })
              : "0"}
          </div>
        </div>
      </Col>
      <Col xs={24} md={24}>
        <ReactToPrint content={reactToPrintContent} removeAfterPrint />

        <div ref={componentRef}>
          <TableUI
            columns={columns}
            dataSource={tableDataSource?.records.records}
            // loading={tableLoading}
            pagination={{
              position: ["bottomCenter"],
              onChange: (page, pageSize) => {
                setPagination((prev) => ({
                  ...prev,
                  Offset: pageSize !== prev.current ? 1 : page,
                  Limit: pageSize,
                }));
              },
            }}
            scroll={{ x: 1140 }}
            rowKey={() => uniqueId()}
            containerStyle={{
              padding: 8,
            }}
            rowClassName={"rowClass"}
            className="p-[8px]"
          />
          {tableDataSource?.records?.count &&
          tableDataSource?.records?.count > 0 ? (
            <img
              src="/assets/images/excelIcon.svg"
              alt="img"
              className="cursor-pointer absolute bottom-[10px] left-[10px] w-[40px] h-[40px] "
              onClick={() => generateIncomeExcel({ data, columns, title })}
            />
          ) : (
            ""
          )}
        </div>
      </Col>
    </IncomeTableContainer>
  );
};
