import { useCheckPermission } from "src/hook/checkRoutePermission";
import { ROUTES } from "src/models/enums";
import {
  AgentPath,
  CommercialPath,
  CountryApiPath,
  CurrencyApiPath,
  FrontAccessPath,
  PayerApiPath,
  PortPagePath,
  ProfitPath,
  ReceiverApiPath,
  RequesterGuardPath,
  ShipManagementPath,
  SupplierPath,
  categoryApiPath,
  tableApiPath,
} from "src/components/Guard/permissions";
import { DailyReportGuardPath } from "src/services/DailyReport/guardPath";
import {
  DailyAverageReportPath,
  InvoiceReportGuardPath,
  ReportPath,
  TurnoverReportPath,
} from "src/services/InvoiceReport/guardPath";
import { EditRequesterPath, ShipGuardPath } from "src/services/Ship/guardPath";
import {
  AssignRoleToActionPath,
  EditSupplierPath,
  GetSupplierPath,
  NewRolePath,
} from "src/services/System/guardPath";
import {
  AddUserRolePath,
  RolesPath,
  newUserPath,
} from "src/services/UserManagement/guardPath";
import { WorkFlowGuardPath } from "src/services/WorkFlow/guardPath";

import { create } from "zustand";
import { checkActionPermission } from "src/helpers/checkPermissionFunc";
import {
  AllIncomesPath,
  AllVoyageIncomesPath,
  IncomeReportPath,
  NewIncomePath,
} from "src/services/Income/guardPath";

type PageTitleKeys = keyof typeof ROUTES;
export type PageTitleKeysType = (typeof ROUTES)[PageTitleKeys];
export type IRoutesAccessed = Record<PageTitleKeys, boolean>;
interface IStore {
  routes: IRoutesAccessed;
  setRoutes: (routes: Partial<Record<PageTitleKeys, boolean>>) => void;
}

const useRoutesAccessStore = () => {
  const { checkRoutePermission } = useCheckPermission();

  const store = create<IStore>((set) => ({
    routes: {
      home: true,
      ships: true,
      invoices: checkRoutePermission(InvoiceReportGuardPath),
      table: checkRoutePermission(tableApiPath),
      security: true,
      settings: true,
      Report: checkRoutePermission(InvoiceReportGuardPath),
      ShipDailyReport: checkRoutePermission(DailyReportGuardPath),
      voyage: true,
      cargos: true,
      actions: true,
      futurePlan: true,
      instruction: true,
      CreateRole: checkRoutePermission([NewRolePath]),
      AssignActionToRole: checkRoutePermission([
        RolesPath,
        AssignRoleToActionPath,
      ]),
      CreateUser: checkRoutePermission([newUserPath]),
      AssignRoleToUser: checkRoutePermission([AddUserRolePath]),
      Category: checkRoutePermission(categoryApiPath),
      Flow: false && checkRoutePermission(WorkFlowGuardPath),
      AddShips: checkRoutePermission(ShipGuardPath),
      ShipManagement: checkRoutePermission(ShipManagementPath),
      Supplier: checkRoutePermission(SupplierPath),
      SupplierView: checkRoutePermission([GetSupplierPath]),
      SupplierEdit: checkRoutePermission([EditSupplierPath]),
      SupplierEditPage: checkRoutePermission([EditSupplierPath]),
      SupplierViewPage: checkRoutePermission([GetSupplierPath]),
      Requester: checkRoutePermission(RequesterGuardPath),
      EditRequester: checkRoutePermission([EditRequesterPath]),
      EditRequesterPage: checkRoutePermission([EditRequesterPath]),
      FrontAccess: false && checkRoutePermission(FrontAccessPath),
      Documentation: false,
      ShipDailyReportOwner: true,
      ShipDailyReportShips: true,
      ShipDailyReportCalendar: true,
      ShipDailyReports: true,
      ShipDailyReportDetail: true,
      login: true,
      NoonReport: false,
      Port: false && checkRoutePermission(PortPagePath),
      Agent: false && checkRoutePermission(AgentPath),
      AgentEdit: false && checkRoutePermission(AgentPath),
      AgentView: false && checkRoutePermission(AgentPath),
      ProfitReport: checkRoutePermission(ProfitPath),
      Commercial: checkRoutePermission(CommercialPath),
      FinancialIncome: checkRoutePermission([
        AllVoyageIncomesPath,
        AllIncomesPath,
        NewIncomePath,
      ]),
      BaseInfo: checkRoutePermission([
        ...CountryApiPath,
        ...CurrencyApiPath,
        ...ReceiverApiPath,
        ...PayerApiPath,
      ]),
      CargoGroup: false,
      CargoType: false,
      Country: checkRoutePermission(CountryApiPath),
      Currency: checkRoutePermission(CurrencyApiPath),
      Charterer: false,
      Receiver: checkRoutePermission(ReceiverApiPath),
      Payer: checkRoutePermission(PayerApiPath),
      incomeReport: checkActionPermission(IncomeReportPath),
      dailyAverageReport: checkActionPermission(DailyAverageReportPath),
      ExpensiveReport: checkActionPermission(ReportPath),
      TurnoverReport: checkActionPermission(TurnoverReportPath),
    },
    setRoutes: (newRoutes) =>
      set((state) => ({ routes: { ...state.routes, ...newRoutes } })),
  }));

  return store.getState();
};

export default useRoutesAccessStore;
