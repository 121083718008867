import { FrontPathType } from "src/modules/frontAccess/models";

export interface INewCategory {
  name: string;
  code: string;
  parentId?: number;
  description?: string;
  type?: CategoryType;
}
export interface ICategoryParams {
  Name?: string;
  Code?: string;
}
export interface ISubCategoryParams extends ICategoryParams {}
export interface INewRoleArg {
  name: string;
  comment: string;
  parentId: number;
}

export interface IAllAction {
  id: number;
  name: string;
  displayName: string;
  urlPath: string;
  created: string;
}
export interface IRolesOfAction {
  id: number;
  name: string;
}
export interface IAssignRoleToActionArgs {
  rolesId: number[];
  actionId: number;
}
export interface IEditCategory {
  name: string;
  description: string;
}

export interface INewFrontAccess {
  name: string;
  displayName: string;
  pathType: FrontPathType;
}

export interface IGetFrontPathParams {
  id?: number;
  name?: string;
  pathType?: number;
  Offset?: number;
  Limit?: number;
}

export interface IAddRoleToFrontPath {
  frontPathId: number;
  roleId: number;
}
export interface IGetCountry {
  id: number;
  name: string;
  abbrivation: string;
}
export interface INewCountry {
  name: string;
  abbrivation: string;
}
export interface INewCurrency {
  name: string;
  shortName: string;
}
export interface INewSupplier {
  name: string;
  webSite: string;
  phoneNumber: string;
  email: string;
  countryId: number;
  city: string;
  address: string;
  comment: string;
}
export interface IGetSupplier {
  id: number;
  created: string;
  name: string;
  webSite: string;
  phoneNumber: string;
  email: string;
  countryId: number;
  countryName: string;
  city: string;
  address: string;
  comment: string;
}

export enum CategoryTypeEnum {
  Cost = 1,
  Income = 2,
}
export type CategoryType = 1 | 2;
