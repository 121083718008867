import { FC, useEffect, useState } from "react";
import {
  App,
  Button,
  Col,
  DatePicker,
  Flex,
  Form,
  Row,
  Space,
  TreeSelect,
} from "antd";
import { SelectUiKit } from "src/components/UiKit/select";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";

import { Dayjs } from "dayjs";
import { useFinancial } from "../../context";

import { ITreeCategory } from "src/services/System/models";
import { IFinancialTabs, ITreeValue } from "../../models";
import { SDatePicker } from "src/components/UiKit/DatePicker";
import usePublicApiStore from "src/store/publicApi";
import { TextAreaUiKit } from "src/components/UiKit/TextArea";
import { SInputNumber } from "src/components/UiKit/InputNumber";
import { omit } from "lodash";
import { IncomeService } from "src/services/Income/Income.service";
import { INewIncome } from "src/services/Income/models";
import { Link } from "react-router-dom";
import { Guard } from "src/components/Guard";
import { NewIncomePath, UpdateIncomePath } from "src/services/Income/guardPath";
const { RangePicker } = DatePicker;
export interface IAddIncomeFormValue {
  refCode: string;
  categoryId: number;
  receiptNo: string;
  receiptDate?: Dayjs | string;
  transactionDate?: Dayjs | string;
  periodDate?: [Dayjs | string, Dayjs | string];
  rate: number;
  payerId: number;
  receiverId: number;
  description: string;
  currencyId: number;
  amount: number;
  AmountUSD?: string;
}

export const AddIncomeForm: FC = () => {
  const { allCurrency } = usePublicApiStore();

  const [submittable, setSubmittable] = useState<boolean>(false);
  const {
    value: {
      form,
      currentEditVoyage,
      categories,
      payers,
      receivers,
      loadings,
      editMode,
      currentEditIncome,
    },
    func: { getAllIncome },
    dispatch: {
      setActiveKey,
      setCurrentVoyage,
      setCurrentEditIncome,
      setEditMode,
    },
  } = useFinancial();
  const [addLoading, setAddLoading] = useState<boolean>(false);
  const values = Form.useWatch([], form);
  const { message } = App.useApp();
  useEffect(() => {
    form?.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values, form]);
  const onFinish = async (values: IAddIncomeFormValue) => {
    if (!currentEditVoyage) return;
    try {
      setAddLoading(true);
      let reqBody: INewIncome = {
        ...omit(values, [
          "periodDate",
          "receiptDate",
          "transactionDate",
          "AmountUSD",
        ]),
        voyageId: currentEditVoyage?.voyageId,
      };
      if (values.receiptDate) {
        reqBody = {
          ...reqBody,
          receiptDate: (values.receiptDate as Dayjs).format("YYYY-MM-DD"),
        };
      }
      if (values.transactionDate) {
        reqBody = {
          ...reqBody,
          transactionDate: (values.transactionDate as Dayjs).format(
            "YYYY-MM-DD"
          ),
        };
      }
      if (values.periodDate && values.periodDate[0] && values.periodDate[1]) {
        reqBody = {
          ...reqBody,
          periodStartDate: (values.periodDate[0] as Dayjs).format("YYYY-MM-DD"),
          periodEndDate: (values.periodDate[1] as Dayjs).format("YYYY-MM-DD"),
        };
      }
      const { NewIncome, UpdateIncome } = new IncomeService();
      let res;
      if (editMode && currentEditIncome) {
        res = await UpdateIncome(currentEditIncome.id, reqBody);
      } else {
        res = await NewIncome(reqBody);
      }
      if (res?.status === 200) {
        message.success(
          `Successfully ${editMode ? "updated" : "created a new"}  income.`
        );
        if (editMode) setEditMode(false);
        getAllIncome();
        form?.resetFields();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAddLoading(false);
    }
  };
  const createTreeCategories = (category: ITreeCategory) => {
    const newCat: ITreeValue = {
      value: category.id,
      title: category.name,
      children: [],
    };
    if (category.childeren.length > 0) {
      const child = category.childeren.map((child) =>
        createTreeCategories(child)
      );
      newCat.children = child;
    }
    return newCat;
  };

  useEffect(() => {
    if (values?.rate && values?.amount) {
      const usdAmount = +values.rate * +values.amount;
      form?.setFieldValue("AmountUSD", usdAmount);
    } else {
      form?.setFieldValue("AmountUSD", undefined);
    }
  }, [values?.rate, values?.amount, form]);
  console.log({ categories });
  return (
    <Row>
      <Col span={24}>
        <h3 className="title">
          Receipt {currentEditVoyage?.vesselName} {currentEditVoyage?.voyageNo}
        </h3>
        <h3 className="subtitle">Please insert the voyage information.</h3>
      </Col>
      <Link to={""}></Link>
      <Col span={24} className="py-[16px]">
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={(err) => {
            console.log(err);
          }}
        >
          <Row gutter={[24, 0]}>
            <Col span={8}>
              <div className="relative">
                <FormItem
                  rules={[{ required: true }]}
                  name={"refCode"}
                  label="Ref Code"
                  className="mb-0"
                  extra={
                    <div className="uniqueField">
                      This field must be unique.
                    </div>
                  }
                >
                  <SInput allowClear />
                </FormItem>
              </div>
            </Col>
            <Col span={8}>
              <FormItem
                rules={[{ required: true }]}
                label="Category"
                name={"categoryId"}
              >
                <TreeSelect
                  treeData={categories.map((category) =>
                    createTreeCategories(category)
                  )}
                  placeholder="Select"
                  showSearch
                  allowClear
                  className="selectOption"
                  loading={loadings.categories}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="Receipt No." name={"receiptNo"}>
                <SInput allowClear />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="Receipt Date" name={"receiptDate"}>
                <SDatePicker
                  allowClear
                  className="w-full"
                  placeholder="Select"
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="Transaction Date" name={"transactionDate"}>
                <SDatePicker
                  allowClear
                  className="w-full"
                  placeholder="Select"
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="Period date" name={"periodDate"}>
                <RangePicker allowClear className="datePicker" />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Amount"
                required
                name={"aa"}
                // rules={[{ required: true, message: "Amount is required." }]}
              >
                <Space.Compact className="w-full">
                  <FormItem
                    name={"currencyId"}
                    className="w-[40%] mb-0"
                    rules={[{ required: true }]}
                  >
                    <SelectUiKit
                      options={allCurrency?.data?.map((currency) => ({
                        label: currency.name,
                        name: currency.shortName,
                        value: currency.id,
                      }))}
                      showSearch
                      allowClear
                      optionFilterProp="label"
                      optionLabelProp="name"
                      className="currency "
                      placeholder="Currency"
                    />
                  </FormItem>
                  <FormItem
                    name={"amount"}
                    className="w-[60%] mb-0"
                    rules={[{ required: true }]}
                  >
                    <SInputNumber />
                  </FormItem>
                </Space.Compact>
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Rate (To USD)"
                name={"rate"}
                rules={[{ required: true }]}
              >
                <SInputNumber />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="Amount (USD)" name={"AmountUSD"}>
                <SInputNumber disabled placeholder="System" />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                rules={[{ required: true }]}
                name={"payerId"}
                label="Payer"
              >
                <SelectUiKit
                  options={payers.map((i) => ({ label: i.name, value: i.id }))}
                  showSearch
                  allowClear
                  optionFilterProp="label"
                  loading={loadings.payers}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                rules={[{ required: true }]}
                name={"receiverId"}
                label="Receiver"
              >
                <SelectUiKit
                  options={receivers.map((i) => ({
                    label: i.name,
                    value: i.id,
                  }))}
                  showSearch
                  allowClear
                  optionFilterProp="label"
                  loading={loadings.receivers}
                />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                name={"description"}
                label="Description"
                className="!w-full"
              >
                <TextAreaUiKit
                  allowClear
                  autoSize={{ maxRows: 4, minRows: 4 }}
                  placeholder="Description"
                  className="!w-full"
                />
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Col>
      <Col span={24} className="">
        <Flex gap={16} justify="flex-end">
          <Button
            className="min-w-[85px] !h-[40px]"
            onClick={() => {
              form?.resetFields();
              setCurrentEditIncome(undefined);
              setEditMode(false);
            }}
          >
            {editMode ? "Cancel" : "Reset"}
          </Button>
          <Guard action={[NewIncomePath, UpdateIncomePath]}>
            <Button
              type="primary"
              className="min-w-[85px] !h-[40px]"
              disabled={!submittable}
              onClick={form?.submit}
              loading={addLoading}
            >
              {editMode ? "Save edit" : "Add"}
            </Button>
          </Guard>
          {!editMode && (
            <Button
              type="primary"
              className="min-w-[85px] !h-[38px]"
              onClick={() => {
                setActiveKey(IFinancialTabs.voyageTable);
                form?.resetFields();
                setCurrentVoyage(undefined);
              }}
              ghost
              // loading={loading}
            >
              {"Finish"}
            </Button>
          )}
        </Flex>
      </Col>
    </Row>
  );
};
