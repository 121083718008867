import { FC, useEffect, useState } from "react";
import { TabsContainer } from "./style";
import { useCheckPermission } from "src/hook/checkRoutePermission";
import { Tabs, TabsProps } from "antd";
import { IFinancialTabs } from "./models";
import { VoyageTable } from "./components/VoyageTable";
import { useFinancial } from "./context";
import { HeaderEditShip } from "./components/HeaderEdit";
import { AddIncome } from "./components/AddNewIncome";
import {
  AllVoyageIncomesPath,
  NewIncomePath,
} from "src/services/Income/guardPath";

export const Financial: FC = () => {
  const [items, setItems] = useState<TabsProps["items"]>([]);
  const {
    value: { activeKey, mode, editMode, form },
    dispatch: {
      setActiveKey,
      setEditMode,
      setCurrentEditIncome,
      setCurrentVoyage,
    },
  } = useFinancial();
  const { checkActionPermission } = useCheckPermission();
  const onChange = (key: string) => {
    setActiveKey(key);
    setCurrentVoyage(undefined);
    if (editMode) {
      form?.resetFields();
      setEditMode(false);
      setCurrentEditIncome(undefined);
    }
  };
  useEffect(() => {
    const NewVoyagePathHasPermission = checkActionPermission(NewIncomePath);
    const AllVoyagePathHasPermission =
      checkActionPermission(AllVoyageIncomesPath);

    if (AllVoyagePathHasPermission) {
      setItems((prev) => {
        const newTab = {
          key: IFinancialTabs.voyageTable,
          label: (
            <span>
              <span className="material-icons text-[10px] p-[10px]">lens</span>
              Voyage Income Table
            </span>
          ),
          children: <VoyageTable />,
        };
        return prev ? [newTab, ...prev] : [newTab];
      });
      setActiveKey(IFinancialTabs.voyageTable);
    }
    if (NewVoyagePathHasPermission) {
      setItems((prev) => {
        const newItem = {
          key: IFinancialTabs.addIncome,
          label: (
            <span>
              <span className="material-icons text-[10px] p-[8px]">lens</span>
              Add income
            </span>
          ),
          children: <AddIncome />,
          disabled: true,
        };
        return prev ? [...prev, newItem] : [newItem];
      });
      !AllVoyagePathHasPermission && setActiveKey(IFinancialTabs.addIncome);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TabsContainer>
      {mode === "doc" && <HeaderEditShip />}
      <Tabs
        items={items}
        onChange={onChange}
        activeKey={activeKey}
        animated
        tabBarStyle={{ display: mode === "doc" ? "none" : "initial" }}
      />
    </TabsContainer>
  );
};
