import { IDataModel } from "../models";
import {
  IAddRoleToFrontPath,
  IAllAction,
  IAssignRoleToActionArgs,
  ICategory,
  ICategoryParams,
  IEditCategory,
  IFrontPath,
  INewFrontAccess,
  IGetCountry,
  INewCategory,
  INewCountry,
  INewCurrency,
  INewRoleArg,
  IRolesOfAction,
  IRolesOfFrontPath,
  ISubCategory,
  ISubCategoryParams,
  ITreeCategory,
  INewSupplier,
  IGetSupplier,
  CategoryType,
} from "./models";
import { SystemUrls } from "./urls";
import axios from "axios";
import * as GuardPaths from "./guardPath";
import { checkActionPermission } from "src/helpers/checkPermissionFunc";
import { permissionLog } from "src/helpers/permissionLog";
import { IObject } from "src/models/interfaces";
export class SystemService {
  async NewRole(data: INewRoleArg) {
    if (!checkActionPermission(GuardPaths.NewRolePath)) {
      permissionLog(GuardPaths.NewRolePath.path);
      return undefined;
    }
    try {
      const response = await axios.post<void>(SystemUrls.NewRole, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async NewCategory(data: INewCategory) {
    if (!checkActionPermission(GuardPaths.NewCategoryPath)) {
      permissionLog(GuardPaths.NewCategoryPath.path);
      return undefined;
    }
    try {
      const response = await axios.post<number>(SystemUrls.NewCategory, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AllActions() {
    if (!checkActionPermission(GuardPaths.AllActionsPath)) {
      permissionLog(GuardPaths.AllActionsPath.path);
      return undefined;
    }
    try {
      const response = await axios.get<IAllAction[]>(SystemUrls.AllActions);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AllCategory(data: ICategoryParams) {
    if (!checkActionPermission(GuardPaths.AllCategoryPath)) {
      permissionLog(GuardPaths.AllCategoryPath.path);
      return undefined;
    }
    const searchParams = new URLSearchParams();
    if (data.Code) searchParams.append("Code", data.Code);
    if (data.Name) searchParams.append("Name", data.Name);
    try {
      const response = await axios.get<ICategory[]>(
        SystemUrls.AllCategory.concat(`?${searchParams.toString()}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AllSubCategory(id: number, data: ISubCategoryParams) {
    const searchParams = new URLSearchParams();
    if (data.Code) searchParams.append("Code", data.Code);
    if (data.Name) searchParams.append("Name", data.Name);
    try {
      const response = await axios.get<ISubCategory[]>(
        SystemUrls.System.concat(id.toString())
          .concat(SystemUrls.AllSubCategory)
          .concat(`?${searchParams.toString()}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async RolesOfAction(id: number) {
    if (!checkActionPermission(GuardPaths.RolesOfActionPath)) {
      permissionLog(GuardPaths.RolesOfActionPath.path);
      return undefined;
    }
    try {
      const response = await axios.get<IRolesOfAction[]>(
        SystemUrls.System.concat(id.toString()).concat(SystemUrls.RolesOfAction)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AssignRoleToAction(data: IAssignRoleToActionArgs) {
    if (!checkActionPermission(GuardPaths.AssignRoleToActionPath)) {
      permissionLog(GuardPaths.AssignRoleToActionPath.path);
      return undefined;
    }
    try {
      const response = await axios.post<void>(
        SystemUrls.AssignRoleToAction,
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async TreeCategory(id?: number, categoryType?: CategoryType) {
    if (!checkActionPermission(GuardPaths.TreeCategoryPath)) {
      permissionLog(GuardPaths.TreeCategoryPath.path);
      return undefined;
    }
    const obj: IObject = {};
    if (id) {
      obj.CategoryId = id.toString();
    }
    if (categoryType) {
      obj.CategoryType = categoryType.toString();
    }

    const newQ = new URLSearchParams(obj);
    let endpoint: string = SystemUrls.TreeCategory;
    endpoint = endpoint.concat("?".concat(newQ.toString()));
    console.log({ endpoint, newQ });

    try {
      const response = await axios.get<ITreeCategory[]>(endpoint);
      return response;
    } catch (err) {
      return console.log(err);
    }
  }
  async DeleteCategory(id: number) {
    if (!checkActionPermission(GuardPaths.DeleteCategoryPath)) {
      permissionLog(GuardPaths.DeleteCategoryPath.path);
      return undefined;
    }
    try {
      const response = await axios.delete(
        SystemUrls.System.concat(`${id}${SystemUrls.DeleteCategory}`)
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async EditCategory(id: number, data: IEditCategory) {
    if (!checkActionPermission(GuardPaths.EditCategoryPath)) {
      permissionLog(GuardPaths.EditCategoryPath.path);
      return undefined;
    }
    try {
      const response = await axios.put(
        SystemUrls.System.concat(`${id}${SystemUrls.EditCategory}`),
        data
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async NewFrontPath(data: INewFrontAccess) {
    if (!checkActionPermission(GuardPaths.NewFrontPathGuardPath)) {
      permissionLog(GuardPaths.NewFrontPathGuardPath.path);
      return undefined;
    }
    try {
      const response = await axios.post<number>(SystemUrls.NewFrontPath, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async GetFrontPath(search: string) {
    if (!checkActionPermission(GuardPaths.GetFrontPathGuardPath)) {
      permissionLog(GuardPaths.GetFrontPathGuardPath.path);
      return undefined;
    }
    try {
      const response = await axios.get<IDataModel<IFrontPath[]>>(
        SystemUrls.GetFrontPath.concat(`?${search}`)
      );
      return response;
    } catch (err) {
      return console.log(err);
    }
  }

  async EditFrontPath(id: number, data: INewFrontAccess) {
    if (!checkActionPermission(GuardPaths.EditFrontPathGuardPath)) {
      permissionLog(GuardPaths.EditFrontPathGuardPath.path);
      return undefined;
    }
    try {
      const response = await axios.put(
        SystemUrls.System.concat(`${id}${SystemUrls.EditFrontPath}`),
        data
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async DeleteFrontPath(id: number) {
    if (!checkActionPermission(GuardPaths.DeleteFrontPathGuardPath)) {
      permissionLog(GuardPaths.DeleteFrontPathGuardPath.path);
      return undefined;
    }
    try {
      const response = await axios.delete(
        SystemUrls.System.concat(`${id}${SystemUrls.DeleteFrontPath}`)
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async GetRoleOfFrontPath(id: number) {
    if (!checkActionPermission(GuardPaths.GetRoleOfFrontPathGuardPath)) {
      permissionLog(GuardPaths.GetRoleOfFrontPathGuardPath.path);
      return undefined;
    }
    try {
      const response = await axios.get<IRolesOfFrontPath[]>(
        SystemUrls.System.concat(id.toString()).concat(
          SystemUrls.GetRoleOfFrontPath
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async AddRolToFrontPath(data: IAddRoleToFrontPath) {
    if (!checkActionPermission(GuardPaths.AddRolToFrontPathGuardPath)) {
      permissionLog(GuardPaths.AddRolToFrontPathGuardPath.path);
      return undefined;
    }
    try {
      const response = await axios.post<number>(
        SystemUrls.AddRolToFrontPath,
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async DeleteFrontPathAccess(id: number) {
    if (!checkActionPermission(GuardPaths.DeleteFrontPathAccessPath)) {
      permissionLog(GuardPaths.DeleteFrontPathAccessPath.path);
      return undefined;
    }
    try {
      const response = await axios.delete(
        SystemUrls.System.concat(`${id}${SystemUrls.DeleteFrontPathAccess}`)
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async GetCountry(id?: number) {
    if (!checkActionPermission(GuardPaths.GetCountryPath)) {
      permissionLog(GuardPaths.GetCountryPath.path);
      return undefined;
    }
    try {
      let endPoint: string = SystemUrls.GetCountry;
      if (id) {
        endPoint = endPoint.concat(`?${id}`);
      }
      const response = await axios.get<IGetCountry[]>(endPoint);
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async NewCountry(data: INewCountry) {
    if (!checkActionPermission(GuardPaths.NewCountryPath)) {
      permissionLog(GuardPaths.NewCountryPath.path);
      return undefined;
    }
    try {
      const response = await axios.post<void>(SystemUrls.NewCountry, data);
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async EditCountry(id: number, data: INewCountry) {
    if (!checkActionPermission(GuardPaths.EditCountryPath)) return undefined;
    try {
      const response = await axios.put(
        SystemUrls.System.concat(`${id}`).concat(SystemUrls.EditCountry),
        data
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async NewCurrency(data: INewCurrency) {
    if (!checkActionPermission(GuardPaths.NewCurrencyPath)) {
      permissionLog(GuardPaths.NewCurrencyPath.path);
      return undefined;
    }
    try {
      const response = await axios.post<void>(SystemUrls.NewCurrency, data);
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async EditCurrency(data: INewCurrency, id: number) {
    if (!checkActionPermission(GuardPaths.EditCurrencyPath)) return undefined;
    try {
      const endpoint = SystemUrls.System.concat(`${id}`).concat(
        SystemUrls.EditCurrency
      );

      const response = await axios.put(endpoint, data);
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async NewSupplier(data: INewSupplier) {
    if (!checkActionPermission(GuardPaths.NewSupplierPath)) {
      permissionLog(GuardPaths.NewSupplierPath.path);
      return undefined;
    }
    try {
      const response = await axios.post<void>(SystemUrls.NewSupplier, data);
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async EditSupplier(id: number, data: INewSupplier) {
    if (!checkActionPermission(GuardPaths.EditSupplierPath)) {
      permissionLog(GuardPaths.EditSupplierPath.path);
      return undefined;
    }
    try {
      const response = await axios.put(
        SystemUrls.System.concat(`${id}${SystemUrls.EditSupplier}`),
        data
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async DeletetSupplier(id: number) {
    if (!checkActionPermission(GuardPaths.DeletetSupplierPath)) {
      permissionLog(GuardPaths.DeletetSupplierPath.path);
      return undefined;
    }
    try {
      const response = await axios.delete(
        SystemUrls.System.concat(`${id}${SystemUrls.DeletetSupplier}`)
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async GetSupplier(queries?: string) {
    if (!checkActionPermission(GuardPaths.GetSupplierPath)) {
      permissionLog(GuardPaths.GetSupplierPath.path);
      return undefined;
    }
    let endpoint: string = SystemUrls.GetSupplier;
    if (queries) endpoint = endpoint.concat(`?${queries}`);
    try {
      const response = await axios.get<IDataModel<IGetSupplier[]>>(endpoint);
      return response;
    } catch (err) {
      console.log(err);
    }
  }
}
