import { App, Button, Col, Form, Radio, Row, TreeSelect } from "antd";
import { FC, useEffect, useMemo, useState } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import TextArea from "antd/es/input/TextArea";
import { TreeSelectProps } from "antd/lib";
import { EditCategoryContainer, EditHeaderContent } from "./style";
import { useCategory } from "../../context";
import { SystemService } from "src/services/System/System.service";
import {
  CategoryTypeEnum,
  IEditCategory,
  ITreeCategory,
} from "src/services/System/models";
import { DefaultOptionType } from "antd/es/select";

const { SHOW_PARENT } = TreeSelect;

export const EditCategory: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const {
    dispatch: { setIsEditMode, setLevels, setSelectedCategories },
    value: { categoryForEdit, category, categoryLoading },
    func: { getCategory },
  } = useCategory()!;

  const onFinish = async (value: IEditCategory) => {
    if (!categoryForEdit) return;
    try {
      setLoading(true);
      const { EditCategory } = new SystemService();
      const reqBody: IEditCategory = {
        name: value.name,
        description: value.description,
      };
      const res = await EditCategory(categoryForEdit.id, reqBody);
      if (res && res.status === 200) {
        message.success("Edit Category successfully updated");
        await getCategory().finally(() => {
          form.resetFields();
          setLevels([]);
          setSelectedCategories([]);
          setIsEditMode(false);
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const createTree = (category: ITreeCategory) => {
    const newCategory: DefaultOptionType = {
      label: category.name,
      value: category.id,
      key: category.id,
    };
    if (category.childeren.length > 0) {
      newCategory.children = category.childeren.map((item) => createTree(item));
    }
    return newCategory;
  };
  const data: DefaultOptionType[] = useMemo(
    () => category.map((item) => createTree(item)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [category]
  );
  const treeProps: TreeSelectProps<any, DefaultOptionType> = useMemo(
    () => ({
      treeData: data,
      treeCheckable: false,
      showCheckedStrategy: SHOW_PARENT,
      placeholder: "select",
      style: {
        width: "100%",
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const headerEditCategory = (
    <EditHeaderContent className="flex justify-between items-center py-[19px] px-[24px] borderBottom">
      <div
        className="flex flex-row gap-[12px] items-center cursor-pointer"
        onClick={() => {
          setIsEditMode(false);
        }}
      >
        <span className="material-icons text-[#2C73FC] text-[32px]">
          keyboard_backspace
        </span>
        <div className=" text-[#2C73FC] text-[24px] ">
          Back to daily reports table
        </div>
      </div>
      <div className="flex flex-col">
        <div className="text-[#001377] text-[18px]">Edit Category </div>
        <div className="text-[#5D6881] text-[14px]">Main Engine </div>
      </div>
    </EditHeaderContent>
  );
  const parentValue = Form.useWatch("parentId", form);
  useEffect(() => {
    if (!!parentValue) {
      form.setFieldValue("type", undefined);
    }
  }, [form, parentValue]);
  useEffect(() => {
    if (categoryForEdit) {
      form.setFieldsValue(categoryForEdit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryForEdit]);
  return (
    <Row justify={"center"}>
      <Col span={24}>{headerEditCategory}</Col>
      <Col span={18}>
        <Form form={form} onFinish={onFinish}>
          <EditCategoryContainer>
            <Row className="background" gutter={24}>
              <Col span={24}>
                <div className="pt-[16px] titleHolder">
                  Please edit category information.
                </div>
              </Col>
              <Col xs={24} md={12}>
                <FormItem
                  name="name"
                  label="Name"
                  rules={[{ required: true }]}
                  required={true}
                >
                  <SInput placeholder="insert" />
                </FormItem>
              </Col>
              <Col xs={24} md={12}>
                <FormItem
                  name="code"
                  label="Code"
                  rules={[{ required: true }]}
                  required={true}
                >
                  <SInput placeholder="insert" disabled />
                </FormItem>
              </Col>
              <Col xs={24} md={12}>
                <FormItem name="parentId" label="Parent">
                  <TreeSelect
                    {...treeProps}
                    allowClear
                    className="selectOption"
                    showSearch
                    treeNodeFilterProp="label"
                    loading={categoryLoading}
                    disabled
                  />
                </FormItem>
              </Col>
              <Col xs={24} md={12}>
                <FormItem
                  name="categoryType"
                  label="Type"
                  rules={[{ required: !parentValue }]}
                >
                  <Radio.Group className="flex items-center h-[46px]" disabled>
                    <Radio value={CategoryTypeEnum.Income}>Income</Radio>
                    <Radio value={CategoryTypeEnum.Cost}>Cost</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem name="description" label="Description">
                  <TextArea
                    placeholder="Type roll description"
                    autoSize={{ maxRows: 12, minRows: 12 }}
                    style={{
                      borderRadius: 12,
                      background: "#F4F7FE",
                    }}
                  />
                </FormItem>
              </Col>

              <Col span={24} className="flex justify-end footer">
                <Button
                  className="footerButton"
                  onClick={form.submit}
                  loading={loading}
                >
                  Save edit
                </Button>
              </Col>
            </Row>
          </EditCategoryContainer>
        </Form>
      </Col>
    </Row>
  );
};
