import { FC } from "react";
import { CategoriesChildItemsContainer } from "../style";
import { CategoryTypeEnum, ITreeCategory } from "src/services/System/models";
import { Tooltip } from "antd";
interface ICategoriesChildItems extends ITreeCategory {
  title: string;
  itemsCount: number | string;
  isSelected?: boolean;
  onClick?: () => void;
}
export const CategoriesChildItems: FC<ICategoriesChildItems> = (props) => {
  const { title, itemsCount, code, isSelected, onClick, categoryType } = props;
  return (
    <CategoriesChildItemsContainer
      className={isSelected ? " selected" : ""}
      onClick={() => {
        onClick && onClick();
      }}
    >
      <div className="flex gap-[24px]">
        <div
          className={`codeHolder text-center ${
            categoryType === CategoryTypeEnum.Income
              ? "!bg-[#6BC497]"
              : "!bg-[#EB6F70]"
          }`}
        >
          {code}
        </div>
        <div className="flex flex-col gap-[4px]">
          <div className={`titleHolder ${isSelected ? "!text-[#000]" : ""}`}>
            {title.length > 24 ? (
              <Tooltip title={title}>
                {title.substring(0, 20).concat("...")}
              </Tooltip>
            ) : (
              title
            )}
          </div>
          <div className="subTitleHolder">({itemsCount} items)</div>
        </div>
      </div>

      {+itemsCount > 0 && (
        <span
          className={`material-icons ${
            isSelected ? "text-[#000]" : "text-[#A3AED0]"
          } `}
        >
          keyboard_arrow_right
        </span>
      )}
    </CategoriesChildItemsContainer>
  );
};
