import { FC } from "react";
import { Financial } from "src/modules/Financial";
import { AttachIncomeProvider } from "src/modules/Financial/components/AddNewIncome/context/attachFile";
import { FinancialProvider } from "src/modules/Financial/context";

export const FinancialPage: FC = () => {
  return (
    <FinancialProvider>
      <AttachIncomeProvider>
        <Financial />
      </AttachIncomeProvider>
    </FinancialProvider>
  );
};
