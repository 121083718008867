import _ from "lodash";
import { IObject } from "src/models/interfaces";

export const addSearchParams = (url: string, query: IObject) => {
  let endpoint = url + "?";
  Object.keys(query).forEach((key) => {
    let isFirst = endpoint[endpoint.length - 1] === "?";
    if (query[key] && _.isArray(query[key])) {
      query[key].forEach((value: number | string, index: number) => {
        endpoint = endpoint + `${index === 0 ? "" : "&"}${key}=${value}`;
      });
    } else {
      if (query[key])
        endpoint = endpoint + `${isFirst ? "" : "&"}${key}=${query[key]}`;
    }
  });
  return endpoint;
};
