import styled from "styled-components";

export const LayoutContainer = styled.div<{ path: string }>`
  .ant-menu-horizontal {
    border: unset;
  }
  .contentWrapper {
    background: #f4f7fe;
    min-height: 100vh;
  }
  .layout {
    background: #fff;
  }
  .ant-layout-sider-children {
    position: fixed;
    width: 290px;
    padding: 24px;
  }
  .ant-layout-content {
    /* height: calc(100vh - 64px); */
    /* width: 100vw; */
    overflow-y: auto;
    background-color: #f4f7fe;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  .ant-layout-header {
    background-color: #f4f7fe;
    line-height: unset;
    padding-inline: 24px;
    margin-top: 37px;
  }
  .ant-layout-sider {
    background: #fff;
    height: 100vh;
    overflow-y: auto;
  }
  overflow-y: auto;
`;
