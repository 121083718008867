import { FC, useState } from "react";
import { TableContainer } from "./styles";
import { useExpensiveReport } from "../../context";
import { TableUI } from "src/components/UiKit/table";
import { IReports } from "src/services/InvoiceReport/models";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { Col, Popover, Spin } from "antd";
import { generateExpensiveReport } from "../../helper/createExcel";
import { uniqueId } from "lodash";

export const ArticleReportTable: FC = () => {
  const {
    value: { loadings, ArticleData, currentRequestValue, ArticlePagination },
    dispatch: { setArticlePagination },
    func: { fetchReports },
  } = useExpensiveReport();
  const [dlLoading, setDlLoading] = useState<boolean>(false);
  const columns: ColumnsType<IReports> = [
    {
      title: "#",
      key: "key",
      align: "center",
      render: (text, record, index) =>
        (ArticlePagination.Offset - 1) * ArticlePagination.Limit + (index + 1),
      fixed: "left",
      width: 60,
    },
    {
      title: "Invoice number",
      dataIndex: "invoiceNO",
      key: "invoiceNO",
      align: "center",
      fixed: "left",
      width: 150,
    },
    {
      title: "Ship",
      dataIndex: "shipName",
      key: "shipName",
      align: "center",
      fixed: "left",
      width: 94,
    },
    {
      title: "Supplier",
      dataIndex: "supplierName",
      key: "supplierName",
      align: "center",

      width: 130,
      render: (text: string) =>
        text && text.length > 8 ? (
          <Popover content={text}>
            <span className="cursor-pointer">
              {text.substring(0, 8).concat("...")}
            </span>
          </Popover>
        ) : (
          text
        ),
    },
    {
      title: "Requester",
      dataIndex: "requester",
      key: "requester",
      align: "center",
      width: 130,
      render: (text: string) =>
        text && text.length > 8 ? (
          <Popover content={text}>
            <span className="cursor-pointer">
              {text.substring(0, 8).concat("...")}
            </span>
          </Popover>
        ) : (
          text
        ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "center",
      width: 203,
      render: (text: string) =>
        text && text.length > 20 ? (
          <Popover content={text}>
            <span className="cursor-pointer">
              {text.substring(0, 20).concat("...")}
            </span>
          </Popover>
        ) : (
          text
        ),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      align: "center",

      width: 203,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
      render: (text: number) => text?.toLocaleString("en-US"),
      width: 93,
    },
    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      key: "unitPrice",
      align: "center",
      width: 98,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      render: (text: number) => text?.toLocaleString("en-US"),
      width: 87,
    },
    {
      title: "Inv currency",
      dataIndex: "invoiceCurrency",
      key: "invoiceCurrency",
      align: "center",
      width: 116,
    },

    {
      title: "Amount USD",
      dataIndex: "amountUSD",
      key: "amountUSD",
      align: "center",
      width: 100,
      render: (text: number) => (text ? text.toLocaleString("en-US") : "--"),
    },
    {
      title: "Our date",
      dataIndex: "ourDate",
      key: "ourDate",
      align: "center",
      render: (text) => moment(text).format("YYYY-MM-DD"),
      width: 120,
    },
  ];
  const onDownloadExcel = async () => {
    if (!currentRequestValue) return;
    try {
      setDlLoading(true);
      const res = await fetchReports({
        values: currentRequestValue,
        justData: true,
        withoutPagination: true,
      });
      if (res) {
        generateExpensiveReport({
          data: res.reports.records,
          title: "Expensive Report",
        });
      }
    } catch (err) {
    } finally {
      setDlLoading(false);
    }
  };
  return (
    <TableContainer>
      <Col span={24}>
        <TableUI
          dataSource={ArticleData}
          columns={columns}
          scroll={{ x: 800 }}
          loading={loadings.article}
          rowKey={() => uniqueId()}
          pagination={{
            style: { marginTop: 40, marginBottom: 0 },
            position: ["bottomCenter"],
            pageSize: ArticlePagination.Limit,
            current: ArticlePagination.Offset,
            total: ArticlePagination.total,
            onChange: (page, pageSize) => {
              if (currentRequestValue) {
                fetchReports({
                  values: currentRequestValue,
                  pagination: {
                    Limit: pageSize,
                    Offset: pageSize !== ArticlePagination.Limit ? 1 : page,
                  },
                });
              }
              setArticlePagination((prev) => ({
                ...prev,
                Limit: pageSize,
                Offset: pageSize !== prev.Limit ? 1 : page,
              }));
            },
          }}
          containerStyle={{ padding: 16 }}
        />
        {dlLoading ? (
          <Spin className="download" />
        ) : (
          <img
            src="/assets/images/dlExcelIncome.svg"
            alt="download"
            className="download cursor-pointer"
            onClick={onDownloadExcel}
          />
        )}
      </Col>
    </TableContainer>
  );
};
