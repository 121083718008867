import { TableColumnsType } from "antd";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { IIncomeReport } from "src/services/Income/models";
import dayjs from "dayjs";
interface IProps {
  data: IIncomeReport[] | undefined;
  columns: TableColumnsType<IIncomeReport>;
  title: string;
}
export const generateIncomeExcel = async ({ data, columns, title }: IProps) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Sheet1");

  // ادغام سلول‌ها
  worksheet.mergeCells("C3:O3");
  worksheet.columns.forEach((column) => {
    column.width = 18;
    column.alignment = {
      horizontal: "center",
      vertical: "middle",
      wrapText: true,
    };
    column.font = {
      name: "Tahoma",
      size: 12,
      color: { argb: "000000" },
    };
  });

  const cell = worksheet.getCell("C3");

  cell.value = title;

  // تنظیم استایل برای سلول ادغام شده
  cell.font = {
    name: "Tahoma",
    size: 14,
    bold: true,
    color: { argb: "000000" },
  };

  cell.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "CACACA" },
  };

  cell.alignment = {
    horizontal: "center",
    vertical: "middle",
    wrapText: true,
  };
  const titleRow = worksheet.getRow(3);
  titleRow.height = 40;

  const header: string[] = columns.map((column) => column.title as string);
  const excelData = data?.map((item, index) => {
    const receiptDate = item.receiptDate
      ? dayjs(item.receiptDate).format("YYYY.MM.DD")
      : "";
    const transactionDate = item.transactionDate
      ? dayjs(item.transactionDate).format("YYYY.MM.DD")
      : "";
    return [
      "",
      "",
      index + 1,
      item.vesselName,
      item.voyageNo,
      item.refCode,
      item.categoryname,
      item.receiptNo,
      item.amount,
      item.currencyname,
      item.amountUsd,
      item.payerName,
      item.receiverName,
      receiptDate,
      transactionDate,
    ];
  });
  // داده‌های جدول
  const tableData = [[], ["", "", ...header], ...(excelData || [])];

  // افزودن داده‌ها به شیت
  tableData.forEach((row, index) => {
    worksheet.addRow(row);
  });
  const headerRow = worksheet.getRow(5);
  headerRow.eachCell((headCell, colNum) => {
    if (colNum > 2) {
      headCell.font = {
        name: "Tahoma",
        size: 12,
        bold: true,
        color: { argb: "000000" },
      };
      headCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "E1E1E1" },
      };

      headCell.alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
    }
  });

  headerRow.height = 40;

  // ایجاد فایل اکسل
  const buffer = await workbook.xlsx.writeBuffer();

  // ذخیره فایل اکسل
  const blob = new Blob([buffer], { type: "application/octet-stream" });
  saveAs(blob, "IncomeReportExcel.xlsx");
};
