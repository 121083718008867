import { FC, ReactNode } from "react";
import { IncomeViewItemStyle } from "../styles";

export const Item: FC<{ label: string; value: ReactNode }> = ({
  label,
  value,
}) => {
  return (
    <IncomeViewItemStyle gap={12}>
      <div className="label">{label}</div>
      <span className="value">{value}</span>
    </IncomeViewItemStyle>
  );
};
