import { checkActionPermission } from "src/helpers/checkPermissionFunc";
import {
  IAllReceiversArgs,
  IAllPayer,
  IAllPayerArgs,
  IAllReceivers,
  INewIncome,
  INewPayer,
  INewReceiver,
  IAllIncomesArgs,
  IAllIncomes,
  IAllVoyageIncomesArgs,
  IAllVoyageIncomes,
  INewIncomeAttachmentDetail,
  IAllIncomeAttachmentDetailsArgs,
  IAllIncomeAttachmentDetails,
  INewIncomeAttachment,
  IGetIncomeAttachment,
  IIncomeReportTable,
  IIncomeReportValues,
} from "./models";
import * as GuardPaths from "./guardPath";
import { permissionLog } from "src/helpers/permissionLog";
import axios from "axios";
import { IncomeUrls } from "./urls";
import { IDataModel } from "../models";
import { addSearchParams } from "src/helpers/addSearchParams";

export class IncomeService {
  async NewPayer(data: INewPayer) {
    if (!checkActionPermission(GuardPaths.NewPayerPath)) {
      permissionLog(GuardPaths.NewPayerPath.path);
      return undefined;
    }
    try {
      const response = await axios.post<void>(IncomeUrls.NewPayer, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AllPayers(query?: IAllPayerArgs) {
    if (!checkActionPermission(GuardPaths.AllPayersPath)) {
      permissionLog(GuardPaths.AllPayersPath.path);
      return undefined;
    }
    let endpoint: string = IncomeUrls.AllPayers;
    if (query) {
      endpoint = endpoint + "?" + new URLSearchParams(query).toString();
    }
    try {
      const response = await axios.get<IDataModel<IAllPayer[]>>(endpoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeletePayer(PayerId: number) {
    if (!checkActionPermission(GuardPaths.DeletePayerPath)) {
      permissionLog(GuardPaths.DeletePayerPath.path);
      return undefined;
    }
    try {
      let endpoint = IncomeUrls.income.concat(
        `${PayerId}${IncomeUrls.DeletePayer}`
      );
      const response = await axios.delete<void>(endpoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdatePayer(PayerId: number, data: INewPayer) {
    if (!checkActionPermission(GuardPaths.UpdatePayerPath)) {
      permissionLog(GuardPaths.UpdatePayerPath.path);
      return undefined;
    }
    try {
      let endpoint = IncomeUrls.income.concat(
        `${PayerId}${IncomeUrls.UpdatePayer}`
      );
      const response = await axios.put<void>(endpoint, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async NewReceiver(data: INewReceiver) {
    if (!checkActionPermission(GuardPaths.NewReceiverPath)) {
      permissionLog(GuardPaths.NewReceiverPath.path);
      return undefined;
    }
    try {
      const response = await axios.post<void>(IncomeUrls.NewReceiver, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AllReceivers(query?: IAllReceiversArgs) {
    if (!checkActionPermission(GuardPaths.AllReceiversPath)) {
      permissionLog(GuardPaths.AllReceiversPath.path);
      return undefined;
    }
    let endpoint: string = IncomeUrls.AllReceivers;
    if (query) {
      endpoint = endpoint + "?" + new URLSearchParams(query).toString();
    }
    try {
      const response = await axios.get<IDataModel<IAllReceivers[]>>(endpoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteReceiver(ReceiverId: number) {
    if (!checkActionPermission(GuardPaths.DeleteReceiverPath)) {
      permissionLog(GuardPaths.DeleteReceiverPath.path);
      return undefined;
    }
    try {
      let endpoint = IncomeUrls.income.concat(
        `${ReceiverId}${IncomeUrls.DeleteReceiver}`
      );
      const response = await axios.delete<void>(endpoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateReceiver(ReceiverId: number, data: INewReceiver) {
    if (!checkActionPermission(GuardPaths.UpdateReceiverPath)) {
      permissionLog(GuardPaths.UpdateReceiverPath.path);
      return undefined;
    }
    try {
      let endpoint = IncomeUrls.income.concat(
        `${ReceiverId}${IncomeUrls.UpdateReceiver}`
      );
      const response = await axios.put<void>(endpoint, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async NewIncome(data: INewIncome) {
    if (!checkActionPermission(GuardPaths.NewIncomePath)) {
      permissionLog(GuardPaths.NewIncomePath.path);
      return undefined;
    }
    try {
      const response = await axios.post<void>(IncomeUrls.NewIncome, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AllIncomes(query?: IAllIncomesArgs) {
    if (!checkActionPermission(GuardPaths.AllIncomesPath)) {
      permissionLog(GuardPaths.AllIncomesPath.path);
      return undefined;
    }
    let endpoint: string = IncomeUrls.AllIncomes;
    if (query) {
      endpoint = endpoint + "?" + new URLSearchParams(query).toString();
    }
    try {
      const response = await axios.get<IAllIncomes>(endpoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteIncome(IncomeId: number) {
    if (!checkActionPermission(GuardPaths.DeleteIncomePath)) {
      permissionLog(GuardPaths.DeleteIncomePath.path);
      return undefined;
    }
    try {
      let endpoint = IncomeUrls.income.concat(
        `${IncomeId}${IncomeUrls.DeleteIncome}`
      );
      const response = await axios.delete<void>(endpoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateIncome(IncomeId: number, data: INewIncome) {
    if (!checkActionPermission(GuardPaths.UpdateIncomePath)) {
      permissionLog(GuardPaths.UpdateIncomePath.path);
      return undefined;
    }
    try {
      let endpoint = IncomeUrls.income.concat(
        `${IncomeId}${IncomeUrls.UpdateIncome}`
      );
      const response = await axios.put<void>(endpoint, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AllVoyageIncomes(query?: IAllVoyageIncomesArgs) {
    if (!checkActionPermission(GuardPaths.AllVoyageIncomesPath)) {
      permissionLog(GuardPaths.AllVoyageIncomesPath.path);
      return undefined;
    }
    let endpoint: string = IncomeUrls.AllVoyageIncomes;
    if (query) {
      endpoint = addSearchParams(endpoint, query);
    }
    try {
      const response = await axios.get<IDataModel<IAllVoyageIncomes[]>>(
        endpoint
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async NewIncomeAttachmentDetail(
    IncomeId: number,
    data: INewIncomeAttachmentDetail
  ) {
    if (!checkActionPermission(GuardPaths.NewIncomeAttachmentDetailPath)) {
      permissionLog(GuardPaths.NewIncomeAttachmentDetailPath.path);
      return undefined;
    }
    try {
      let endpoint = IncomeUrls.income.concat(
        `${IncomeId}${IncomeUrls.NewIncomeAttachmentDetail}`
      );
      const response = await axios.post<number>(endpoint, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AllIncomeAttachmentDetails(
    IncomeId: number,
    query?: IAllIncomeAttachmentDetailsArgs
  ) {
    if (!checkActionPermission(GuardPaths.AllIncomeAttachmentDetailsPath)) {
      permissionLog(GuardPaths.AllIncomeAttachmentDetailsPath.path);
      return undefined;
    }
    let endpoint = IncomeUrls.income.concat(
      `${IncomeId}${IncomeUrls.AllIncomeAttachmentDetails}`
    );
    if (query) {
      endpoint = endpoint + "?" + new URLSearchParams(query).toString();
    }
    try {
      const response = await axios.get<
        IDataModel<IAllIncomeAttachmentDetails[]>
      >(endpoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteIncomeAttachmentDetail(IncomeAttachmentDetailId: number) {
    if (!checkActionPermission(GuardPaths.DeleteIncomeAttachmentDetailPath)) {
      permissionLog(GuardPaths.DeleteIncomeAttachmentDetailPath.path);
      return undefined;
    }
    try {
      let endpoint = IncomeUrls.income.concat(
        `${IncomeAttachmentDetailId}${IncomeUrls.DeleteIncomeAttachmentDetail}`
      );
      const response = await axios.delete<void>(endpoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateIncomeAttachmentDetail(
    IncomeAttachmentDetailId: number,
    data: INewIncomeAttachmentDetail
  ) {
    if (!checkActionPermission(GuardPaths.UpdateIncomeAttachmentDetailPath)) {
      permissionLog(GuardPaths.UpdateIncomeAttachmentDetailPath.path);
      return undefined;
    }
    try {
      let endpoint = IncomeUrls.income.concat(
        `${IncomeAttachmentDetailId}${IncomeUrls.UpdateIncomeAttachmentDetail}`
      );
      const response = await axios.put<void>(endpoint, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async NewIncomeAttachment(
    IncomeAttachmentDetailId: number,
    data: INewIncomeAttachment
  ) {
    if (!checkActionPermission(GuardPaths.NewIncomeAttachmentPath)) {
      permissionLog(GuardPaths.NewIncomeAttachmentPath.path);
      return undefined;
    }
    try {
      let endpoint = IncomeUrls.income.concat(
        `${IncomeAttachmentDetailId}${IncomeUrls.NewIncomeAttachment}`
      );
      const response = await axios.post<number>(endpoint, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetIncomeAttachment(IncomeAttachmentDetialId: number) {
    if (!checkActionPermission(GuardPaths.GetIncomeAttachmentPath)) {
      permissionLog(GuardPaths.GetIncomeAttachmentPath.path);
      return undefined;
    }
    let endpoint = IncomeUrls.income.concat(
      `${IncomeAttachmentDetialId}${IncomeUrls.GetIncomeAttachment}`
    );

    try {
      const response = await axios.get<IGetIncomeAttachment[]>(endpoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateIncomeFile(
    IncomeAttachmentDetailId: number,
    data: INewIncomeAttachment
  ) {
    if (!checkActionPermission(GuardPaths.UpdateIncomeFilePath)) {
      permissionLog(GuardPaths.UpdateIncomeFilePath.path);
      return undefined;
    }
    try {
      let endpoint = IncomeUrls.income.concat(
        `${IncomeAttachmentDetailId}${IncomeUrls.UpdateIncomeFile}`
      );
      const response = await axios.patch<void>(endpoint, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteIncomeAttachment(IncomeAttachmentDetailId: number) {
    if (!checkActionPermission(GuardPaths.DeleteIncomeAttachmentPath)) {
      permissionLog(GuardPaths.DeleteIncomeAttachmentPath.path);
      return undefined;
    }
    try {
      let endpoint = IncomeUrls.income.concat(
        `${IncomeAttachmentDetailId}${IncomeUrls.DeleteIncomeAttachment}`
      );
      const response = await axios.delete<void>(endpoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async IncomeReport(data: IIncomeReportValues) {
    if (!checkActionPermission(GuardPaths.IncomeReportPath)) {
      permissionLog(GuardPaths.IncomeReportPath.path);
      return undefined;
    }
    try {
      let endpoint = IncomeUrls.income.concat(`${IncomeUrls.IncomeReport}`);
      const response = await axios.post<IIncomeReportTable>(endpoint, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
}
