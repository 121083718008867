import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
body * :not(.material-icons , .material-symbols-outlined){
  font-family: DM Sans !important;
}
.selectOption{
  height: 100%;
    .ant-select-selector{
        background: #f4f7fe !important; 
  border-color: #f4f7fe !important;
  border-radius: 12px !important;
  min-height: 48px;
  display: flex;
  align-items: center;
    }
   input{
    /* margin-top: 8px !important; */
   }
   .ant-select-arrow{
    height: 100% !important;
    top: 0 !important;
   }
}
.ant-form-item-control{
  input,
  .ant-select-selection-item,
  textarea,.ant-select-selection-placeholder {
    color: #878787!important;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    /* letter-spacing: -0.24px; */
  }
}
.s-textarea{
  background: #f4f7fe !important; 
  border-color: #f4f7fe !important;
  border-radius: 12px !important;
  min-height: 48px;
  display: flex;
  align-items: center;
}
.ant-input-disabled,.ant-input-number-disabled{
  border-color: #F3F4F5 !important;
  background: #DADEEC !important;
  border-color: #DADEEC !important;
}
.ant-select-disabled{
 .ant-select-selector{background:#DADEEC !important} 
}
 .ant-picker.ant-picker-disabled {
    background: #DADEEC !important;
    border-color: #DADEEC !important;
  }

.ant-divider-with-text::before{
  border-block-start-color:#2C73FC !important ;
}
.unset-modal-padding{
  .ant-modal-content{
    padding: unset !important;
  }
}
.filterPopUp{
  .ant-tabs-nav-wrap{
    justify-content: center !important;
  }
   .ant-popconfirm-buttons {
    display: flex;
    justify-content: space-between !important;
  }
  button{
  width: 51px !important;
 }
  .ant-popover-inner{
    /* height: 320px; */
    width: 500px;
    padding: 24px 12px 12px 12px !important;
  }
  .ant-popconfirm-message{
    margin-bottom: 16px;
    justify-content: center;
  }
  
  .ant-popconfirm-message-text{
    width: 100% ;
  }

.ant-tabs-content-holder{
  /* height: 175px; */
}
}

.frontAccessFilterPopUp{
   .ant-popconfirm-buttons {
    display: flex;
    justify-content: space-between !important;
  }
  button{
  width: 51px !important;
 }
  .ant-popover-inner{
    width: 300px;
    padding: 24px 12px 12px 12px !important;
  }
  .ant-popconfirm-message{
    margin-bottom: 16px;
    justify-content: center;
  }
  
  .ant-popconfirm-message-text{
    width: 100% ;
  }

.ant-tabs-content-holder{
  height: 175px;
}
}

.rejectedPopconfirm{
  .ant-popconfirm-title{
color: #001377 !important;
font-family: DM Sans;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
  }
  .ant-popconfirm-description{
    color: var(--text, #001377)!important;
font-family: DM Sans;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
  }
   .ant-btn-primary{
    border-radius: 16px;
    background : #FD29B5 !important;
    height: 48px;
    width: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 24px 12px 12px;

  }
  .ant-btn-default{
    border-radius: 16px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 24px 12px 12px;
    width: 125px;
  }
  .ant-popconfirm-buttons{
    display: flex;
    justify-content: center;
    gap: 16px;
  }
  .ant-popover-inner{
    padding: 24px !important;
  }
  .ant-popconfirm-inner-content{
    display: flex;
    gap: 32px;
    flex-direction: column;
  }
  .ant-popconfirm-message-text{
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
 }
 .acceptedPopconfirm{
  .ant-popconfirm-title{
color: #001377 !important;
font-family: DM Sans;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
  }
  .ant-popconfirm-description{
    color: var(--text, #001377)!important;
font-family: DM Sans;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
  }
   .ant-btn-primary{
    border-radius: 16px;
    background : #1DCF0E !important;
    width: 100px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 24px 12px 12px;
    width: 125px;
  }
  .ant-btn-default{
    border-radius: 16px;
    width: 100px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 24px 12px 12px;
    width: 125px;

  }
  .ant-popconfirm-buttons{
    display: flex;
    justify-content: center;
    gap: 16px;
  }
  .ant-popover-inner{
    padding: 24px !important;
  }
  .ant-popconfirm-inner-content{
    display: flex;
    gap: 32px;
    flex-direction: column;
  }
  .ant-popconfirm-message-text{
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
 }
 .deletePopconfirm{
  .ant-popconfirm-title{
color: #001377 !important;
font-family: DM Sans;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
  }
  .ant-popconfirm-description{
    color: var(--text, #001377)!important;
font-family: DM Sans;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
  }
   .ant-btn-primary{
    border-radius: 16px;
    background : #FF1414 !important;
    width: 100px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 12px 12px 12px;
    width: 125px;
  }
  .ant-btn-default{
    border-radius: 16px;
    width: 100px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 12px 12px 12px;
    width: 125px;

  }
  .ant-popconfirm-buttons{
    display: flex;
    justify-content: center;
    gap: 16px;
  }
  .ant-popover-inner{
    padding: 24px !important;
  }
  .ant-popconfirm-inner-content{
    display: flex;
    gap: 32px;
    flex-direction: column;
  }
  .ant-popconfirm-message-text{
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
 }
.confirm-modal{
  .ant-modal-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:16px
  }
}
.ant-table-thead {
  tr{
    th{
      padding: 16px 14px !important;

    }
  }
}
tr{
  th::before{
    content: unset !important;
  }
  th{
    text-align: center !important;
  }
}
.assign-modal{
    .ant-modal-content{
        padding: 12px !important;
    }
}
.ant-select-arrow {
    margin-top: unset !important;
  }
  .AddCountryModal{
  .titleHolder {
  display: flex;
  padding-bottom: 16px;
  gap: 4px;
  align-self: stretch;
  border-bottom: 1px solid var(--shadow, #dadeec);
  }
  .footerHolder{
    border-top: 1px solid var(--strock, #F3F4F5);
    display: flex;
    padding-top: 24px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
  }
  }
  .addHolder{
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
  }
  .enable{
    border-radius: 16px;
border: 1px solid var(--Info-Info-600, #3B82F6);
background: #fff;
color: var(--Info-Info-600, #3B82F6);
text-align: center;
font-family: DM Sans;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.7px;
display: flex;
padding: 12px;
justify-content: center;
align-items: center;
  }
  .disable{
    border-radius: 16px;
border: 1px solid var(--Info-Info-400, #93C8FF);
background: #fff;
color: var(--Info-Info-400, #93C8FF);
text-align: center;
font-family: DM Sans;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.7px;
display: flex;
padding: 12px;
justify-content: center;
align-items: center;
  }


  .ant-btn-default:disabled{
color:#CACACA;
background-color: #F5F5F5;
  }
  .ShipDailyPdf-popconfirm{
    .ant-popconfirm-buttons{
      display: flex;
      justify-content: center;
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-background-clip: unset !important;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: unset !important;
  }
  ::-webkit-scrollbar {
  width: 6px !important;
  height:5px !important;
}
/* Track */
::-webkit-scrollbar-track {
  background: #eee !important;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background:#cacaca !important;
  border-radius: 2px !important;
  background-clip: content-box !important;
  width: 4px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8e8e8e !important;
}

`;
