import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FiltersContainer } from "./style";
import { Col, Flex, Row } from "antd";
import { useExpensiveReport } from "../../context";
import { STag } from "src/components/UiKit/Tag";
import { invoiceReportFormItemsName } from "../../constant/flterFormNames";
import { IObject } from "src/models/interfaces";
import { INVOICE_REPORT_TYPES } from "../../models/reportTypes";

export const InvoiceReportFilters: FC = () => {
  const {
    value: {
      invoiceReportForm,
      reportType,
      defaultPagination,
      currentRequestValue,
      totalAmount,
    },
    dispatch: { setCurrentRequestValue, setArticlePagination },
    func: { fetchReports },
  } = useExpensiveReport();
  const [tags, setTags] = useState<string[]>([]);
  const formValues = useMemo(
    () => invoiceReportForm?.getFieldsValue(),
    [invoiceReportForm]
  );
  const filters = useCallback(() => {
    if (!currentRequestValue) return;
    Object.keys(currentRequestValue).forEach((key) => {
      Object.keys(invoiceReportFormItemsName).forEach((itemName) => {
        if ((invoiceReportFormItemsName as IObject)[itemName] === key) {
          if (!!currentRequestValue[key]) {
            setTags((prev) => {
              if (!prev.includes(itemName)) {
                return [...prev, itemName];
              } else {
                return [...prev];
              }
            });
          } else {
            setTags((prev) => prev.filter((i) => i !== itemName));
          }
        }
      });
      // });
    });
  }, [currentRequestValue]);

  const onCloseTag = (item: string) => {
    setTags((prev) => prev.filter((i) => i !== item));
    const newCurrentValue = { ...currentRequestValue };
    delete (newCurrentValue as IObject)[
      (invoiceReportFormItemsName as IObject)[item]
    ];
    invoiceReportForm?.setFieldValue(
      (invoiceReportFormItemsName as IObject)[item],
      undefined
    );
    setCurrentRequestValue(newCurrentValue);
    switch (reportType) {
      case INVOICE_REPORT_TYPES.Article:
        if (newCurrentValue) {
          setArticlePagination(defaultPagination);
          fetchReports({
            values: newCurrentValue,
            pagination: defaultPagination,
          });
        }
        break;
    }
  };

  useEffect(() => {
    filters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);
  return (
    <Row gutter={40}>
      <Col span={18}>
        <FiltersContainer className="h-[84px]">
          <Flex className="p-[16px]" align="center" gap={12}>
            <div className="filterIconHolder">
              <span className="material-icons text-[24px] text-[#fff]">
                tune
              </span>
            </div>
            {tags.map((item, index) => (
              <STag
                title={item}
                onclose={() => {
                  onCloseTag(item);
                }}
                key={index}
              />
            ))}
          </Flex>
        </FiltersContainer>
      </Col>
      <Col span={6}>
        <FiltersContainer className="h-[84px]">
          <Flex
            className="px-[16px] py-[8px]"
            justify="center"
            gap={6}
            vertical
          >
            <Flex justify="space-between" align="center" className="w-full">
              <div className="total">Total (USD)</div>
              <div className="w-[32px] h-[32px]">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="32" height="32" rx="16" fill="#F6F8FD" />
                  <path
                    d="M14.737 9.87395C14.7172 9.17546 15.2707 8.47447 15.9967 8.50071C16.7153 8.48072 17.2911 9.15922 17.2626 9.86395C18.3907 10.1239 19.5325 10.6687 20.199 11.6583C20.6619 12.4367 19.9979 13.5513 19.0981 13.5001C18.3088 13.5176 17.9638 12.6404 17.2465 12.4455C17.2365 13.2527 17.2427 14.0611 17.2427 14.8683C18.1425 15.0833 19.0224 15.5068 19.6405 16.2166C20.539 17.2062 20.7587 18.7506 20.1779 19.9564C19.6467 21.1222 18.4764 21.8532 17.2651 22.1244C17.2725 22.7766 16.8059 23.4464 16.1283 23.4901C15.3551 23.5938 14.6886 22.8653 14.737 22.1056C13.7826 21.8657 12.8841 21.4034 12.1457 20.7486C11.8925 20.5225 11.6741 20.2251 11.6617 19.8715C11.5748 19.1667 12.1916 18.4645 12.9027 18.4982C13.6536 18.5095 14.0755 19.2667 14.7581 19.4854C14.7606 18.7006 14.7594 17.9147 14.7581 17.13C13.8571 16.9163 12.9797 16.4927 12.3616 15.7817C11.4829 14.8159 11.2509 13.3202 11.7833 12.1281C12.2958 10.9173 13.4947 10.1488 14.737 9.87395ZM13.9825 13.4926C13.9825 13.9749 14.3647 14.321 14.7519 14.536C14.7643 13.84 14.7631 13.144 14.7519 12.448C14.3796 12.6792 13.9812 13.0103 13.9825 13.4926ZM17.2489 17.4624C17.2365 18.1571 17.2378 18.8543 17.2489 19.5503C17.6225 19.3167 18.0259 18.9818 18.0172 18.4957C18.0134 18.0172 17.6324 17.6773 17.2489 17.4624Z"
                    fill="#2C73FC"
                  />
                </svg>
              </div>
            </Flex>
            <div className="amount">
              {totalAmount?.toLocaleString("en-US") || "--"}
            </div>
          </Flex>
        </FiltersContainer>
      </Col>
    </Row>
  );
};
