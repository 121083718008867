import { FC, useCallback, useEffect, useState } from "react";
import { EditBaseInformationContainer } from "../styles/baseInformation";
import { App, Button, Col, Divider, Form, Row, Select } from "antd";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import _ from "lodash";
import {
  IInitInvoiceInof,
  IInvoiceByInvoiceNumber,
  IInvoiceType,
  IUpdateBasicInformationInvoice,
  InvoiceType,
} from "src/services/Invoice/models";
import { InvoiceService } from "src/services/Invoice/Invoice.service";
import { EditOutlined } from "@ant-design/icons";
import { SDatePicker } from "src/components/UiKit/DatePicker";
import { useEditInvoice } from "../context";
import dayjs from "dayjs";
import { IEditBaseInfoFormValue } from "../models";
import { EditBaseInformationSkeleton } from "./EditBaseInformationSkeleton";
import { Guard } from "src/components/Guard";
import { UpdateBasicInformationInvoicePath } from "src/services/Invoice/guardPath";
import { IAllVoyage } from "src/services/Operation/models/result.model";
import { OperationService } from "src/services/Operation/Operation.service";
import { IAllVoyageArg } from "src/services/Operation/models/args.models";

interface IProps {
  onCancelModal: () => void;
}
export const EditBaseInformation: FC<IProps> = ({ onCancelModal }) => {
  const { InvoiceByInvoiceNumber, InitInvoiceInof } = new InvoiceService();
  const {
    value: {
      currentEditInvoice,
      editInvoiceId,
      currentPdfFile,
      getCurrentInvoiceLoading,
      supplierList,
      supplierLoading,
    },
    func: { refetchZipFileToggle },
  } = useEditInvoice();
  const [preInvoices, setPerInvoices] = useState<IInvoiceByInvoiceNumber[]>([]);
  const [reservedInvoices, setReservedInvoices] = useState<
    IInvoiceByInvoiceNumber[]
  >([]);
  const { message } = App.useApp();

  const [invoiceInfo, setInvoiceInfo] = useState<IInitInvoiceInof>();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [EditBaseInfoForm] = Form.useForm();
  const [fetchInitInvoiceInofLoading, setFetchInitInvoiceInofLoading] =
    useState<boolean>(false);
  const fetchInvoice = _.debounce(async function (
    text: string,
    options: { ShipId?: number; InvoiceType: InvoiceType }
  ) {
    setSearchLoading(true);
    InvoiceByInvoiceNumber(text, options)
      .then((res) => {
        if (res && res.data) {
          if (options.InvoiceType === IInvoiceType.PreInvoice) {
            setPerInvoices(res.data);
          }
          if (options.InvoiceType === IInvoiceType.ReserveFund) {
            setReservedInvoices(res.data);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setSearchLoading(false);
      });
  },
  200);
  const onFinish = async (values: Partial<IEditBaseInfoFormValue>) => {
    if (!editInvoiceId) return;
    try {
      setLoading(true);
      const parentId: number[] = [];
      if (values.preInvParentId) {
        values.preInvParentId.forEach((id) => {
          parentId.push(id);
        });
      }
      if (values.reserveParentId) {
        parentId.push(values.reserveParentId);
      }
      const reqBody: IUpdateBasicInformationInvoice = {
        ourDate: values.ourDate ? values.ourDate?.format("YYYY-MM-DD") : null,
        parentId,
        shmInvoiceDate: values.shmInvoiceDate
          ? values.shmInvoiceDate?.format("YYYY-MM-DD")
          : null,
        shmInvoiceNumber: values.shmInvoiceNumber,
        supplierInvoiceDate: values.suppliernvoiceDate
          ? values.suppliernvoiceDate?.format("YYYY-MM-DD")
          : null,
        supplierInvoiceNumber: values.supplierInvoiceNumber,
        supplierId: values.supplierId,
        voyageId: values.voyageId,
        usdRate: +values.usdRate!,
      };
      const { UpdateBasicInformationInvoice } = new InvoiceService();
      const res = await UpdateBasicInformationInvoice(editInvoiceId, reqBody);
      if (res && res.status === 200) {
        refetchZipFileToggle();
        setEditMode(false);
        message.success("Basic Information Invoice updated successfully");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const onSearch = (text: string, InvoiceType: InvoiceType) => {
    if (!invoiceInfo) return;
    setSearch(text);
    if (text) fetchInvoice(text, { ShipId: invoiceInfo?.shipId, InvoiceType });
  };
  const fetchInitInvoiceInof = useCallback(
    async (id: number) => {
      try {
        setFetchInitInvoiceInofLoading(true);
        const res = await InitInvoiceInof(id);
        if (res && res.data) {
          setInvoiceInfo(res.data);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setFetchInitInvoiceInofLoading(false);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPdfFile]
  );

  // --------------------
  // voyage
  // --------------------
  const [voyageForm] = Form.useForm();

  const [voyageSearch, setVoyageSearch] = useState<string>("");
  const [voyageSearchLoading, setVoyageSearchLoading] =
    useState<boolean>(false);
  const [allVoyage, setAllVoyage] = useState<IAllVoyage[]>([]);

  const fetchVoyage = _.debounce(async function (VoyageNO?: string) {
    if (!invoiceInfo) return;
    setVoyageSearchLoading(true);
    const { AllVoyage } = new OperationService();
    const params: IAllVoyageArg = {
      VoyageNO,
      ShipId: invoiceInfo.shipId,
    };
    AllVoyage(params)
      .then((res) => {
        if (res && res.data) {
          setAllVoyage(res.data.records);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setVoyageSearchLoading(false);
      });
  }, 200);

  const onSearchVoyage = (text: string) => {
    if (!invoiceInfo) return;
    setVoyageSearch(text);
    if (text) fetchVoyage(text);
  };
  useEffect(() => {
    if (currentEditInvoice) {
      fetchVoyage(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEditInvoice]);
  useEffect(() => {
    if (invoiceInfo) {
      voyageForm.setFieldsValue(invoiceInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceInfo]);
  // end voyage
  useEffect(() => {
    if (currentEditInvoice && editInvoiceId) {
      const { GetRelatedInvoice } = new InvoiceService();

      GetRelatedInvoice(editInvoiceId).then((res) => {
        if (res && res.data) {
          setPerInvoices(
            res.data.preInvoices.map((inv) => ({
              id: inv.relateInvoiceId,
              invoiceNumber: inv.invoiceNumber,
            }))
          );
          setReservedInvoices(
            res.data.reservedFund.map((inv) => ({
              id: inv.relateInvoiceId,
              invoiceNumber: inv.invoiceNumber,
            }))
          );
          const invoice: Partial<IEditBaseInfoFormValue> = {
            ..._.omit(currentEditInvoice, [
              "suppliernvoiceDate",
              "shmInvoiceDate",
              "ourDate",
            ]),
          };
          invoice.suppliernvoiceDate = currentEditInvoice.suppliernvoiceDate
            ? dayjs(currentEditInvoice.suppliernvoiceDate)
            : undefined;
          invoice.shmInvoiceDate = currentEditInvoice.shmInvoiceDate
            ? dayjs(currentEditInvoice.shmInvoiceDate)
            : undefined;
          invoice.ourDate = currentEditInvoice.ourDate
            ? dayjs(currentEditInvoice.ourDate)
            : undefined;
          invoice.preInvParentId = res.data.preInvoices.map(
            (inv) => inv.relateInvoiceId
          );
          invoice.reserveParentId =
            res.data.reservedFund.length > 0
              ? res.data.reservedFund[0].relateInvoiceId
              : undefined;
          EditBaseInfoForm.setFieldsValue(invoice);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEditInvoice, editInvoiceId]);
  useEffect(() => {
    if (currentPdfFile) {
      fetchInitInvoiceInof(currentPdfFile.id);
    }
  }, [fetchInitInvoiceInof, currentPdfFile]);
  if (getCurrentInvoiceLoading || fetchInitInvoiceInofLoading) {
    return <EditBaseInformationSkeleton />;
  }

  return (
    <Form form={EditBaseInfoForm} disabled={!editMode} onFinish={onFinish}>
      <EditBaseInformationContainer>
        <Col span={24}>
          <div className="title">
            you can edit invoice’s basic information .
          </div>
          <Row gutter={32}>
            <Col span={24} className="mt-[24px]">
              <Row gutter={24}>
                {/* ////// voyage /////// */}

                <Col span={12} className=" ps-[12px]">
                  <div className="relatedInvoice ps-[12px] pb-[8px]">
                    Related Voyage No.
                  </div>
                  <FormItem style={{ marginBottom: "unset" }} name="voyageId">
                    <Select
                      placeholder="insert"
                      onSearch={(text) => onSearchVoyage(text)}
                      optionLabelProp="label"
                      showSearch
                      allowClear
                      loading={
                        voyageSearchLoading || fetchInitInvoiceInofLoading
                      }
                      className="selectOption"
                      filterOption={false}
                      options={allVoyage.map((voyage) => ({
                        label: voyage.voyageNO,
                        value: voyage.id,
                      }))}
                      notFoundContent={
                        !voyageSearch ? (
                          <div className="flex flex-col items-center justify-center py-[4px] gap-[16px]">
                            <EditOutlined className="text-[#00000030] text-[32px]" />
                            <div className="text-center text-[#00000030] text-[12px]">
                              Please write Related Voyage No. (Enter at least
                              four letters).
                            </div>
                          </div>
                        ) : undefined
                      }
                    />
                  </FormItem>
                </Col>
                <Col span={12} className=" ">
                  <div className="relatedInvoice ps-[12px] pb-[8px]">
                    Related Reserve fund No.
                  </div>
                  <FormItem
                    style={{ marginBottom: "unset" }}
                    name="reserveParentId"
                  >
                    <Select
                      placeholder="insert"
                      onSearch={(text) =>
                        onSearch(text, IInvoiceType.ReserveFund)
                      }
                      optionLabelProp="label"
                      showSearch
                      allowClear
                      loading={searchLoading || fetchInitInvoiceInofLoading}
                      className="selectOption"
                      filterOption={false}
                      options={reservedInvoices.map((invoice) => ({
                        label: invoice.invoiceNumber,
                        value: invoice.id,
                      }))}
                      notFoundContent={
                        !search ? (
                          <div className="flex flex-col items-center justify-center py-[4px] gap-[16px]">
                            <EditOutlined className="text-[#00000030] text-[32px]" />
                            <div className="text-center text-[#00000030] text-[12px]">
                              Please write Related Reserve fund No (Enter at
                              least four letters).
                            </div>
                          </div>
                        ) : undefined
                      }
                    />
                  </FormItem>
                </Col>
                <Col span={12} className="mt-[24px]">
                  <div className="relatedInvoice ps-[12px] pb-[8px]">
                    Related pre-invoice (s) No.
                  </div>
                  <FormItem
                    style={{ marginBottom: "unset" }}
                    name="preInvParentId"
                  >
                    <Select
                      placeholder="insert"
                      onSearch={(text) =>
                        onSearch(text, IInvoiceType.PreInvoice)
                      }
                      optionLabelProp="label"
                      showSearch
                      allowClear
                      mode="multiple"
                      loading={searchLoading || fetchInitInvoiceInofLoading}
                      className="selectOption"
                      filterOption={false}
                      options={preInvoices.map((invoice) => ({
                        label: invoice.invoiceNumber,
                        value: invoice.id,
                      }))}
                      notFoundContent={
                        !search ? (
                          <div className="flex flex-col items-center justify-center py-[4px] gap-[16px]">
                            <EditOutlined className="text-[#00000030] text-[32px]" />
                            <div className="text-center text-[#00000030] text-[12px]">
                              Please write Related pre-invoice No (Enter at
                              least four letters).
                            </div>
                          </div>
                        ) : undefined
                      }
                    />
                  </FormItem>
                </Col>
                <Col span={12} className="mt-[16px]">
                  <FormItem
                    name={"usdRate"}
                    label="USD Rate (Invoice currency to USD)"
                    rules={[{ required: true }]}
                  >
                    <SInput
                      style={{ width: "100%" }}
                      placeholder="insert"
                      numbermode
                    />
                  </FormItem>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Divider />
            </Col>
            <Col span={24}>
              <div className="title">
                Please insert invoice’s base information .
              </div>
              <Row gutter={32}>
                <Col span={12} className="mt-[16px]">
                  <FormItem
                    name={"shmInvoiceNumber"}
                    label="Requester Invoice number"
                    // rules={[{ required: true }]}
                    // required={false}
                  >
                    <SInput
                      style={{ width: "100%" }}
                      placeholder="insert"
                      // numbermode
                    />
                  </FormItem>
                  <FormItem
                    name={"supplierInvoiceNumber"}
                    label="Supplier Invoice number"
                    // required={false}
                  >
                    <SInput
                      style={{ width: "100%" }}
                      placeholder="insert"
                      // numbermode
                    />
                  </FormItem>
                  <FormItem
                    name={"suppliernvoiceDate"}
                    label="Supplier invoice date"
                    // required={false}
                  >
                    <SDatePicker
                      style={{ width: "100%" }}
                      placeholder="select"
                    />
                  </FormItem>
                </Col>
                <Col span={12} className="mt-[16px]">
                  <FormItem
                    name={"supplierId"}
                    label="Supplier"
                    // rules={[{ required: true }]}
                    // required={false}
                  >
                    <Select
                      placeholder="select"
                      allowClear
                      className="selectOption"
                      showSearch
                      optionFilterProp="label"
                      options={supplierList?.map((supp) => ({
                        label: supp.name,
                        value: supp.id,
                      }))}
                      loading={supplierLoading}
                    />
                  </FormItem>
                  <FormItem
                    name={"shmInvoiceDate"}
                    label="Requester invoice date"
                    // rules={[{ required: true }]}
                    // required={false}
                  >
                    <SDatePicker
                      style={{ width: "100%" }}
                      placeholder="select"
                    />
                  </FormItem>

                  <FormItem
                    name={"ourDate"}
                    label="Our date"
                    rules={[{ required: true }]}
                  >
                    <SDatePicker
                      style={{ width: "100%" }}
                      placeholder="select"
                    />
                  </FormItem>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Divider className="mb-[32px]" />
        </Col>
        <Col span={24} className="flex justify-end gap-[24px]  footer">
          <Button
            disabled={false}
            onClick={() => {
              if (editMode) {
                setEditMode(false);
                // reset();
              } else {
                onCancelModal();
                // reset();
                EditBaseInfoForm.setFieldsValue(resetFieldsValue);
                setEditMode(false);
              }
            }}
            htmlType="button"
          >
            {editMode ? "Cancel" : "Close"}
          </Button>
          <Guard action={UpdateBasicInformationInvoicePath}>
            {editMode ? (
              <Button
                type="primary"
                htmlType="button"
                loading={loading}
                disabled={false}
                onClick={() => {
                  EditBaseInfoForm.submit();
                }}
              >
                Save
              </Button>
            ) : (
              <Button
                type="primary"
                htmlType="button"
                disabled={false}
                onClick={() => {
                  setEditMode(true);
                }}
              >
                Edit
              </Button>
            )}
          </Guard>
        </Col>
      </EditBaseInformationContainer>
    </Form>
  );
};

const resetFieldsValue: Partial<IEditBaseInfoFormValue> = {
  reserveParentId: undefined,
  preInvParentId: [],
  ourDate: undefined,
  shmInvoiceDate: undefined,
  supplierId: undefined,
  suppliernvoiceDate: undefined,
  supplierInvoiceNumber: undefined,
  shmInvoiceNumber: undefined,
};
