import { FC, useMemo } from "react";
import { IncomeFormContainer } from "../style";
import { Button, Col, DatePicker, Flex, Form, Row, TreeSelect } from "antd";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { SelectUiKit } from "src/components/UiKit/select";
import { useForm } from "antd/es/form/Form";
import usePublicApiStore from "src/store/publicApi";
import { useIncomeReport } from "../context";
import { ITreeCategory } from "src/services/System/models";
import { ITreeValue } from "src/modules/Financial/models";
const { RangePicker } = DatePicker;

export const IncomeForm: FC = () => {
  const {
    value: {
      voyages,
      category,
      categoryLoading,
      voyageLoading,
      payer,
      payerLoading,
      currency,
      currencyLoading,
      receivers,
      receiversLoading,
      loadingForm,
    },
    func: { onFinishForm, getVoyageNo },
  } = useIncomeReport()!;
  const [form] = useForm();
  const { ships } = usePublicApiStore();

  const values = Form.useWatch([], form);
  const isDisabledResetForm = useMemo(() => {
    let isValidate: boolean = true;
    if (!values) {
      isValidate = true;
      return;
    }
    for (const val of Object.keys(values)) {
      if (values[val] !== undefined) {
        isValidate = false;
        break;
      }
    }
    return isValidate;
  }, [values]);

  const createTreeCategories = (category: ITreeCategory) => {
    const newCat: ITreeValue = {
      value: category.id,
      title: category.name,
      children: [],
    };
    if (category.childeren.length > 0) {
      const child = category.childeren.map((child) =>
        createTreeCategories(child)
      );
      newCat.children = child;
    }
    return newCat;
  };

  return (
    <IncomeFormContainer>
      <Col span={24}>
        <div className="filterContainer">
          <span className="material-symbols-outlined text-[#4B4B4B] text-[24px]">
            filter_alt
          </span>
        </div>
      </Col>
      <Form
        form={form}
        onFinish={onFinishForm}
        onFinishFailed={(err) => {
          console.log(err);
        }}
      >
        <Row gutter={[24, 0]}>
          <Col xs={24} md={8}>
            <FormItem name={"vesselId"} label="Vessel">
              <SelectUiKit
                options={ships?.data?.map((i) => ({
                  label: i.name,
                  value: i.id,
                  name: i.abbreviation,
                }))}
                showSearch
                allowClear
                optionFilterProp="label"
                optionLabelProp="name"
                onChange={(value) => {
                  if (value) {
                    getVoyageNo({ VesselIds: [value] });
                  } else {
                    getVoyageNo();
                  }
                }}
              />
            </FormItem>
          </Col>
          <Col xs={24} md={8}>
            <FormItem name={"voyageId"} label="Voyage No.">
              <SelectUiKit
                options={voyages.map((i) => ({
                  label: i.voyageNo,
                  value: i.voyageId,
                }))}
                showSearch
                allowClear
                optionFilterProp="label"
                loading={voyageLoading}
              />
            </FormItem>
          </Col>
          <Col xs={24} md={8}>
            <div className="relative">
              <FormItem name={"refCode"} label="Ref Code" className="mb-0">
                <SInput allowClear />
              </FormItem>
            </div>
          </Col>
          <Col xs={24} md={8}>
            <FormItem name={"categoryId"} label="Category">
              <TreeSelect
                treeData={category.map((category) =>
                  createTreeCategories(category)
                )}
                placeholder="Select"
                showSearch
                allowClear
                className="selectOption"
                loading={categoryLoading}
              />
            </FormItem>
          </Col>
          <Col xs={24} md={8}>
            <div className="relative">
              <FormItem name={"receiptNo"} label="Receipt No." className="mb-0">
                <SInput allowClear />
              </FormItem>
            </div>
          </Col>
          <Col xs={24} md={8}>
            <FormItem name={"payerId"} label="Payer">
              <SelectUiKit
                options={payer.map((i) => ({ label: i.name, value: i.id }))}
                showSearch
                allowClear
                optionFilterProp="label"
                loading={payerLoading}
              />
            </FormItem>
          </Col>
          <Col xs={12} md={4}>
            <div className="absoluteLabel">Amount</div>
            <FormItem name="amountFrom" className="!mb-0 mt-[32px]">
              <SInput placeholder="From" numbermode />
            </FormItem>
          </Col>
          <Col xs={12} md={4}>
            <FormItem name="amountTo" className="!mb-0 mt-[32px]">
              <SInput placeholder="To" numbermode />
            </FormItem>
          </Col>
          <Col xs={24} md={8}>
            <FormItem name={"currencyId"} label="Currency">
              <SelectUiKit
                options={currency.map((i) => ({
                  label: i.shortName,
                  value: i.id,
                }))}
                showSearch
                allowClear
                optionFilterProp="label"
                loading={currencyLoading}
              />
            </FormItem>
          </Col>
          <Col xs={24} md={8}>
            <FormItem name={"receiverId"} label="Receiver">
              <SelectUiKit
                options={receivers.map((i) => ({ label: i.name, value: i.id }))}
                showSearch
                allowClear
                optionFilterProp="label"
                loading={receiversLoading}
              />
            </FormItem>
          </Col>
          <Col xs={24} md={8}>
            <FormItem label="Receipt Date" name={"receiptDate"}>
              <RangePicker allowClear className="datePicker" />
            </FormItem>
          </Col>
          <Col xs={24} md={8}>
            <FormItem label="Transaction Date" name={"transactionDate"}>
              <RangePicker allowClear className="datePicker" />
            </FormItem>
          </Col>
        </Row>
      </Form>
      <Col span={24} className="buttonHolder">
        <Flex gap={16} justify="flex-end">
          <Button
            type="primary"
            className="w-[85px] !h-[38px] border-[#95B9FE]"
            onClick={() => {
              form?.resetFields();
            }}
            ghost
            disabled={isDisabledResetForm}
          >
            Erase filter
          </Button>
          <Button
            type="primary"
            className="w-[85px] !h-[40px]"
            disabled={isDisabledResetForm}
            onClick={form?.submit}
            loading={loadingForm}
          >
            Set filter
          </Button>
        </Flex>
      </Col>
    </IncomeFormContainer>
  );
};
