import { FC, useEffect, useMemo, useState } from "react";
import { AddCategoryContainer } from "./style";
import {
  App,
  Button,
  Col,
  Form,
  Radio,
  Row,
  TreeSelect,
  TreeSelectProps,
} from "antd";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import TextArea from "antd/es/input/TextArea";
import { SystemService } from "src/services/System/System.service";
import {
  CategoryTypeEnum,
  INewCategory,
  ITreeCategory,
} from "src/services/System/models";
import { DefaultOptionType } from "antd/es/select";
import { useCategory } from "../../context";

const { SHOW_PARENT } = TreeSelect;

export const AddCategory: FC = () => {
  const [parentId, setParentId] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const {
    value: { category, categoryLoading },
    func: { getCategory },
  } = useCategory()!;
  const onFinish = async (value: INewCategory) => {
    setLoading(true);
    const service = new SystemService();
    const reqBody: INewCategory = {
      code: value.code.replaceAll(" ", ""),
      name: value.name,
      parentId,
      description: value.description,
      type: value.type,
    };
    try {
      const response = await service.NewCategory(reqBody);
      if (response && response.status === 200) {
        await getCategory().finally(() => {
          form.resetFields();
          message.success("Category create successfully");
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onChange = (newValue: number) => {
    setParentId(newValue);
  };
  const createTree = (category: ITreeCategory) => {
    const newCategory: DefaultOptionType = {
      label: category.name,
      value: category.id,
      key: category.id,
    };
    if (category.childeren.length > 0) {
      newCategory.children = category.childeren.map((item) => createTree(item));
    }
    return newCategory;
  };
  const data: DefaultOptionType[] = useMemo(
    () => category.map((item) => createTree(item)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [category]
  );
  const treeProps: TreeSelectProps<any, DefaultOptionType> = useMemo(
    () => ({
      treeData: data,
      value: parentId,
      onChange,
      treeCheckable: false,
      showCheckedStrategy: SHOW_PARENT,
      placeholder: "select",
      style: {
        width: "100%",
      },
    }),
    [data, parentId]
  );
  const parentValue = Form.useWatch("parentId", form);
  useEffect(() => {
    if (!!parentValue) {
      form.setFieldValue("type", undefined);
    }
  }, [form, parentValue]);
  return (
    <Row justify={"center"}>
      <Col span={18}>
        <Form form={form} onFinish={onFinish}>
          <AddCategoryContainer>
            <Row gutter={24}>
              <Col span={24} className="pb-[40px]">
                <div className="pt-[16px] titleHolder">
                  Please add category information.
                </div>
              </Col>
              <Col xs={24} md={12}>
                <FormItem
                  name="name"
                  label="Name"
                  rules={[{ required: true }]}
                  required={true}
                >
                  <SInput placeholder="insert" />
                </FormItem>
              </Col>
              <Col xs={24} md={12}>
                <FormItem
                  name="code"
                  label="Code"
                  rules={[{ required: true }]}
                  required={true}
                >
                  <SInput placeholder="insert" />
                </FormItem>
              </Col>
              <Col xs={24} md={12}>
                <FormItem name="parentId" label="Parent">
                  <TreeSelect
                    {...treeProps}
                    allowClear
                    className="selectOption"
                    showSearch
                    treeNodeFilterProp="label"
                    loading={categoryLoading}
                  />
                </FormItem>
              </Col>
              <Col xs={24} md={12}>
                <FormItem
                  name="type"
                  label="Type"
                  initialValue={CategoryTypeEnum.Income}
                  rules={[{ required: !parentValue }]}
                >
                  <Radio.Group
                    className="flex items-center h-[46px]"
                    disabled={!!parentValue}
                  >
                    <Radio value={CategoryTypeEnum.Income}>Income</Radio>
                    <Radio value={CategoryTypeEnum.Cost}>Cost</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem name="description" label="Description">
                  <TextArea
                    placeholder="Type roll description"
                    autoSize={{ maxRows: 12, minRows: 12 }}
                    style={{
                      borderRadius: 12,
                      background: "#F4F7FE",
                    }}
                  />
                </FormItem>
              </Col>
              <Col span={24} className="flex justify-end footer">
                <Button
                  type="primary"
                  className="footerButton"
                  onClick={form.submit}
                  loading={loading}
                >
                  Create
                </Button>
              </Col>
            </Row>
          </AddCategoryContainer>
        </Form>
      </Col>
    </Row>
  );
};
