import { Flex } from "antd";
import { FC, ReactNode } from "react";
import styled from "styled-components";

interface IProps {
  title: string;
  amount: number;
  icon?: ReactNode;
  currency?: string;
}
export const DailyAverageCard: FC<IProps> = ({
  title,
  amount,
  currency = "USD",
  icon = defaultIcon,
}) => {
  return (
    <CardStyle>
      <Flex vertical justify="center" align="center">
        <div className="icon-wrapper">{icon}</div>
        <div className="card-title">{title}</div>
        <Flex gap={8} className="mt-[24px]">
          <div className="amount">
            {amount
              ? amount.toLocaleString("en-Us", { maximumFractionDigits: 2 })
              : 0}
          </div>
          <div className="currency">{currency}</div>
        </Flex>
      </Flex>
    </CardStyle>
  );
};
const CardStyle = styled.div`
  border-radius: 20px;
  background: var(--color-Primary-Primary-50, #f4f8ff);

  /* 2 */
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  display: flex;
  width: 334px;
  padding: 32px 16px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  .icon-wrapper {
    display: flex;
    width: 48px;
    height: 48px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: var(--color-Info-Info-200, #e4f2ff);
    margin-bottom: 8px;
  }
  .card-title {
    color: var(--color-Light-Mode-Text-Secondary-Text, #5d6881);
    text-align: center;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .amount {
    color: var(--color-Primary-Primary-600, #2c73fc);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .currency {
    color: var(--color-Primary-Primary-400, #95b9fe);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

const defaultIcon = (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.42261 5C5.78009 5 5.16389 5.28095 4.70957 5.78105C4.25524 6.28115 4 6.95942 4 7.66667V26.3333C4 27.0406 4.25524 27.7189 4.70957 28.219C5.16389 28.719 5.78009 29 6.42261 29H27.5C27.7761 29 28 28.7761 28 28.5V10.1578C28 9.88163 27.7761 9.65778 27.5 9.65778H23.0411C22.7649 9.65778 22.5411 9.43392 22.5411 9.15778V5.53445C22.5411 5.25874 22.3179 5.03506 22.0422 5.03445L6.42261 5ZM6.42261 5.71111H21.395C21.6712 5.71111 21.895 5.93497 21.895 6.21111V9.12222C21.895 9.39836 21.6712 9.62222 21.395 9.62222H6.42261C5.95143 9.62222 5.49955 9.41619 5.16638 9.04945C4.8332 8.68271 4.64603 8.18531 4.64603 7.66667C4.64603 7.14802 4.8332 6.65062 5.16638 6.28388C5.49955 5.91714 5.95143 5.71111 6.42261 5.71111ZM27.354 27.8244C27.354 28.1006 27.1301 28.3244 26.854 28.3244H6.42261C5.95143 28.3244 5.49955 28.1184 5.16638 27.7517C4.8332 27.3849 4.64603 26.8875 4.64603 26.3689V9.53795C4.64603 9.52558 4.65605 9.51556 4.66842 9.51556C4.67473 9.51556 4.68075 9.51822 4.68499 9.52289L4.75731 9.60249C4.76923 9.61561 4.78244 9.62751 4.79673 9.638L4.83164 9.66362C4.85857 9.68338 4.88346 9.70579 4.90592 9.73051L4.93527 9.76282C4.95773 9.78754 4.98261 9.80996 5.00954 9.82972L5.06595 9.87111L5.16285 9.94222L5.25976 10.0133L5.34652 10.0611C5.37436 10.0764 5.40563 10.0844 5.43742 10.0844C5.4692 10.0844 5.50047 10.0925 5.52832 10.1078L5.55047 10.12C5.59285 10.1433 5.64044 10.1556 5.68882 10.1556H26.854C27.1301 10.1556 27.354 10.3794 27.354 10.6556V27.8244Z"
      fill="#4BA1FF"
      stroke="#4BA1FF"
    />
    <path
      d="M22.4432 20.8563C22.7626 20.8563 23.0749 20.7521 23.3405 20.5567C23.6061 20.3614 23.8131 20.0837 23.9353 19.7589C24.0576 19.434 24.0896 19.0766 24.0272 18.7317C23.9649 18.3869 23.8111 18.0701 23.5852 17.8215C23.3594 17.5729 23.0716 17.4035 22.7583 17.3349C22.445 17.2663 22.1203 17.3016 21.8251 17.4361C21.53 17.5707 21.2778 17.7985 21.1003 18.0909C20.9228 18.3832 20.8281 18.7269 20.8281 19.0786C20.8281 19.5501 20.9983 20.0022 21.3012 20.3356C21.6041 20.669 22.0149 20.8563 22.4432 20.8563ZM22.4432 18.0119C22.6349 18.0119 22.8222 18.0745 22.9816 18.1917C23.1409 18.3089 23.2651 18.4755 23.3385 18.6704C23.4118 18.8653 23.431 19.0797 23.3936 19.2867C23.3562 19.4936 23.2639 19.6836 23.1284 19.8328C22.9929 19.982 22.8202 20.0836 22.6323 20.1247C22.4443 20.1659 22.2494 20.1448 22.0724 20.064C21.8953 19.9833 21.7439 19.8466 21.6375 19.6712C21.531 19.4958 21.4742 19.2895 21.4742 19.0786C21.4742 18.7957 21.5762 18.5244 21.758 18.3243C21.9397 18.1243 22.1862 18.0119 22.4432 18.0119Z"
      fill="#4BA1FF"
      stroke="#4BA1FF"
    />
    <path
      d="M25.8577 2.51464L25.8577 7.83236C25.8577 8.19902 25.5577 8.49902 25.191 8.49902C24.8244 8.49902 24.5244 8.19902 24.5244 7.83236L24.5244 2.51464L23.331 2.51464C23.031 2.51464 22.8844 2.15464 23.0977 1.94797L24.9577 0.094637C25.091 -0.03203 25.2977 -0.03203 25.431 0.094637L27.291 1.94797C27.5044 2.15464 27.351 2.51464 27.0577 2.51464L25.8577 2.51464Z"
      fill="#4BA1FF"
    />
  </svg>
);
