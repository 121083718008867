import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  height: 80px;
  padding: 24px;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid var(--color-Light-Mode-Border-3, #dadeec);
`;
export const TurnoverReportContainer = styled.div`
  .formHolder {
    padding: 32px;
    border-radius: 20px;
    background: #fff;
  }
`;

export const TurnoverFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  .datePicker {
    background: #f4f7fe !important;
    border-color: #f4f7fe !important;
    border-radius: 12px !important;
    min-height: 48px !important;
    width: 100% !important;
    &.ant-picker-disabled {
      background: #dadeec !important;
      border-color: #dadeec !important;
    }
  }
  .buttonHolder {
    padding-bottom: 32px;
    gap: 16px;
    border-bottom: 1px solid var(--color-Light-Mode-Border-3, #dadeec);
  }
`;
export const CardContainer = styled.div`
  display: flex;
  padding: 32px 16px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 20px;
  border: 2px solid var(--color-Light-Mode-Border-1, #a3aed0);
  background: var(--color-Base-Color-White, #fff);

  /* 2 */
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
`;
