import { IObject } from "src/models/interfaces";

export const FormLabels: IObject = {
  vesselId: "Vessel",
  voyageId: "Voyage No.",
  refCode: "Ref Code",
  categoryId: "Category",
  receiptNo: "Receipt No.",
  payerId: "Payer",
  amountFrom: "Amount",
  amountTo: "Amount",
  currencyId: "Currency",
  receiverId: "Receiver",
  receiptDate: "Receipt Date",
  transactionDate: "Transaction Date",
};
