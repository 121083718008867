import { FC } from "react";
import { Header } from "./components/header";
import { DailyAverageReportContent } from "./components/ReportContent";

export const DailyAverageReport: FC = () => {
  return (
    <div className="flex gap-[32px] flex-col">
      <Header />
      <DailyAverageReportContent />
    </div>
  );
};
