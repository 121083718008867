export const invoiceReportFormItemsName = {
  Ship: "ShipId",
  "Invoice created date": "createdDate",
  Requester: "RequesterId",
  "Payment currency": "PaymentCurrencyId",
  "Zip code": "ZipCode",
  "Requester Date": "RequesterDate",
  "Due Date": "DueDate",
  "Attachment code": "AttachmentCode",
  "Voyage No.": "voyageId",
  "Invoice number": "InvoiceNumber",
  "Requester Invoice number": "ShipManagementInvoiceNumber",
  "Requester Invoice Date": "RequesterInvoiceDate",
  Supplier: "SupplierId",
  "Supplier Invoice number": "supplierInvoiceNumber",
  "Supplier Invoice Date": "SupplierInvoiceDate",
  "Invoice currency": "InvoiceCurrencyId",
  "Supplier total from amount": "SupplierTotalFromAmount",
  "Supplier total to amount": "SupplierTotalToAmount",
  "Our date": "OurDate",
  "Ship management total from amount": "ShipManagementTotalFromAmount",
  "Ship management total to amount": "ShipManagementTotalToAmount",
  "Category code": "categoryCode",
  Category: "categoryIds",
  "Quantity from": "quantityFrom",
  "Quantity to": "quantityTo",
  "Unit price from": "unitPriceFrom",
  "Unit price to": "unitPriceTo",
  "Amount from": "amountFrom",
  "Amount to": "amountTo",
};
