import { Col, DatePicker, Select } from "antd";
import { FC, useEffect, useState } from "react";
import { SInput } from "src/components/UiKit/Input";
import { KindInvoiceContainer } from "../../styles";
import { FormItem } from "src/components/UiKit/FormItem";
import { SystemService } from "src/services/System/System.service";
import { IGetSupplier } from "src/services/System/models";
import usePublicApiStore from "src/store/publicApi";
import { invoiceReportFormItemsName } from "../../constant/flterFormNames";
import { OperationService } from "src/services/Operation/Operation.service";
import { IAllVoyage } from "src/services/Operation/models/result.model";
const { RangePicker } = DatePicker;
export const KindInvoice: FC = () => {
  const [suppliers, setSuppliers] = useState<IGetSupplier[]>([]);
  const [allVoyage, setAllVoyage] = useState<IAllVoyage[]>([]);
  const [suppLoading, setSuppLoading] = useState<boolean>(false);
  const { allCurrency } = usePublicApiStore();

  const fetchSuppliers = async () => {
    try {
      setSuppLoading(true);
      const { GetSupplier } = new SystemService();
      const res = await GetSupplier();
      if (res && res.data) {
        setSuppliers(res.data.records);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSuppLoading(false);
    }
  };
  const fetchAllVoyage = async () => {
    try {
      setSuppLoading(true);
      const { AllVoyage } = new OperationService();
      const res = await AllVoyage();
      if (res && res.data) {
        setAllVoyage(res.data.records);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSuppLoading(false);
    }
  };
  useEffect(() => {
    Promise.allSettled([fetchSuppliers(), fetchAllVoyage()]);
  }, []);
  return (
    <KindInvoiceContainer gutter={[24, 24]}>
      <Col span={8}>
        <FormItem
          name={invoiceReportFormItemsName["Voyage No."]}
          label="Voyage No."
          className="!mb-0"
        >
          <Select
            className="selectOption"
            optionFilterProp="label"
            options={allVoyage.map((voyage) => ({
              label: voyage.voyageNO,
              value: voyage.id,
            }))}
            placeholder="Select"
            showSearch
            allowClear
            loading={suppLoading}
          />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          name={invoiceReportFormItemsName["Invoice number"]}
          label="Invoice number"
          className="!mb-0"
        >
          <SInput />
        </FormItem>
      </Col>
      {/* <Col span={8}>
        <FormItem
          name={invoiceReportFormItemsName["Related Voyage No"]}
          label="Related Voyage No"
          className="!mb-0"
        >
          <SInput />
        </FormItem>
      </Col> */}
      <Col span={8}>
        <FormItem
          name={invoiceReportFormItemsName["Requester Invoice number"]}
          label="Requester Invoice number"
          className="!mb-0"
        >
          <SInput />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          name={invoiceReportFormItemsName["Requester Invoice Date"]}
          label="Requester Invoice Date"
          className="!mb-0"
        >
          <RangePicker
            style={{ width: "100%" }}
            className="rangePicker"
            placeholder={["Start", "End"]}
          />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          name={invoiceReportFormItemsName.Supplier}
          label="Supplier"
          className="!mb-0"
        >
          <Select
            className="selectOption"
            optionFilterProp="label"
            options={suppliers.map((supp) => ({
              label: supp.name,
              value: supp.id,
            }))}
            placeholder="Select"
            showSearch
            allowClear
            loading={suppLoading}
          />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          name={invoiceReportFormItemsName["Supplier Invoice number"]}
          label="Supplier Invoice number"
          className="!mb-0"
        >
          <SInput />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          name={invoiceReportFormItemsName["Supplier Invoice Date"]}
          label="Supplier Invoice Date"
          className="!mb-0"
        >
          <RangePicker
            style={{ width: "100%" }}
            className="rangePicker"
            placeholder={["Start", "End"]}
          />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          name={invoiceReportFormItemsName["Invoice currency"]}
          label="Invoice currency"
          className="!mb-0"
        >
          <Select
            style={{ width: "100%" }}
            placeholder="Select"
            optionLabelProp="name"
            optionFilterProp="label"
            showSearch
            allowClear
            className="selectOption"
            options={allCurrency?.data?.map((currency) => ({
              value: currency.id,
              name: currency.shortName,
              label: currency.name,
            }))}
          />
        </FormItem>
      </Col>
      <Col span={4}>
        <div className="absoluteLabel">Supplier total Amount</div>
        <FormItem
          name={invoiceReportFormItemsName["Supplier total from amount"]}
          className="!mb-0 mt-[32px]"
        >
          <SInput placeholder="From" numbermode />
        </FormItem>
      </Col>
      <Col span={4}>
        <FormItem
          name={invoiceReportFormItemsName["Supplier total to amount"]}
          className="!mb-0 mt-[32px]"
        >
          <SInput placeholder="To" numbermode />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          name={invoiceReportFormItemsName["Our date"]}
          label="Our date"
          className="!mb-0"
        >
          <RangePicker
            style={{ width: "100%" }}
            className="rangePicker"
            placeholder={["Start", "End"]}
          />
        </FormItem>
      </Col>

      <Col span={4}>
        <div className="absoluteLabel">Ship management total Amount</div>

        <FormItem
          name={invoiceReportFormItemsName["Ship management total from amount"]}
          className="!mb-0 "
        >
          <SInput placeholder="From" className="mt-[32px] " numbermode />
        </FormItem>
      </Col>
      <Col span={4}>
        <FormItem
          name={invoiceReportFormItemsName["Ship management total to amount"]}
          className="!mb-0 mt-[32px]"
        >
          <SInput placeholder="To" numbermode />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          name={invoiceReportFormItemsName["Invoice created date"]}
          label="Invoice created date"
          className="!mb-0"
        >
          <RangePicker
            style={{ width: "100%" }}
            className="rangePicker"
            placeholder={["Start", "End"]}
          />
        </FormItem>
      </Col>
    </KindInvoiceContainer>
  );
};
