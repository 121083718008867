import { FC } from "react";
import { SelectProps } from "antd/lib";
import { SelectStyle } from "./style";

export const SelectUiKit: FC<SelectProps> = (props) => {
  return (
    <SelectStyle
      placeholder="Select"
      {...props}
      className={`selectOption ${props.className ? props.className : ""}`}
    />
  );
};
