import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { IncomeService } from "src/services/Income/Income.service";
import {
  IAllVoyageIncomes,
  IAllVoyageIncomesArgs,
} from "src/services/Income/models";

interface IContext {
  value: { voyages: IAllVoyageIncomes[]; voyageLoading: boolean };
  dispatch: {};
  func: { getVoyageNo: (queries?: IAllVoyageIncomesArgs) => void };
}

export const TurnoverReportContext = createContext<IContext | undefined>(
  undefined
);

export const TurnoverReportProvider: FC<PropsWithChildren> = ({ children }) => {
  const [voyageLoading, setVoyageLoading] = useState<boolean>(false);
  const [voyages, setVoyages] = useState<IAllVoyageIncomes[]>([]);

  const getVoyageNo = useCallback(
    async (queries?: IAllVoyageIncomesArgs) => {
      try {
        setVoyageLoading(true);
        const service = new IncomeService();
        const res = await service.AllVoyageIncomes(queries);
        if (res && res.status === 200 && res.data) {
          setVoyages(res.data.records);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setVoyageLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    getVoyageNo();
  }, [getVoyageNo]);
  const ContextValue: IContext = {
    value: { voyageLoading, voyages },
    dispatch: {},
    func: { getVoyageNo },
  };
  return (
    <TurnoverReportContext.Provider value={ContextValue}>
      {children}
    </TurnoverReportContext.Provider>
  );
};

export const useTurnoverReport = () => useContext(TurnoverReportContext);
