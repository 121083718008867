import { FC } from "react";
import { TextAreaProps } from "antd/es/input";
import { TextAreaStyled } from "./styled";

interface IProps extends TextAreaProps {}
export const TextAreaUiKit: FC<IProps> = (props) => {
  return (
    <TextAreaStyled
      {...props}
      placeholder={props.placeholder ? props.placeholder : "insert"}
    />
  );
};
