import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { VoyageTableContainer } from "./style";
import { Button, Col, Flex, Form, Row, Select } from "antd";
import { SInput } from "src/components/UiKit/Input";
import { TableUI } from "src/components/UiKit/table";
import { TableProps } from "antd/lib";
import { IPagination } from "src/models/interfaces/pagination";
import { omit, uniqueId } from "lodash";
import moment from "moment";
import { useFinancial } from "../../context";
import { IFinancialTabs } from "../../models";
import usePublicApiStore from "src/store/publicApi";
import { IncomeService } from "src/services/Income/Income.service";
import {
  IAllVoyageIncomes,
  IAllVoyageIncomesArgs,
} from "src/services/Income/models";
import { FormItem } from "src/components/UiKit/FormItem";
import { IObject } from "src/models/interfaces";
import { IncomeView } from "../ViewIncome";
const defaultPagination = {
  Offset: 1,
  Limit: 5,
};

export const VoyageTable: FC = () => {
  const [pagination, setPagination] = useState<IPagination>(defaultPagination);
  const [voyages, setVoyages] = useState<IAllVoyageIncomes[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { ships } = usePublicApiStore();
  const [searchForm] = Form.useForm();
  const [hasFilter, setHasFilter] = useState<boolean>(false);
  const {
    value: { activeKey },
    func: { goToAddIncome, onViewIncome },
  } = useFinancial();

  const fetchVoyage = useCallback(
    async (search?: Partial<IAllVoyageIncomesArgs>) => {
      if (activeKey !== IFinancialTabs.voyageTable) return;
      try {
        setLoading(true);
        const service = new IncomeService();
        let args = {
          ...omit(pagination, "total"),
        };
        if (search) {
          args = {
            ...args,
            ...search,
          };
        }
        const res = await service.AllVoyageIncomes(args);
        if (res && res.status === 200 && res.data) {
          if (args.Offset !== 1 && args.Offset * args.Limit > res.data.count) {
            setPagination((prev) => ({
              ...prev,
              Offset: 1,
            }));
            fetchVoyage({ ...search, Offset: 1 });
            return;
          }
          setVoyages(res.data.records);
          setPagination((prev) => ({ ...prev, total: res.data.count }));
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeKey]
  );
  const searchValues: IObject | undefined = Form.useWatch([], searchForm);
  useEffect(() => {
    setHasFilter(
      searchValues
        ? !!Object.values(searchValues).find(
            (v: number[] | string) => !!v && v.length > 0
          )
        : false
    );
  }, [searchValues]);

  const columnsClassName = "!text-center !text-[12px] !text-[400] ";
  const columns: TableProps<IAllVoyageIncomes>["columns"] = useMemo(
    () => [
      {
        title: "#",
        key: "#",
        render: (text, record, index) =>
          (pagination.Offset - 1) * pagination.Limit + (index + 1),
        className: columnsClassName,
      },
      {
        title: "Vessel",
        dataIndex: "vesselName",
        key: "vesselName",
        className: columnsClassName,
        render: (text) => text || "-",
      },
      {
        title: "Voyage No.",
        dataIndex: "voyageNo",
        key: "voyageNo",
        className: columnsClassName,
        render: (text) => text || "-",
      },
      {
        title: "Factor count",
        dataIndex: "incomesCount",
        key: "incomesCount",
        className: columnsClassName,
        render: (text) => text || "-",
      },

      {
        title: "Total (USD)",
        dataIndex: "totalUsd",
        key: "totalUsd",
        className: columnsClassName,
        render: (text: number) => text.toLocaleString("en-US") || "-",
      },
      {
        title: "Date",
        key: "Date",
        className: columnsClassName,
        render: (text, record) =>
          !!record.startDate && !!record.endDate
            ? `${moment(record.startDate).format("YYYY.MM.DD")} - ${moment(
                record.endDate
              ).format("YYYY.MM.DD")}`
            : "-",
      },
      {
        title: "State",
        dataIndex: "hasIncome",
        key: "hasIncome",
        className: columnsClassName,
        render: (text) => (!text ? "New" : "Completed"),
      },
      {
        title: "Tools",
        key: "tools",
        render: (text, record) =>
          record.hasIncome ? (
            <div className="tools" onClick={() => onViewIncome(record)}>
              Edit and view
            </div>
          ) : (
            <div className="tools" onClick={() => goToAddIncome(record, false)}>
              Complete Voyage
            </div>
          ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pagination.Limit, pagination.Offset]
  );
  const onFilter = () => {
    searchForm.submit();
  };

  const resetSearch = () => {
    if (hasFilter) {
      searchForm.resetFields();
      fetchVoyage({
        ...defaultPagination,
      });
      setPagination(defaultPagination);
      return;
    }
  };
  useEffect(() => {
    fetchVoyage();
  }, [fetchVoyage]);
  return (
    <VoyageTableContainer justify={"center"}>
      <Col span={24}>
        <Row gutter={[24, 24]} justify={"start"} align={"middle"}>
          <Col span={16}>
            <Form
              onFinish={async (val) => {
                setPagination(defaultPagination);
                await fetchVoyage({ ...defaultPagination, ...val });
              }}
              form={searchForm}
            >
              <Flex gap={10} justify="start" align="center">
                <FormItem name={"VesselIds"} className="w-[320px] !mb-0">
                  <Select
                    options={ships?.data?.map((ship) => ({
                      label: ship.name,
                      value: ship.id,
                      name: ship.abbreviation,
                    }))}
                    showSearch
                    allowClear
                    optionFilterProp="label"
                    optionLabelProp="name"
                    className="w-full h-[48px] select"
                    placeholder="Select vessel"
                    // onChange={(VesselIds) => onChangeSearch({ VesselIds })}
                    mode="multiple"
                  />
                </FormItem>
                <FormItem name={"VoyageNo"} className="w-[320px] !mb-0">
                  <SInput
                    className="!bg-white w-full"
                    allowClear
                    placeholder="Search voyage No."
                    prefix={
                      <span className="material-icons text-[16px] text-[#A5ABC0]">
                        search
                      </span>
                    }
                    style={{
                      border: " 1px solid #DBE2E6",
                    }}
                  />
                </FormItem>
              </Flex>
            </Form>
          </Col>
          <Col span={8}>
            <Flex justify="end">
              {hasFilter && (
                <Button
                  type="primary"
                  className="mr-[16px]"
                  ghost
                  onClick={resetSearch}
                >
                  {"Erase filter"}
                </Button>
              )}
              <Button type="primary" onClick={onFilter}>
                {"Set filter"}
              </Button>
            </Flex>
          </Col>

          <Col span={24}>
            <TableUI
              dataSource={voyages}
              containerStyle={{ padding: 16 }}
              columns={columns}
              scroll={{ x: "auto" }}
              pagination={{
                position: ["bottomCenter"],
                showSizeChanger: true,
                total: pagination.total,
                pageSize: pagination.Limit,
                current: pagination.Offset,
                onChange: async (page: number, pageSize: number) => {
                  setPagination((prev) => ({
                    ...prev,
                    Offset: pageSize !== prev.Limit ? 1 : page,
                    Limit: pageSize,
                  }));
                  await fetchVoyage({
                    Offset: pageSize !== pagination.Limit ? 1 : page,
                    Limit: pageSize,
                    ...searchForm.getFieldsValue(),
                  });
                },
              }}
              loading={loading}
              rowKey={() => uniqueId()}
            />
          </Col>
        </Row>
        <IncomeView />
      </Col>
    </VoyageTableContainer>
  );
};
