import { FC } from "react";
import { TurnoverForm } from "./components/TurnoverForm";
import { TurnoverReportContainer } from "./style";
import { Header } from "./components/header";

export const TurnoverReport: FC = () => {
  return (
    <TurnoverReportContainer className="flex gap-[32px] flex-col">
      <Header />
      <div className="formHolder">
        <TurnoverForm />
      </div>
    </TurnoverReportContainer>
  );
};
