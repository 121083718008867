import { FC } from "react";
import { HeaderEditCommercial } from "./style";
import { useFinancial } from "../../context";

export const HeaderEditShip: FC = () => {
  const {
    dispatch: { setMode },
  } = useFinancial();
  const obBack = () => {
    setMode("table");
  };
  return (
    <HeaderEditCommercial>
      <div
        className="flex flex-row gap-[12px] items-center cursor-pointer"
        onClick={obBack}
      >
        <span className="material-icons text-[#2C73FC] text-[32px]">
          keyboard_backspace
        </span>
        <div className=" text-[#2C73FC] text-[24px] ">Back to table</div>
      </div>
      <div className="flex flex-col">
        <div className="text-[#001377] text-[18px]">Attach file</div>
        <div className="text-[#5D6881] text-[14px]">
          Freight/Demurrage/Heating fuel/Hire
        </div>
      </div>
    </HeaderEditCommercial>
  );
};
