import { FC } from "react";
import { Header } from "./components/header";
import { IncomeReportContainer } from "./style";
import { IncomeForm } from "./components/IncomeForm";
import { IncomeTable } from "./components/IncomeTable";
import { useIncomeReport } from "./context";
import { IComponent } from "./models";

export const IncomeReport: FC = () => {
  const {
    value: { compHandler },
  } = useIncomeReport()!;
  return (
    <IncomeReportContainer className="flex gap-[32px] flex-col">
      <Header />
      {compHandler === IComponent.form ? <IncomeForm /> : <IncomeTable />}
    </IncomeReportContainer>
  );
};
