import { Row } from "antd";
import styled from "styled-components";

export const AttachmentContainer = styled(Row)`
  .upload {
    border-radius: 8px;
    border: 1px dashed var(--shadow, #dadeec);
    width: 70px !important;
    height: 70px !important;
    transform: translateY(-6px);
    position: relative;
    .ant-form-item-explain-error {
      position: absolute;
      bottom: -37px;
      left: -29px;
      white-space: nowrap;
    }
  }
  .fileName {
    width: calc(100% - 94px);
  }
  .ant-upload.ant-upload-select {
    width: 40px !important;
    height: 40px !important;
    background-color: #f2f7ff !important;
    border: 0.682px dashed #2c73fc !important;
  }
`;
