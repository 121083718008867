import { Form } from "antd";
import { FormItemProps } from "antd/lib/form";
import { FC, PropsWithChildren } from "react";
import { styled } from "styled-components";

interface IProps extends FormItemProps {}

export const FormItem: FC<PropsWithChildren<IProps>> = (props) => {
  return (
    <FormItemContainer
      labelCol={{
        span: 24,
        style: { paddingBottom: "unset", paddingLeft: 12 },
      }}
      {...props}
    >
      {props.children}
    </FormItemContainer>
  );
};

const FormItemContainer = styled(Form.Item)`
  label {
    color: #001377 !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    height: unset;
  }
  label::before {
    content: unset !important;
  }
  label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::after {
    display: inline-block !important;
    margin-inline-end: 4px !important;
    color: #f64c4c !important;
    font-size: 12px !important;
    /* font-family: SimSun, sans-serif !important; */
    line-height: 1 !important;
    content: "*" !important;
    visibility: visible !important;
  }
`;
