import { FC, useCallback, useEffect, useState } from "react";
import { VoyageTableContainer } from "./style";
import { App, Col, Popconfirm, Row, Select } from "antd";
import { SInput } from "src/components/UiKit/Input";
import { TableUI } from "src/components/UiKit/table";
import { TableProps } from "antd/lib";
import { IAllVoyage } from "src/services/Operation/models/result.model";
import { IPagination } from "src/models/interfaces/pagination";
import { OperationService } from "src/services/Operation/Operation.service";
import _, { omit, uniqueId } from "lodash";
import moment from "moment";
import { useCommercial } from "../../context";
import { ICommercialTabs } from "../../models";
import dayjs from "dayjs";
import usePublicApiStore from "src/store/publicApi";
import { IAllVoyageArg } from "src/services/Operation/models/args.models";
import { Guard } from "src/components/Guard";
import {
  DeleteVoyagePath,
  EditVoyagePath,
} from "src/services/Operation/guardPath";

export const VoyageTable: FC = () => {
  const [pagination, setPagination] = useState<IPagination>({
    Offset: 1,
    Limit: 10,
  });
  const [voyages, setVoyages] = useState<IAllVoyage[]>([]);
  const [search, setSearch] = useState<Partial<IAllVoyageArg>>();
  const [loading, setLoading] = useState<boolean>(false);
  const { ships } = usePublicApiStore();

  const {
    dispatch: { setActiveKey, setEditMode, setCurrentEditVoyage },
    value: { form, activeKey },
  } = useCommercial();
  const { message } = App.useApp();
  const onEdit = (record: IAllVoyage) => {
    form?.setFieldsValue({
      ...record,
      date: [dayjs(record.startDate), dayjs(record.endDate)],
    });
    setCurrentEditVoyage(record);
    setEditMode(true);
    setActiveKey(ICommercialTabs.addVoyage);
  };
  const fetchVoyage = useCallback(async () => {
    if (activeKey !== ICommercialTabs.voyageTable) return;
    try {
      setLoading(true);
      const service = new OperationService();
      const res = await service.AllVoyage({
        ...omit(pagination, "total"),
        ...search,
      });
      if (res && res.status === 200 && res.data) {
        setVoyages(res.data.records);
        setPagination((prev) => ({ ...prev, total: res.data.count }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.Limit, pagination.Offset, search, activeKey]);
  const onDelete = async (id: number) => {
    try {
      const { DeleteVoyage } = new OperationService();
      const res = await DeleteVoyage(id);
      if (res && res?.status === 200) {
        message.success("Successfully deleted");
        fetchVoyage();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const columns: TableProps<IAllVoyage>["columns"] = [
    {
      title: "#",
      key: "#",
      render: (text, record, index) =>
        (pagination.Offset - 1) * pagination.Limit + (index + 1),
      className: " !text-center",
    },
    {
      title: "Vessel",
      dataIndex: "shipName",
      key: "shipName",
      className: " !text-center",
    },
    {
      title: "Voyage No.",
      dataIndex: "voyageNO",
      key: "voyageNO",
      className: " !text-center",
    },

    {
      title: "Date",
      key: "Date",
      className: " !text-center",
      render: (text, record) =>
        !!record.startDate && !!record.endDate
          ? `${moment(record.startDate).format("YYYY.MM.DD")} - ${moment(
              record.endDate
            ).format("YYYY.MM.DD")}`
          : "",
    },

    {
      title: "Tools",
      key: "tools",
      render: (text, record) => (
        <Tools
          onDelete={() => onDelete(record.id)}
          onEdit={() => {
            onEdit(record);
          }}
        />
      ),
    },
  ];
  var debounce_fun = _.debounce(function (value: Partial<IAllVoyageArg>) {
    setSearch((prev) => ({ ...prev, ...value }));
  }, 300);
  useEffect(() => {
    fetchVoyage();
  }, [fetchVoyage]);
  return (
    <VoyageTableContainer justify={"center"}>
      <Col span={18}>
        <Row gutter={[24, 24]}>
          <Col span={10}>
            <Select
              options={ships?.data?.map((ship) => ({
                label: ship.name,
                value: ship.id,
                name: ship.abbreviation,
              }))}
              showSearch
              allowClear
              optionFilterProp="label"
              optionLabelProp="name"
              className="w-full h-[48px] select"
              placeholder="Select vessel"
              onChange={(ShipId) => debounce_fun({ ShipId })}
            />
          </Col>
          <Col span={10}>
            <SInput
              className="!bg-white"
              onChange={(e) =>
                debounce_fun({ VoyageNO: e.target.value.toLowerCase().trim() })
              }
              allowClear
              placeholder="Search voyage No."
              prefix={
                <span className="material-icons text-[16px] text-[#A5ABC0]">
                  search
                </span>
              }
              style={{
                border: " 1px solid #DBE2E6",
              }}
            />
          </Col>

          <Col span={24}>
            <TableUI
              dataSource={voyages}
              containerStyle={{ padding: 16 }}
              columns={columns}
              pagination={{
                position: ["bottomCenter"],
                showSizeChanger: true,
                total: pagination.total,
                pageSize: pagination.Limit,
                current: pagination.Offset,
                onChange: (page: number, pageSize: number) => {
                  setPagination((prev) => ({
                    ...prev,
                    Offset: pageSize !== prev.Limit ? 1 : page,
                    Limit: pageSize,
                  }));
                },
              }}
              loading={loading}
              rowKey={() => uniqueId()}
            />
          </Col>
        </Row>
      </Col>
    </VoyageTableContainer>
  );
};

const Tools: FC<{ onDelete: () => void; onEdit: () => void }> = ({
  onDelete,
  onEdit,
}) => {
  return (
    <div className="flex justify-center items-center gap-[16px] text-[16px]">
      <Guard action={EditVoyagePath}>
        <span
          onClick={onEdit}
          className="material-icons !text-[#01C18F] text-[16px] cursor-pointer"
        >
          edit_note
        </span>
      </Guard>
      <Guard action={DeleteVoyagePath}>
        <Popconfirm
          placement="bottom"
          title="Delete Voyage"
          description="Are you sure you want to Delete this Voyage?"
          okText="Yes"
          cancelText="No"
          onConfirm={onDelete}
        >
          <span className="material-icons !text-[#FF1414] text-[16px] cursor-pointer">
            delete_forever
          </span>
        </Popconfirm>
      </Guard>
    </div>
  );
};
