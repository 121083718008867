import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import dayjs from "dayjs";
import { IReports } from "src/services/InvoiceReport/models";
import { IObject } from "src/models/interfaces";
interface IProps {
  data: IReports[] | undefined;
  title: string;
}
export const generateExpensiveReport = async ({ data, title }: IProps) => {
  const columns: string[] = [
    "shipName",
    "voyage",
    "description",
    "unitQuantity",
    "unitPrice",
    "tax",
    "discount",
    "amount",
    "invoiceCurrency",
    "paymentCurrency",
    "usdRate",
    "supervisorRate",
    "amountUSD",
    "kindOfInvoice",
    "category",
    "costCode",
    "requester",
    "supplier",
    "invoiceDate",
    "invoiceNO",
    "requesterInvoiceDate",
    "requesterInvoiceNO",
    "supplierInvoiceDate",
    "supplierInvoiceNO",
    "requesterTotalAmount",
    "supplierTotalAmount",
    "totalInvAmountReqPayCurrency",
    "dueDate",
    "ourDate",
    "attachnNO",
    "attachDate",
    "zipCode",
  ];

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Sheet1");

  // ادغام سلول‌ها
  worksheet.mergeCells("C3:AI3");
  worksheet.columns.forEach((column) => {
    // column.width = 18;
    column.alignment = {
      horizontal: "center",
      vertical: "middle",
      wrapText: true,
    };
    column.font = {
      name: "Tahoma",
      size: 12,
      color: { argb: "000000" },
    };
  });

  const cell = worksheet.getCell("C3");

  cell.value = title;

  // تنظیم استایل برای سلول ادغام شده
  cell.font = {
    name: "Tahoma",
    size: 14,
    bold: true,
    color: { argb: "000000" },
  };

  cell.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "CACACA" },
  };

  cell.alignment = {
    horizontal: "center",
    vertical: "middle",
    wrapText: true,
  };
  const titleRow = worksheet.getRow(3);
  titleRow.height = 40;

  const header: string[] = ["#", ...columns];
  const excelData = data?.map((item: IObject, index) => {
    item.dueDate = item.dueDate ? dayjs(item.dueDate).format("YYYY.MM.DD") : "";
    item.ourDate = item.ourDate ? dayjs(item.ourDate).format("YYYY.MM.DD") : "";
    item.attachDate = item.attachDate
      ? dayjs(item.attachDate).format("YYYY.MM.DD")
      : "";
    item.requesterInvoiceDate = item.requesterInvoiceDate
      ? dayjs(item.requesterInvoiceDate).format("YYYY.MM.DD")
      : "";
    item.supplierInvoiceDate = item.supplierInvoiceDate
      ? dayjs(item.supplierInvoiceDate).format("YYYY.MM.DD")
      : "";
    item.invoiceDate = item.invoiceDate
      ? dayjs(item.invoiceDate).format("YYYY.MM.DD")
      : "";
    item.paymentCurrency = item.paymanetCurrency || "";
    item.kindOfInvoice = item.kindOnInvoice || "";
    item.supplier = item.supplierName || "";
    item.voyage = item.voyageNO || "";
    const newData = ["", "", index + 1];
    columns.forEach((column) => {
      newData.push(item[column]);
    });
    return newData;
  });
  // داده‌های جدول
  const tableData = [[], ["", "", ...header], ...(excelData || [])];

  // افزودن داده‌ها به شیت
  tableData.forEach((row, index) => {
    worksheet.addRow(row);
  });
  const headerRow = worksheet.getRow(5);
  headerRow.eachCell((headCell, colNum) => {
    if (colNum > 2) {
      headCell.font = {
        name: "Tahoma",
        size: 12,
        bold: true,
        color: { argb: "000000" },
      };
      headCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "E1E1E1" },
      };

      headCell.alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
    }
  });

  headerRow.height = 40;
  worksheet.columns.forEach((column) => {
    let maxLength = 10; // حداقل عرض
    column.eachCell!({ includeEmpty: true }, (cell) => {
      const cellValue = cell.value ? cell.value.toString().length : 0;
      maxLength = Math.max(maxLength, cellValue);
    });
    column.width = maxLength > 90 ? 90 : maxLength + 7; // برای فاصله دادن
  });
  const cCol = worksheet.getColumn("C");
  cCol.width = 9.17;
  // ایجاد فایل اکسل
  const buffer = await workbook.xlsx.writeBuffer();

  // ذخیره فایل اکسل
  const blob = new Blob([buffer], { type: "application/octet-stream" });
  saveAs(blob, "ExpensiveReportExcel.xlsx");
};
