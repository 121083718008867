import { InputNumber } from "antd";
import { styled } from "styled-components";

export const InputNumberStyle = styled(InputNumber)`
  background: #f4f7fe;
  border-color: #f4f7fe;
  border-radius: 12px;
  min-height: 48px;
  width: 100%;
  display: flex !important;
  align-items: center !important;
  flex-direction: row;
  font-weight: 500;
  color: #878787;

  .ant-input-number-input-wrap {
    width: 100%;
  }
  .ant-input-number-input::placeholder {
    font-weight: 500;
    color: #878787;
    opacity: 1 !important;
  }
`;
