import { FC } from "react";
import { HeaderContainer } from "../style";

export const Header: FC = () => {
  return (
    <HeaderContainer>
      <span className="material-icons text-[#001377] text-[32px]">
        filter_list
      </span>
      <div className="text-[#001377] text-[24px] font-[700] ">
        Set filter for report
      </div>
    </HeaderContainer>
  );
};
