import { Flex } from "antd";
import { FC } from "react";
import { IAllIncomeAttachmentDetails } from "src/services/Income/models";
import { Item } from "./incomeViewItem";
import styled from "styled-components";

export const IncomeAttachmentTab: FC<{
  attachment: IAllIncomeAttachmentDetails;
}> = ({ attachment }) => {
  return (
    <Flex vertical gap={32} className="mt-[16px]">
      <Item label="File name" value={attachment.fileName || "--"} />
      <Item label="Description" value={attachment.description || "--"} />
      <Item
        label="Attachment"
        value={
          <AttachmentImage
            onClick={() => {
              alert("download");
            }}
          />
        }
      />
    </Flex>
  );
};
const AttachmentImage: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <AttachmentImageStyle onClick={onClick}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 28V4C4 1.2 6.66667 0.166667 8 0H19L28 9.5V28C28 31.2 25.3333 32 24 32H8C4.8 32 4 29.3333 4 28Z"
          fill="#008F87"
        />
        <path d="M28 9.5H19V0L28 9.5Z" fill="#80C6C2" />
        <path
          d="M21.5125 19.53C21.0025 16.9425 18.73 15 16 15C13.8325 15 11.95 16.23 11.0125 18.03C8.755 18.27 7 20.1825 7 22.5C7 24.9825 9.0175 27 11.5 27H21.25C23.32 27 25 25.32 25 23.25C25 21.27 23.4625 19.665 21.5125 19.53ZM15.0325 24.2175C14.74 24.51 14.2675 24.51 13.975 24.2175L12.4 22.65C12.1075 22.3575 12.1075 21.885 12.4 21.5925C12.6925 21.3 13.165 21.3 13.4575 21.5925L14.5 22.635L17.86 19.275C18.1525 18.9825 18.625 18.9825 18.9175 19.275C19.21 19.5675 19.21 20.04 18.9175 20.3325L15.0325 24.2175Z"
          fill="white"
        />
      </svg>
    </AttachmentImageStyle>
  );
};
const AttachmentImageStyle = styled.div`
  border-radius: 8px;
  border: 1px solid var(--color-Success-Success-700, #0c9d61);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  width: 58px;
  height: 58px;
`;
